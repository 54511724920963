<template>
    <router-link :to="items.route" class="btn btn-secondary rounded-0">
        <i :class="'m-0 icon ' + items.icon"></i>
        {{ items.name }}
    </router-link>
</template>

<script>
export default {
    name: "ButtonRoute",

    props: {
        items: {
            "required": true,
            "type": Object,
            "default": []
        },
    },
}
</script>