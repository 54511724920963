<template>
    <textarea class="form-control" :value="modelValue"
              @input="$emit('update:modelValue', $event.target.value)"></textarea>
</template>

<script>
export default {
    name: 'TextArea',
    props: {
        modelValue: {
            type: String,
            default: '',
            required: false
        }
    }
}
</script>

<style scoped>
textarea {
    resize: none;
    border-radius: 0;
    border-color: #000000;
}

textarea:focus {
    box-shadow: none;
    border-color: #000;
}
</style>