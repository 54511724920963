import AdminComponent from "@/components/dashboard/AdminComponent";

import UserGroupSearch from "@/views/search/UserGroupSearch";
import UserGroupCreate from "@/views/create/UserGroupCreate";
import UserGroupUpdate from "@/views/update/UserGroupUpdate";

export default {
    path: "/user-group",
    meta: {auth: true},
    component: AdminComponent,
    children: [
        {path: "search", name: "user-group-search", component: UserGroupSearch},
        {path: "create", name: "user-group-create", component: UserGroupCreate},
        {path: "edit/:id", name: "user-group-edit", component: UserGroupUpdate, props: true},
    ]
}