import AdminComponent from '@/components/dashboard/AdminComponent'
import FinanceExitPreview from "@/views/report/FinanceExitPreview";

export default {
    path: '/financial-exit-preview',
    component: AdminComponent,
    meta: {auth: true},
    children: [
        {path: 'search', name: 'financial-exit-preview-search', component: FinanceExitPreview},
    ]
}