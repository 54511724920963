<template>
    <painel-component :items="itemsOne">
        <error-component :errors="errors" v-if="showError" :class="'mb-5'"/>

        <div class="aux my-4">
            <div class="avatar-form">
                <img v-if="me.photo" :src="imagePreview" alt="" class="avatar__image">
                <img v-else src="@/assets/avatar.png" alt="" class="avatar__image">
            </div>
            <p>{{ me.name }}</p>
        </div>

        <form autocomplete="off">
            <row-component>
                <col-component class-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <label>Foto:</label>
                    <input type="file" name="file" id="formFile" @change="onFileChange">
                    <label for="formFile" class="label-file">
                        <span>{{ this.namePhoto }}</span>
                        <span><i class="m-0 camera icon"></i></span>
                    </label>
                </col-component>
            </row-component>

            <row-component>
                <col-component class-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <button-route :items="itemsTwo" class="w-100"/>
                </col-component>
            </row-component>
        </form>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import ColComponent from "@/components/row/ColComponent";
import ErrorComponent from "@/components/alerts/ErrorComponent";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import {useToast} from "vue-toastification";

const toast = useToast();

export default {
    name: "UserUpdatePhoto",

    created() {
        this.$store.dispatch('changeNamePage', 'Usuário')
    },

    components: {
        ButtonRoute,
        PainelComponent,
        ColComponent,
        RowComponent,
        ErrorComponent,
    },

    data() {
        return {
            itemsOne: {
                title: "Editar",
                icon: "edit"
            },

            itemsTwo: {
                route: "/",
                icon: "reply all",
                name: "Voltar"
            },

            formDate: {
                photo: '',
            },

            namePhoto: '',
            imagePreview: "",

            errors: [],
            showError: false,
            sendForm: false,
        }
    },
    methods: {
        submit() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('updateUserPhoto', this.formDate)
                    .then((r) => {
                        this.showError = false
                        this.sendForm = false
                        this.$store.dispatch('UpdateLinkPhoto', r[0].photo)
                        toast.success('Foto atualizada com sucesso', {timeout: 2000});
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
                })
            }
        },

        onFileChange(e) {
            let files = e.target.files || e.dataTransfer.files
            if (!files.length)
                return
            this.formDate.photo = files[0]
            this.namePhoto = this.formDate.photo.name
            if (files[0].type.indexOf('image') === 0)
                this.previewImage(files[0])
            this.submit()
        },

        previewImage(file) {
            let reader = new FileReader();
            reader.onload = (e) => {
                this.imagePreview = e.target.result
            }
            reader.readAsDataURL(file)
        },

        photoPreviewTimer() {
            this.imagePreview = this.me.photo
        }
    },

    computed: {
        me() {
            return this.$store.state.auth.me
        },
    },

    beforeMount() {
        setTimeout(() => this.photoPreviewTimer(), 1000);
    },

}
</script>

<style scoped>
input[type="file"] {
    display: none;
}

.label-file {
    width: 100%;
    height: 42px;
    display: flex;
    border: 1px solid #ddd;
    justify-content: space-between;
}

.label-file span:nth-child(2) {
    width: 50px;
    color: #fff;
    height: 100%;
    cursor: pointer;
    line-height: 40px;
    text-align: center;
    background-color: #000000;
}

label {
  padding-right: 0px;
}

</style>
