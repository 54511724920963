<template>
  <painel-component :items="itemsOne">
    <error-component :errors="errors" v-if="showError" :class="'mb-5'"/>

    <div class="aux my-4">
      <div class="avatar-form">
        <img v-if="formData.photo" :src="imagePreview" class="avatar__image" alt="">
        <img v-else src="@/assets/avatar.png" class="avatar__image" alt="">
      </div>
      <p>{{ formData.nameClient }}</p>
    </div>

    <form autocomplete="off">
      <row-component>
        <col-component :class-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
          <label>Foto:</label>
          <input type="file" name="file" id="formFile" @change="onFileChange">
          <label for="formFile" class="label-file">
            <span>{{ this.namePhoto }}</span>
            <span><i class="camera icon m-0"></i></span>
          </label>
        </col-component>
      </row-component>

      <row-component>
        <col-component
            :class-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
          <button-route :items="itemsTwo" class="w-100"/>
        </col-component>
      </row-component>
    </form>
  </painel-component>
</template>

<script>

import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import ErrorComponent from "@/components/alerts/ErrorComponent";
import {useToast} from "vue-toastification";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import colComponent from "@/components/row/ColComponent.vue";

const toast = useToast();

export default {
  name: "AccountBankUpdatePhoto",

  props: {
    id: {
      required: true
    }
  },

  created() {
    this.$store.dispatch('changeNamePage', 'Conta Bancária')
    this.loadPageItens();
  },

  components: {
    ButtonRoute,
    PainelComponent,
    RowComponent,
    ErrorComponent,
    colComponent
  },

  data() {
    return {
      itemsOne: {
        title: "Editar",
        icon: "edit"
      },

      itemsTwo: {
        route: `/account-bank/edit/${this.id}`,
        icon: "reply all",
        name: "Voltar",
      },

      formData: {
        photo: '',
        name: '',
        id: this.id
      },

      imagePreview: "",
      namePhoto: '',

      errors: [],
      showError: false,
      sendForm: false,
    }
  },

  methods: {
    loadPageItens() {
      this.$store.dispatch('loadAccountBank', this.id).then((r) => {
        this.formData.photo = r.photo;
        this.formData.nameClient = r.name;
      }).catch(() => {
        toast.error("Erro desconhecido !", {timeout: 2000});
      })
    },

    submit() {
      if (!this.sendForm) {
        this.sendForm = true;
        this.$store.dispatch('updateAccountBankPhoto', this.formData)
            .then(() => {
              this.showError = false
              this.sendForm = false
              toast.success("Foto atualizada com sucesso", {timeout: 2000});
            }).catch((r) => {
          this.showError = true;
          this.sendForm = false
          this.errors = r.response.data.errors;
          toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
        })
      }
    },

    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files
      if (!files.length)
        return
      this.formData.photo = files[0]
      this.namePhoto = this.formData.photo.name
      if (files[0].type.indexOf('image') === 0)
        this.previewImage(files[0])
      this.submit()
    },

    previewImage(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.imagePreview = e.target.result
      }
      reader.readAsDataURL(file)
    },

    photoPreviewTimer() {
      this.imagePreview = this.formData.photo
    },

  },

  beforeMount() {
    setTimeout(() => this.photoPreviewTimer(), 1000);
  },
}
</script>

<style scoped>
input[type="file"] {
  display: none;
}

.label-file {
  width: 100%;
  height: 42px;
  display: flex;
  border: 1px solid #ddd;
  justify-content: space-between;
}

.label-file span:nth-child(2) {
  width: 50px;
  color: #fff;
  height: 100%;
  cursor: pointer;
  line-height: 40px;
  text-align: center;
  background-color: #000000;
}

label {
  padding-right: 0px;
}

</style>

