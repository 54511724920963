<template>
  <painel-component :items="itemsOne">

    <form >
      <row-component>
        <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
          <label>Consultora:</label>
          <MultiSelect v-model="formData.creator_user_id" :options="clients" placeholder="Consultora"/>
        </col-component>

        <col-component class-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
            <label>Entrada/Saída:</label>
            <multi-select placeholder="Entrada/Saída"
              :options="selectEntranceExit"
              v-model="formData.type"
              />
        </col-component>

        <col-component class-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
            <label>Mês:</label>
            <multi-select placeholder="Mês" v-model="formData.month" :options="selectMonthList" />
        </col-component>

        <col-component class-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
            <label>Ano:</label>
            <MultiSelect placeholder="Ano" v-model="formData.year" :options="getYearSelect()" :searchable="true"/>
        </col-component>
        
        <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
            <label>Excel/PDF:</label>
            <multi-select placeholder="Excel ou PDF" v-model="formData.excel_pdf" :options="selectExcelOrPdf" />
        </col-component>

    </row-component>

    <row-component>
      <col-component class-col="col-xxl-3 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
        <button-submit class="me-1" @click="submit" name="Gerar"/>
      </col-component>
    </row-component>
    </form>
  </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from '@/components/row/RowComponent.vue';
import ColComponent from "@/components/row/ColComponent.vue";
import MultiSelect from "@/components/form/MultiSelect";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";

export default {
  name: "BoxTotalMoviment",
  components: {
    PainelComponent,
    RowComponent,
    ColComponent,
    MultiSelect,
    ButtonSubmit,
  },
  created() {
    this.$store.dispatch("changeNamePage", "PDV Caixa (Movimentação)");
    this.checkACL();
    this.loadClients();
  },
  data() {
    // Data atual
    const currentDate = new Date();

    return {
      itemsOne: {
        title: "Relatório",
        icon: "list alternate"
      },
      buttonCheck: {
        create: false,
        edit: false
      },
      formData: {
        creator_user_id: "",
        type: "",
        month: currentDate.getMonth() + 1, //Retorna o mês atual
        year: currentDate.getFullYear(), //Retorna o anual atual
        excel_pdf: 2,
      },

      viewPage: false,
      baseUrl: '',

      clients: [],
    }
  },
  methods: {
    submit() {
      this.$store.dispatch('loadBoxTotalMoviment', {...this.params})
        .then((r) => {
          console.log(r)
          window.open(r.link, '_blank');
        }).catch(() => {
        });
    },

    async checkACL() {
      this.$store.dispatch('ACLItems', 19)
        .then((r) => {
          if (r.indexOf('report-all') !== -1) {
            this.viewPage = true;
            if (r.indexOf('report-all-search') !== -1) {
              this.viewPage = true;
            }
          } else {
            this.$router.push({name: 'home'});
          }
        }).catch(() => {
          this.$router.push({name: 'home'});
        });
    },

    statusString(value) {
      return parseInt(value) === 1 ? "Ativo" : "Inativo";
    },

    loadClients() {
      this.$store.dispatch('getUsersLists')
        .then((r) => {
          this.clients = r.map(client => ({
            label: client.name,
            value: client.value,
            icon: client.icon,
          }));
        }).catch((error) => {
          console.error('Erro ao carregar clientes:', error);
        });
    },

    getYearSelect() {
      let dataActual = new Date();
      let year = dataActual.getFullYear() + 2;
      let years = [];
      for (let i = 2024; i <= year; i++) {
        years.push(i);
      }
      return years
    },
  },

  computed: {
    selectActiveInative() {
      return this.$store.state.var.selectActiveInative;
    },

    selectExcelOrPdf() {
      return this.$store.state.var.selectExcelOrPdf;
    },

    selectEntranceExit(){
      return this.$store.state.var.selectEntranceExit;
    },

    selectMonthList(){
      return this.$store.state.var.selectMonthList;
    },


    object() {
      return this.$store.state.report_products.items;
    },
    params() {
      return {
        excel_pdf: this.formData.excel_pdf,
        creator_user_id: this.formData.creator_user_id,
        type: this.formData.type,
        month: this.formData.month,
        year: this.formData.year,
      };
    }
  }
};


</script>