import AdminComponent from "@/components/dashboard/AdminComponent";

import ProductSearch from "@/views/search/ProductSearch";
import ProductCreate from "@/views/create/ProductCreate";
import ProductUpdate from "@/views/update/ProductUpdate";

export default {
    path: "/product",
    meta:{auth: true},
    component: AdminComponent,
    children: [
        {path: "search", name: "product-search", component: ProductSearch},
        {path: "create", name: "product-create", component: ProductCreate},
        {path: "edit/:id", name: "product-edit", component: ProductUpdate, props: true},
    ]
}