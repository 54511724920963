import AdminComponent from "@/components/dashboard/AdminComponent";

import ProductReport from "@/views/report/ProductReport.vue";

export default {
    path: "/report-products",
    meta:{auth: true},
    component: AdminComponent,
    children: [
        {path: "search", name: "report-product-search", component: ProductReport},
    ]
}