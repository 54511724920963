<template>
  <div class="painel p-4 pt-5">

    <div role="button" @click="this.$emit('close')" class="btn-close"></div>

    <br>
    <div v-if="textOne">
      <h1>A NF não foi emitida!</h1>
    </div>

    <div v-if="textTwo">
      <h1 class="preloader-send">Enviado dados para SEFAZ</h1>
    </div>

    <div v-if="textThree">
      <h1 class="preloader-send">Emitindo NF</h1>
    </div>


    <br>
    <row-component>
      <col-component class-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" v-if="textOne">
        <button-submit @click="submit" name="Emitir NF"/>
      </col-component>
    </row-component>


  </div>
</template>

<script>
import RowComponent from "@/components/row/RowComponent";
import ColComponent from "@/components/row/ColComponent";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";


export default {
  name: "NFModal",
  components: {
    ButtonSubmit,
    ColComponent,
    RowComponent
  },


  props: {
    id: {
      required: true
    }
  },

  data() {
    return {

      textOne: true,
      textTwo: false,
      textThree: false,
      formData: {
        id: this.id
      }

    }
  },


  methods: {
    submit() {
      this.textOne = false
      this.textTwo = true
      this.$store.dispatch('storeNF', this.formData)
          .then(() => {
            setTimeout(() => {
              this.textTwo = false
              this.endClose()
            }, 10000);


          }).catch(() => {
        this.$emit('close')
      })

    },

    endClose() {
      this.textThree = true
      setTimeout(() => {
        this.$emit('close')

      }, 10000);
    },

  },


}
</script>

<style scoped>
.painel {
  position: relative;
  background: #FFFFFF;
}

.btn-close {
  top: 10px;
  right: 10px;
  position: absolute;
}

h1 {
  font-size: 20px;

  padding: 10px 50px;
  text-transform: uppercase;
}


</style>