import OrderProducts from "@/views/show/OrderProducts";
import PostalLabel from "@/views/show/PostalLabel";
import RequestSummary from "@/views/show/RequestSummary";
import ProductLabel from "@/views/show/ProductLabel";

export default {
    path: "/tags",
    meta: {auth: true},
    children: [
        {path: "post-label/:id", name: "post-label", component: PostalLabel, props: true},
        {path: "order-products/:id", name: "order-products", component: OrderProducts, props: true},
        {path: "request-summary/:id", name: "request-summary", component: RequestSummary, props: true},
        {path: "product-label/:id/:amount", name: "product-label", component: ProductLabel, props: true},
    ]
}