<template>
  <div class="modal-w d-grid position-fixed h-100 w-100 start-0 top-0" v-if="NFmodal">
    <div v-if="NFStatus ===1">
      <NFModal v-if="NFmodal" @close="closeNFModal" :id="this.id"/>
    </div>
    <div v-if="NFStatus ===2">
      <NFModalDow v-if="NFmodal" @closeDown="closeNFModalDow" :id="this.id"/>
    </div>
  </div>

  <painel-component :items="itemsOne">
    <div class="clearfix">
            <span class="px-2 badge text-white bg-black rounded-0" v-if="formData.n_envio">
               Código de Envio: {{ formData.n_envio }}
            </span>

      <div class="btn-group float-end" role="group" aria-label="Basic example">
        <button-submit title="Etiqueta Correios" @click="postLabel" icon="ticket"/>
        <button-submit title="Etiqueta Pedido" @click="orderProducts" icon="file alternate outline"/>
        <button-submit title="Resumo do pedido" @click="requestSummary" icon="list alternate"/>
        <button-submit title="NF-E" icon="file pdf outline" @click="NFmodal = true"
                       v-if="formData.statusTransaction ===2"/>
      </div>
    </div>

    <form class="mb-4">
      <row-component>
        <col-component class-col="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
          <label>Nome:</label>
          <input-component v-model="formData.nome_cliente" disabled/>
        </col-component>

        <col-component class-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
          <label>CPF / CNPJ:</label>
          <input-component v-model="formData.cnpj_cliente" disabled/>
        </col-component>
      </row-component>

      <row-component>
        <col-component class-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <label>Logradouro:</label>
          <input-component v-model="formData.end_endereco" disabled/>
        </col-component>
      </row-component>

      <row-component>
        <col-component class-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
          <label>CEP:</label>
          <input-component v-mask="'#####-###'" v-model="formData.end_cep" disabled/>
        </col-component>

        <col-component class-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
          <label>Bairro:</label>
          <input-component v-model="formData.end_bairro" disabled/>
        </col-component>

        <col-component class-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
          <label>Cidade:</label>
          <input-component v-model="formData.end_cidade" disabled/>
        </col-component>
      </row-component>
    </form>

    <table class="table mb-5">
      <thead class="table-light">
      <tr>
        <th scope="col" style="width: 16.6%">#</th>
        <th scope="col" style="width: 16.6%">Ref.</th>
        <th scope="col" style="width: 16.6%">Descrição</th>
        <th scope="col" style="width: 16.6%">Qtde.</th>
        <th scope="col" style="width: 16.6%">Unitário</th>
        <th scope="col" style="width: 16.6%">Total</th>
      </tr>
      </thead>

      <tbody>
      <tr v-for="(data, index) in items" :key="index">
        <td data-label="#">{{ data.id }}</td>
        <td data-label="Ref.">{{ data.referencia }}</td>
        <td data-label="Descrição">{{ data.descricao_simples }}</td>
        <td data-label="Qtde.">{{ data.qtde }}</td>
        <td data-label="Unitário">R$ {{ formatMoney(data.vlr_uni) }}</td>
        <td data-label="Total">R$ {{ formatMoney(data.vlr_total) }}</td>
      </tr>
      </tbody>
    </table>

    <table class="table table-striped">
      <thead>
      <tr>
        <th scope="col" style="width: 16.6%">Frete</th>
        <th scope="col" style="width: 16.6%">Pagamento</th>
        <th scope="col" style="width: 16.6%">Peso total</th>
        <th scope="col" style="width: 16.6%">Qtd. Itens</th>
        <th scope="col" style="width: 16.6%">SubTotal</th>
        <th scope="col" style="width: 16.6%">Total</th>
      </tr>
      </thead>

      <tbody>
      <tr>
        <td data-label="Frete">{{ formData.frete_tipo }} | R$ {{ formatMoney(formData.frete) }}</td>
        <td data-label="Pagamento">{{ formData.forma_pgto }}</td>
        <td data-label="Peso Total">{{ formData.peso_total_pedido }}</td>
        <td data-label="Qtd. Itens">{{ formData.qtd_itens }}</td>
        <td data-label="SubTotal">R$ {{ formatMoney(formData.vlr_total_itens) }}</td>
        <td data-label="Total">R$ {{ formatMoney(formData.vlr_total_pedido) }}</td>
      </tr>
      </tbody>

      <thead>
      <tr>
        <th scope="col" style="width: 16.6%">Descontos</th>
        <th scope="col" style="width: 16.6%">Cupom</th>
        <th scope="col" style="width: 16.6%">Vale Compra</th>
        <th scope="col" style="width: 16.6%">Impostos</th>
        <th scope="col" style="width: 16.6%">Vendedor</th>
        <th scope="col" style="width: 16.6%">Entrega até</th>
      </tr>
      </thead>

      <tbody>
      <tr>
        <td data-label="Descontos">R$ {{ formatMoney(formData.vlr_total_descontos) }}</td>
        <td data-label="Cupom">
          {{ formData.cupom !== null ? formData.cupom : "Não informado" }}
        </td>
        <td data-label="Vale Compra">
          {{ formData.id_vale_compra !== null ? formData.id_vale_compra : "Não informado" }}
        </td>
        <td data-label="Impostos">R$ {{ formatMoney(formData.imposto) }}</td>
        <td data-label="Vendedor">
          {{ formData.id_vendedor !== null ? formData.id_vendedor : "Não informado" }}
        </td>
        <td data-label="Entrega até">{{ formatDate(formData.previsao_entrega) }}</td>
      </tr>
      </tbody>
    </table>

    <row-component>
      <col-component class-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-5">
        <button-route class="w-100" :items="itemsTwo"/>
      </col-component>
    </row-component>
  </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import ColComponent from "@/components/row/ColComponent";
import InputComponent from "@/components/form/InputComponent";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";

import {mask} from "vue-the-mask";
import {useToast} from "vue-toastification";
import NFModalDow from "@/components/modal/NFmodalDow.vue";
import NFModal from "@/components/modal/NFmodal.vue";

const toast = useToast()
export default {
  name: "CommerceSalesShow",
  components: {
    NFModal,
    NFModalDow,
    ButtonSubmit,
    ButtonRoute,
    InputComponent,
    ColComponent,
    RowComponent,
    PainelComponent
  },

  directives: {
    mask
  },

  props: {
    id: {
      required: true
    }
  },

  created() {
    this.$store.dispatch("changeNamePage", "Ecommerce Vendas")
    this.loadItems()
  },

  data() {
    return {
      itemsOne: {
        icon: "eye",
        title: "Ver"
      },

      itemsTwo: {
        icon: "reply all",
        name: "Voltar",
        route: "/e-commerce-sales/search"
      },

      formData: {
        id: this.id,
        nome_cliente: "",
        cnpj_cliente: "",
        data: "",
        n_envio: "",
        end_bairro: "",
        end_cep: "",
        end_cidade: "",
        end_endereco: "",
        end_estado: "",
        end_numero: "",
        end_nome_destinatario: "",
        frete_tipo: "",
        frete: "",
        forma_pgto: "",
        peso_total_pedido: "",
        qtd_itens: "",
        vlr_total_itens: "",
        vlr_total_pedido: "",
        vlr_total_descontos: "",
        vlr_desconto_cupom: "",
        cupom: "",
        id_vale_compra: "",
        imposto: "",
        id_vendedor: "",
        previsao_entrega: "",
        status: [],
        statusTransaction: false,
      },

      items: [],
      NFmodal: false,
      NFStatus: 3
    }
  },

  methods: {
    loadItems() {
      this.$store.dispatch('loadCommerceSale', this.id)
          .then((r) => {
            this.formData = r.sales.original.registros
            this.NFStatus = r.NFStatus
            this.items = r.itens.original.registros
            this.formData.statusTransaction = r.transaction.original.registros[0].status.id;
            console.log(this.formData.statusTransaction)
          }).catch(() => {
        toast.error('Erro desconhecido!', {timeout: 1500})
      })
    },

    formatMoney(value) {
      return value = value.toLocaleString('pt-br', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
    },

    formatDate(value) {
      if (value) {
        let date = value.split(" ")[0]
        date = date.split("-")
        return `${date[2]}/${date[1]}/${date[0]}`
      }
    },

    orderProducts() {
      const route = this.$router.resolve({name: "order-products", params: {id: this.id}})
      window.open(route.href, '_blank');
    },

    postLabel() {
      const route = this.$router.resolve({name: "post-label", params: {id: this.id}})
      window.open(route.href, '_blank');
    },

    requestSummary() {
      const route = this.$router.resolve({name: "request-summary", params: {id: this.id}})
      window.open(route.href, '_blank');
    },

    closeNFModal() {
      this.NFStatus = 2;
      this.NFmodal = false
      setTimeout(() => {
        this.NFmodal = true
      }, 500);
    },

    closeNFModalDow() {
      this.NFmodal = false
    },
  }
}
</script>
<style scoped>
.modal-w {
  place-items: center;
  background: rgba(0, 0, 0, 0.9);
  z-index: 99;
}

.modal-p {
  width: 60%;
  overflow-y: auto;
  min-height: 550px;
  background: #FFFFFF;
}

.btn-close {
  top: 10px;
  right: 10px;
  position: absolute;
}

.painel {
  padding: 1rem;
  background: #FFFFFF;
}

</style>