import AdminComponent from "@/components/dashboard/AdminComponent";

import InventorySearch from "@/views/search/InventorySearch";
import InventoryCreate from "@/views/create/InventoryCreate.vue";

export default {
    path: "/inventory",
    meta: {auth: true},
    component: AdminComponent,
    children: [
        {path: "search", name: "inventory-search", component: InventorySearch},
        {path: "create", name: "inventory-create", component: InventoryCreate},
    ]
}