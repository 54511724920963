<template>
    <painel-component :items="itemsOne">
        <error-component :errors="errors" v-if="showError"/>

        <div class="clearfix mb-3" v-if="create">
            <button-route class="float-end" :items="itemsThree"/>
        </div>

        <form v-if="viewPage">
            <row-component>
                <col-component class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                               :class="{'formError' : errors.name}">
                    <label>Nome:</label>
                    <input-component v-model="formData.name"/>
                </col-component>

                <col-component class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                               :class="{'formError' : errors.status}">
                    <label>Status:</label>
                    <MultiSelect v-model="formData.status" :options="selectActiveInative"/>
                </col-component>
            </row-component>

            <row-component>
                <col-component class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                               :class="{'formError' : errors.permissions}"
                               v-for="(permission, index) in permissionsPage" :key="index">
                    <div class="form-check bg-secondary-subtle mb-2">
                        <input class="form-check-input" type="checkbox" :id="'checkBoxMaster' + index" :name="index"
                               @click="checkAll(index)">
                        <label class="form-check-label">{{ permission.label }}</label>
                    </div>

                    <div class="form-check mb-2" v-for="(option, indexTwo) in permission.options" :key="indexTwo">
                        <input class="form-check-input" type="checkbox" :value="option.value"
                               :id="'checkOne' + option.value" :class="'form-check-input checkBoxAll' + index"
                               @click="checkOne(index, permission)">
                        <label class="form-check-label">{{ option.label }}</label>
                    </div>
                </col-component>
            </row-component>

            <row-component>
                <col-component class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-submit :disabled="sendForm" @click="submit" class="w-100" icon="save" name="Salvar"/>
                </col-component>

                <col-component class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-route class="w-100" :items="itemsTwo"/>
                </col-component>
            </row-component>
        </form>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import ColComponent from "@/components/row/ColComponent";
import InputComponent from "@/components/form/InputComponent";
import MultiSelect from "@/components/form/MultiSelect";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import ErrorComponent from "@/components/alerts/ErrorComponent";

import {useToast} from "vue-toastification";

const toast = useToast()
export default {
    name: "UserGroupUpdate",

    components: {
        ErrorComponent,
        ButtonRoute,
        ButtonSubmit,
        MultiSelect,
        InputComponent,
        ColComponent,
        RowComponent,
        PainelComponent
    },

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch("changeNamePage", "Grupo de Usuário")
        this.$store.dispatch("loadPermissions")
        this.loadItems()
        this.checkACL()
    },

    data() {
        return {
            itemsOne: {
                title: "Editar",
                icon: "edit"
            },

            itemsTwo: {
                route: "/user-group/search",
                icon: "reply all",
                name: "Voltar"
            },

            itemsThree: {
                route: "/user-group/create",
                icon: "plus",
            },

            formData: {
                name: "",
                status: "",
                permissions: []
            },

            create: false,

            errors: [],
            sendForm: false,
            viewPage: false,
            showError: false,
        }
    },

    methods: {
        loadItems() {
            this.$store.dispatch('loadUserGroup', this.id)
                .then((r) => {
                    this.formData = r
                    setTimeout(() => {
                        this.checkBoxCheckedStart()
                    }, 1000)
                }).catch(() => {
                toast.error('Erro desconhecido!', {timeout: 1500})
            })
        },

        submit() {
            if (!this.sendForm) {
                this.sendForm = true
                this.$store.dispatch('updateUserGroup', this.formData)
                    .then(() => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        window.scroll(0, 0)
                        toast.success('Cadastro atualizado com sucesso', {timeout: 1500})
                    }).catch((e) => {
                    this.showError = true
                    this.sendForm = false
                    window.scroll(0, 0)
                    this.errors = e.response.data.errors
                })
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLItems', 1)
                .then((r) => {
                    if (r.indexOf('group-user-edit') !== -1) {
                        this.viewPage = true

                        if (r.indexOf('group-user-create') !== -1) {
                            this.create = true
                        }
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        checkOne(index, permission) {
            let listPermission = document.getElementsByClassName("checkBoxAll" + index);
            let cont = 0;
            for (let i = 0; i < listPermission.length; ++i) {
                if (listPermission[i].checked) {
                    listPermission[i].checked = true;
                    if (!this.formData.permissions.includes(parseInt(listPermission[i].value))) {
                        this.formData.permissions.push(parseInt(listPermission[i].value))
                    }
                    cont = cont + 1;
                } else {
                    listPermission[i].checked = false;
                    let indexArray = this.formData.permissions.indexOf(parseInt(listPermission[i].value));
                    if (indexArray !== -1) {
                        this.formData.permissions.splice(indexArray, 1);
                    }
                }
            }
            document.getElementById("checkBoxMaster" + index).checked = cont >= permission.options.length;
        },

        checkAll(index) {
            let listPermission = document.getElementsByClassName("checkBoxAll" + index);
            if (document.getElementById("checkBoxMaster" + index).checked) {
                for (let i = 0; i < listPermission.length; ++i) {
                    listPermission[i].checked = true;
                    if (!this.formData.permissions.includes(parseInt(listPermission[i].value))) {
                        this.formData.permissions.push(parseInt(listPermission[i].value))
                    }
                }
            } else {
                for (let cont = 0; cont < listPermission.length; ++cont) {
                    listPermission[cont].checked = false;
                    let indexArray = this.formData.permissions.indexOf(parseInt(listPermission[cont].value));
                    if (indexArray !== -1) {
                        this.formData.permissions.splice(indexArray, 1);
                    }
                }
            }
        },

        checkBoxCheckedStart() {
            let totalPage = 0;
            let cont = 0;
            let i = 0;
            let permissionsList = this.formData.permissions;
            this.permissionsPage.forEach(function (element) {
                cont = 0;
                totalPage = element.options.length;
                element.options.forEach(function (item) {
                    if (permissionsList.includes(item.value)) {
                        document.getElementById("checkOne" + item.value).checked = true
                        cont = cont + 1;
                    }
                });
                if (cont >= totalPage) {
                    document.getElementById("checkBoxMaster" + i).checked = true
                }
                i = i + 1;
            });
        },
    },

    computed: {
        selectActiveInative() {
            return this.$store.state.var.selectActiveInative
        },

        permissionsPage() {
            return this.$store.state.var.permissionsPage
        }
    }
}
</script>