<template>
  
  <painel-component :items="itemsOne">
    <form>
      <row-component>
        
        <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
          <label>N°:</label>
          <input-component v-model="formData.code" placeholder="Código"/>
        </col-component>

        <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
            <label>Consultora:</label>
            <MultiSelect v-model="formData.creator_user_id" :options="clients"/>
          </col-component>

          <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
            <label>Cliente:</label>
            <MultiSelect v-model="formData.creator_user_id" :options="clients"/>
          </col-component>

        <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
          <label>Status:</label>
          <MultiSelect v-model="formData.status" :options="selectOpenOrClose" placeholder="Status"/>
        </col-component>
      </row-component>

      <row-component>
        <col-component class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
          <button-submit class="me-1" @click="submit" icon="search" name="Pesquisar"/>
        </col-component>
      </row-component>
    </form>

    <table class="table table-striped" v-if="viewPage">
      <thead>
      <tr>
        <th scope="col" style="width: 20%">N° </th>
        <th scope="col" style="width: 20%">Cliente <br> Consultora</th>
        <th scope="col" style="width: 20%">Status</th>
        <th scope="col" style="width: 15%">Data <br> Hora</th>
        <th scope="col" style="width: 15%">Valor total</th>
        <th scope="col" style="width: 15">Ações</th>
      </tr>
      </thead>

      <tbody>
        <tr v-for="(data, i) in object.data" :key="i" >
          <td data-label="Código">{{ data.code }}</td>
          <td data-label="Consultora" v-if="data.user_creater">
            {{ data.user_creater }}
            <br>
             <!-- <img :src="data.user_photo" alt="Foto consultora"> -->
             <img src="../../assets/avatar.png" class="user-photo" alt="Foto consultora">
          </td>

          <td data-label="Consultora" v-else>
              N/I <br>
              <img :src="data.user_photo" alt="Foto consultora">
              <!-- <img src="../../assets/avatar.png" class="user-photo" alt="Foto consultora"> -->
              {{data.user_creater}}
            </td>

            <td data-label="Status" v-if="data.status==1">Aberto</td>
            <td data-label="Status" v-if="data.status==2">Fechado</td>
            <td data-label="Status" v-if="data.status==3">3</td>
            <td data-label="Status" v-if="data.status==4">4</td>

            <td data-label="Data e Hora">
              <div>{{ data.dateOpen }}</div>
              <div>{{ data.hourOpen }}</div>
            </td>

            <td data-label="Valor total">R$ {{ data.price_total }}</td>
            
            <td data-label="Ações">
              <button-show :id="data.id" class="m-1" route="pdv-sales-historic-show"/>
              <button-submit :icon="'print'" class="m-1" @click="openPDF(data.linkPrint)"/>
            </td>
        </tr>
      </tbody>
    </table>

    <button-pagination :pagination="object" :offset="3" @paginate="loadItems"/>

  </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import ColComponent from "@/components/row/ColComponent";
import InputComponent from "@/components/form/InputComponent";
import MultiSelect from "@/components/form/MultiSelect";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ButtonPagination from "@/components/buttons/ButtonPagination";
import ButtonShow from "@/components/buttons/ButtonShow.vue";  
import {useToast} from "vue-toastification";

const toast = useToast()

export default {
  name: "SalesHistoricSearch",

  components: {
    ButtonPagination,
    ButtonSubmit,
    MultiSelect,
    InputComponent,
    ColComponent,
    RowComponent,
    PainelComponent,
    // LoaderComponent,
    ButtonShow
  },

  created() {
    this.$store.dispatch("changeNamePage", "Pedidos Caixa")
    this.checkACL()
    this.loadItems(1)
    this.loadClients()
  },

  data() {
    return {
      itemsOne: {
        title: "Pesquisar",
        icon: "list alternate"
      },

      buttonCheck: {
        create: false,
        edit: false
      },

      formData: {
        status: "",
        creator_user_id: "",
        code: "", 
        user_creater: "",
        user_photo: "",
        boxTotal: "",
        dateHourOpen: "",
        dateHourClose: ""
      },

      viewPage: false,
      searchSubmit: false,

      clients: [],
    }
  },

  methods: {
    submit() {
      this.loadItems(1)
      this.searchSubmit = true
    },

    loadItems(page) {
      if (!this.searchSubmit) {
        this.$store.dispatch('loadSalesHistorics', {...this.params, page})
          .then((r) => {
            if (this.searchSubmit) {
              this.searchSubmit = false;
              if (r.data.length === 0) {
                toast.info("Nada foi encontrado com os parâmetros informados!", {timeout: 2000});
              }
            }
          }).catch(() => {
            this.searchSubmit = false;
          });
        }
      },

    loadClients(page) {
      this.$store.dispatch("loadCustomers", { ...this.params, page })
        .then((r) => {
          // Mapear os nomes dos clientes para mostrar no select
          // console.log(r.data)
          this.clients = r.data.map((client) => ({
            label: client.name,
            value: client.id,
          }));
        })
        .catch(() => {});
    },
    
    // Permissions
    async checkACL() {
      this.$store.dispatch('ACLItems', 17)
          .then((r) => {
            if (r.indexOf('pdv-box-historic') !== -1) {
              this.viewPage = true

              if (r.indexOf('pdv-box-historic-search') !== -1) {
                this.viewPage = true
              }
              if (r.indexOf('pdv-box-historic-show') !== -1) {
                this.buttonCheck.create = true
              }
            } else {
              this.$router.push({name: 'home'})
            }
          }).catch(() => {
        this.$router.push({name: 'home'})
      })
    },

    statusString(value) {
      if (parseInt(value) === 1)
        return "Ativo"
      return "Inativo"
    },

    openPDF(link) {
        if (link) {
          window.open(link, '_blank');
        } else {
          console.error('Link do PDF não disponível');
        }
      }
  },

  computed: {
    selectOpenOrClose() {
      return this.$store.state.var.selectOpenOrClose
    },

    object() {
      return this.$store.state.sales_historic.items
    },

    params() {
      return {
        code: this.formData.code,
        creator_user_id: this.formData.creator_user_id,
        client_id: this.formData.client_id,
        status: this.formData.status
      }
    }
  }
}
</script>

<style>
.user-photo{
width: 30px;
border-radius: 50%;
}

</style>