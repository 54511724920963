import AdminComponent from '@/components/dashboard/AdminComponent'
import ItensNFSearch from "@/views/search/ItensNFSearch.vue";
import ItensNFCreate from "@/views/create/ItensNFCreate.vue";
import ItensNFUpdate from "@/views/update/ItensNFUpdate.vue";

export default {
    path: '/nf-itens',
    component: AdminComponent,
    meta: {auth: true},
    children: [
        {path: 'search', name: 'nf-itens-search', component: ItensNFSearch},
        {path: 'create', name: 'nf-itens-create', component: ItensNFCreate},
        {path: 'edit/:id', name: 'nf-itens-edit', component: ItensNFUpdate, props: true},
    ]
}