import {createStore} from 'vuex'

import Auth from "@/store/modules/auth";
import Category from "@/store/modules/category";
import Client from "@/store/modules/client";
import ECommerceCart from "@/store/modules/e-commerce-cart";
import ECommerceClient from "@/store/modules/e-commerce-client";
import ECommerceProduct from "@/store/modules/e-commerce-product";
import ECommerceSales from "@/store/modules/e-commerce-sales";
import Employee from "@/store/modules/employee";
import ExternalApi from "@/store/modules/external-api";
import GenerateNF from './modules/generateNF';
import ItensNF from './modules/itensNF';
import Inventory from "@/store/modules/inventory";
import Loader from "@/store/modules/loader";
import Product from "@/store/modules/product";
import Subcategory from "@/store/modules/subcategory";
import Supplier from "@/store/modules/supplier";
import User from "@/store/modules/user";
import UserGroup from "@/store/modules/user-group";
import Nf from "@/store/modules/nf";
import Var from "@/store/modules/var";
import AccountBank from "@/store/modules/account-bank";
import AccountType from "@/store/modules/account-type";
import EntranceExitPreview from "@/store/modules/entrance-exit-preview";
import EntranceExit from "@/store/modules/entrance-exit";
import ReportProducts from "@/store/modules/report-products";
import boxHistoric from '@/store/modules/box-historic';
import salesHistoric from '@/store/modules/sales-historic';
import dashboardBox from '@/store/modules/dashboard-box';
import Home from '@/store/modules/home';
import Consigned from '@/store/modules/consigned';

export default createStore({
    modules: {
        auth: Auth,
        category: Category,
        client: Client,
        e_commerce_cart: ECommerceCart,
        e_commerce_client: ECommerceClient,
        e_commerce_product: ECommerceProduct,
        e_commerce_sales: ECommerceSales,
        employee: Employee,
        external_api: ExternalApi,
        inventory: Inventory,
        loader: Loader,
        nf_generate: GenerateNF,
        nf_itens: ItensNF,
        product: Product,
        subcategory: Subcategory,
        supplier: Supplier,
        user: User,
        user_group: UserGroup,
        var: Var,
        Nf: Nf,
        account_bank: AccountBank,
        account_type: AccountType,
        entrance_exit_preview:EntranceExitPreview,
        entrance_exit:EntranceExit,
        reportProducts: ReportProducts,
        box_historic: boxHistoric,
        sales_historic: salesHistoric,
        box_dashboard: dashboardBox,
        home: Home,
        consigned: Consigned
    }
})
