import axios from "axios";

const RESOURCE = "api/v1/bw/sales-nf";
export default {

    storeNF(context, formData) {
        return new Promise((resolve, reject) => {
            axios.post(`${RESOURCE}`, formData)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally()

        })
    },


    loadNF(context, id) {
        return new Promise((resolve, reject) => {
            axios.get(`${RESOURCE}/${id}`)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            })
        })
    },







}