import AdminComponent from "@/components/dashboard/AdminComponent";

import CommerceCartSearch from "@/views/search/CommerceCartSearch";
import CommerceCartShow from "@/views/show/CommerceCartShow.vue";

export default {
    path: "/e-commerce-cart",
    meta: {auth: true},
    component: AdminComponent,
    children: [
        {path: "search", name: "commerce-cart-search", component: CommerceCartSearch},
        {path: "show/:id", name: "commerce-cart-show", component: CommerceCartShow, props: true},
    ]
}