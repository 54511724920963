<template>
    <div class="main-box">
        <div class="logo-sedex">
            <!--            <img src="image.png" alt="">-->
        </div>

        <h2>Destinatário:</h2>

        <ul class="destiny">
            <li>{{ formData.end_nome_destinatario }}</li>
            <li>{{ formData.end_endereco }},</li>
            <li>{{ formData.end_numero }}</li>
            <li>{{ formData.end_bairro }}</li>
            <li>{{ formData.end_cidade }}/{{ formData.end_estado }}</li>
            <li>{{ formData.end_cep }}</li>
        </ul>

        <div class="barcode my-4">
            <vue3-barcode :value="formData.n_envio ? formData.n_envio : formData.end_cep" :height="50"/>
        </div>

        <ul class="sender">
            <li><h3>Remetente</h3></li>
            <li>REGALLI SEMIJOIAS</li>
            <li>FONE: (19) 9 9661-9942</li>
            <li>Rua Dario Roland 664</li>
            <li>Jardim Res. Santina Paroli Peccinino</li>
            <li>LIMEIRA/SP</li>
            <li>13481-385</li>
        </ul>
    </div>
</template>

<script>
import Vue3Barcode from 'vue3-barcode'

export default {
    components: {
        Vue3Barcode,
    },

    props: {
        id: {
            required: true
        }
    },

    data() {
        return {
            formData: {
                id: this.id,
                data: "",
                end_bairro: "",
                end_cep: "",
                end_cidade: "",
                end_endereco: "",
                end_estado: "",
                end_numero: "",
                end_nome_destinatario: "",
                n_envio: "",
            },
        }
    },

    created() {
        this.loadItems()
    },

    methods: {
        loadItems() {
            this.$store.dispatch('loadCommerceSale', this.id)
                .then((r) => {
                    this.formData = r.sales.original.registros
                    setTimeout(() => {
                        window.print()
                        window.close()
                    }, 1000)
                })
        },
    },
}
</script>

<style scoped>
.main-box {
    width: 450px;
    padding: 1rem;
    height: 480px;
    position: relative;
    border-radius: 3px;
    background: #FFFFFF;
    border: 1px solid #000;
    -webkit-print-color-adjust: exact;
}

h2 {
    color: #fff;
    padding: 0 4rem;
    background: #000;
    font-size: 1.2rem;
    width: fit-content;
    text-transform: uppercase;
}

.destiny {
    padding: 0;
    list-style: none;
    font-weight: bold;
}

.destiny li {
    margin-bottom: 0.2rem;
}

.sender {
    padding: 0;
    font-size: 12px;
    list-style: none;
}

h3 {
    margin: 0;
    color: black;
    font-weight: bold;
    font-size: 1.17em;
}

.barcode {
    text-align: center;
}

.logo-sedex img {
    right: 1rem;
    height: 50px;
    position: absolute;
}
</style>