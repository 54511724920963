<template>
  <div class="painel p-4 pt-5">

    <div role="button" @click="this.$emit('closeDown')" class="btn-close"></div>

     <h1>Baixar NF</h1>

    <a :href="NFPDF" target="_blank"><i class="icon file pdf"></i>PDF</a>
    <a :href="NFXML" target="_blank"><i class="icon file excel"></i>XML</a>


    <br>



  </div>
</template>

<script>

export default {
  name: "NFModalDow",


  props: {
    id: {
      required: true
    }
  },

  created() {
    this.loadNF()
  },

  data() {
    return {

      textOne: true,
      textTwo: false,
      NFPDF:'',
      NFXML:'',
      formData: {
        id: this.id
      }

    }
  },


  methods: {
    loadNF() {
      this.textOne = false
      this.textTwo = true
      this.$store.dispatch('loadNF', this.id)
          .then((r) => {
            this.NFPDF=r.linkPDF
            this.NFXML=r.linkXML
          }).catch(() => {
      })

    },


  },


}
</script>

<style scoped>
.painel {
  position: relative;
  background: #FFFFFF;
}

.btn-close {
  top: 10px;
  right: 10px;
  position: absolute;
}

h1 {
  font-size: 20px;

  padding: 10px 50px;
  text-transform: uppercase;
}

a{
  display: inline-block;
  width: 100px;
  text-align: center;
  font-size: 16px;
  color: #000000;
  text-decoration: none;
}

a i{
  font-size: 60px;
  color: #000000;
}

</style>