<template>
    <painel-component :items="itemsOne">
        <error-component :errors="errors" v-if="showError"/>

        <div class="tabs effect-3" v-if="viewPage">
            
            <input type="radio" id="tab-1" name="tab-effect-3" :class="{ 'mobileTab-client': tabMobile }" checked>
            <span :class="{ 'mobileTab-client': tabMobile }">
                <i class="info circle icon"></i>
                <span>Informações</span>
            </span>

            <input type="radio" id="tab-2" name="tab-effect-3" v-if="picked === 2">
            <span v-if="picked === 2">
                <i class="user circle icon"></i>
                <span>Sócio</span>
            </span>

            <input type="radio" id="tab-3" name="tab-effect-3" :class="{ 'mobileTab-client': tabMobile }">
            <span :class="{ 'mobileTab-client': tabMobile }">
                <i class="envelope icon"></i>
                <span>Email</span>
            </span>

            <input type="radio" id="tab-4" name="tab-effect-3" :class="{ 'mobileTab-client': tabMobile }">
            <span :class="{ 'mobileTab-client': tabMobile }">
                <i class="phone square icon"></i>
                <span>Telefone</span>
            </span>

            <input type="radio" id="tab-5" name="tab-effect-3" v-if="picked === 2">
            <span v-if="picked === 2">
                <i class="tasks icon"></i>
                <span>Atividades econômicas secundárias</span>
            </span>

            <div class="line ease" :class="{ 'line-3': tabMobile }"></div>
            <div class="tab-content">
                <section id="tab-item-1">
                    <form>
                        <row-component>
                            <col-component class-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                                <div class="form-check form-check-inline">
                                    <input v-model="formData.type" class="" type="radio" value="1"
                                           @change="changeType" name="types" id="type-one">
                                    <label class="form-check-label" for="type-one">CPF</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input v-model="formData.type" class="" type="radio" value="2"
                                           @change="changeType" name="types" id="type-two" checked>
                                    <label class="form-check-label" for="type-two">CNPJ</label>
                                </div>
                            </col-component>
                        </row-component>

                        <row-component v-if="picked === 2">
                            <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                           :class="{'formError' : errors.cnpj}">
                                <label>CNPJ:</label>
                                <input-component @change="searchCNPJ" v-model="formData.cnpj"
                                                 v-mask="'##.###.###/####-##'"/>
                            </col-component>

                            <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                           :class="{'formError' : errors.size}">
                                <label>Porte:</label>
                                <input-component v-model="formData.size"/>
                            </col-component>

                            <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                           :class="{'formError' : errors.size}">
                                <label>IE:</label>
                                <input-component v-model="formData.ie"/>
                            </col-component>

                            <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                           :class="{'formError' : errors.status}">
                                <label>Status:</label>
                                <MultiSelect v-model="formData.status" :options="selectActiveInative"/>
                            </col-component>
                        </row-component>

                        <row-component>
                            <col-component class-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                           :class="{'formError' : errors.name}">
                                <label>Nome:</label>
                                <input-component v-model="formData.name"/>
                            </col-component>
                        </row-component>

                        <row-component v-if="picked === 2">
                            <col-component class-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                           :class="{'formError' : errors.name_f}">
                                <label>Nome Fantasia:</label>
                                <input-component v-model="formData.name_f"/>
                            </col-component>
                        </row-component>

                        <row-component>
                            <col-component class-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                           :class="{'formError' : errors.surname}">
                                <label>Apelido:</label>
                                <input-component v-model="formData.surname"/>
                            </col-component>
                        </row-component>

                        <div v-if="picked === 2">
                            <row-component>
                                <col-component class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                                               :class="{'formError' : errors.legal_nature}">
                                    <label>Natureza Jurídica:</label>
                                    <input-component v-model="formData.legal_nature"/>
                                </col-component>

                                <col-component class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                                               :class="{'formError' : errors.share_capital}">
                                    <label>Capital Social:</label>
                                    <input-component v-model="formData.share_capital" v-money="MONEY"/>
                                </col-component>
                            </row-component>

                            <row-component>
                                <col-component class-col="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12"
                                               :class="{'formError' : errors.main_activity}">
                                    <label>Atividade Econômica Principal:</label>
                                    <input-component v-model="formData.main_activity"/>
                                </col-component>

                                <col-component class-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12"
                                               :class="{'formError' : errors.date_open}">
                                    <label>Data de Abertura:</label>
                                    <input-component v-model="formData.date_open" v-mask="'##/##/####'"/>
                                </col-component>

                                <col-component class-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12"
                                               :class="{'formError' : errors.headquarters}">
                                    <label>Matriz:</label>
                                    <input-component v-model="formData.headquarters"/>
                                </col-component>
                            </row-component>
                        </div>

                        <div v-if="picked === 1">
                            <row-component>
                                <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                                    <label>CPF:</label>
                                    <input-component @change="searchCPF" v-model="formData.cpf"
                                                     v-mask="'###.###.###-##'"/>
                                </col-component>

                                <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                               :class="{'formError' : errors.rg}">
                                    <label>RG:</label>
                                    <input-component v-model="formData.rg" v-mask="['##.###.###-X', '#.###.###']"/>
                                </col-component>

                                <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                               :class="{'formError' : errors.birth_date}">
                                    <label>Data Nasc:</label>
                                    <input-component v-model="formData.birth_date" v-mask="'##/##/####'"/>
                                </col-component>

                                <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                               :class="{'formError' : errors.status}">
                                    <label>Status:</label>
                                    <MultiSelect v-model="formData.status" :options="selectActiveInative"/>
                                </col-component>
                            </row-component>
                        </div>

                        <row-component>
                            <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                           :class="{'formError' : errors.cep}">
                                <label>CEP:</label>
                                <input-component v-model="formData.cep" v-mask="'#####-###'" @change="searchApiCEP"/>
                            </col-component>

                            <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                           :class="{'formError' : errors.city}">
                                <label>Cidade:</label>
                                <input-component v-model="formData.city"/>
                            </col-component>

                            <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                           :class="{'formError' : errors.state}">
                                <label>Estado:</label>
                                <!-- <input-component v-model="formData.state"/> -->
                                <MultiSelect
                                    v-model="selectedEstados"
                                    :options="estados"
                                    label="label" 
                                    value="value" 
                                    placeholder="Selecione os estados"
                                    multiple
                                />
                            </col-component>
                            <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                           :class="{'formError' : errors.district}">
                                <label>Bairro:</label>
                                <input-component v-model="formData.district"/>
                            </col-component>
                        </row-component>

                        <row-component>
                            <col-component class-col="col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12"
                                           :class="{'formError' : errors.address}">
                                <label>Endereço:</label>
                                <input-component v-model="formData.address"/>
                            </col-component>

                            <col-component class-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12"
                                           :class="{'formError' : errors.number}">
                                <label>N°:</label>
                                <input-component v-model="formData.number"/>
                            </col-component>
                        </row-component>

                        <row-component>
                            <col-component class-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                           :class="{'formError' : errors.complement}">
                                <label>Referência:</label>
                                <text-area rows="6" v-model="formData.complement"/>
                            </col-component>
                        </row-component>
                    </form>
                </section>

                <section id="tab-item-2">
                    <div class="clearfix mb-3">
                        <button-submit class="float-end" @click="addMembers" icon="plus"/>
                    </div>

                    <template v-for="(data, index) in membersAdd" :key="index">
                        <form class="bg-body-secondary p-3 mb-3">
                            <row-component>
                                <col-component class-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                    <label>Nome:</label>
                                    <input-component v-model="data.name"/>
                                </col-component>
                            </row-component>

                            <row-component>
                                <col-component class-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                    <label>CPF/CNPJ:</label>
                                    <input-component v-model="data.cpf_cnpj"/>
                                </col-component>

                                <col-component class-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                    <label>Idade:</label>
                                    <input-component v-model="data.age"/>
                                </col-component>

                                <col-component class-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                    <label>Qualificação::</label>
                                    <input-component v-model="data.qualification"/>
                                </col-component>
                            </row-component>

                            <div class="clearfix" v-if="membersAdd.length >= 2">
                                <button-delete class="float-end" @click="removeMembers(index)"/>
                            </div>
                        </form>
                    </template>
                </section>

                <section id="tab-item-3">
                    <div class="clearfix mb-3">
                        <button-submit class="float-end" @click="addEmails" icon="plus"/>
                    </div>

                    <template v-for="(data, index) in emailsAdd" :key="index">
                        <form class="bg-body-secondary p-3 mb-3">
                            <row-component>

                                <col-component class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                
                                    <label>Receber notificação:</label>

                                    <div class="form-check form-check-inline">
                                        <input v-model="data.notification" class="" type="radio"
                                               value="1" :id="'emailS' + index" >
                                        <label class="form-check-label" :for="'emailS' + index">Sim</label>
                                    </div>

                                    <div class="form-check form-check-inline">
                                        <input v-model="data.notification" class="" type="radio"
                                               value="2" :id="'emailN' + index" checked>
                                        <label class="form-check-label" :for="'emailN' + index">Não</label>
                                    </div>

                                </col-component>

                            </row-component>

                            <row-component>
                                <col-component class-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                    <label>Nome:</label>
                                    <input-component v-model="data.name"/>
                                </col-component>

                                <col-component class-col="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                                    <label>Email:</label>
                                    <input-component type="email" v-model="data.email"/>
                                </col-component>
                            </row-component>

                            <div class="clearfix" v-if="emailsAdd.length >= 2">
                                <button-delete class="float-end" @click="removeEmails(index)"/>
                            </div>
                        </form>
                    </template>
                </section>

                <section id="tab-item-4">
                    <div class="clearfix mb-3">
                        <button-submit class="float-end" @click="addPhones" icon="plus"/>
                    </div>

                    <template v-for="(data, index) in phonesAdd" :key="index">
                        <form class="bg-body-secondary p-3 mb-3">
                            <row-component>
                                <col-component class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                    <label>Receber notificação:</label>

                                    <div class="form-check form-check-inline">
                                        <input v-model="data.notification" type="radio"
                                               value="1" :id="'phoneS' + index">
                                        <label class="form-check-label" :for="'phoneS' + index">Sim</label>
                                    </div>

                                    <div class="form-check form-check-inline">
                                        <input v-model="data.notification" type="radio"
                                               value="2" :id="'phoneN' + index" checked>
                                        <label class="form-check-label" :for="'phoneN' + index">Não</label>
                                    </div>
                                </col-component>
                            </row-component>

                            <row-component>
                                <col-component class-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                    <label>Nome:</label>
                                    <input-component v-model="data.name"/>
                                </col-component>

                                <col-component class-col="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                                    <label>Telefone:</label>
                                    <input-component v-mask="['(##) ####-####', '(##) #####-####']"
                                                     v-model="data.phone"/>
                                </col-component>
                            </row-component>

                            <div class="clearfix" v-if="phonesAdd.length >= 2">
                                <button-delete class="float-end" @click="removePhones(index)"/>
                            </div>
                        </form>
                    </template>
                </section>

                <section id="tab-item-5">
                    <div class="clearfix mb-3">
                        <button-submit class="float-end" @click="addActivities" icon="plus"/>
                    </div>

                    <template v-for="(data, index) in activitiesAdd" :key="index">
                        <form class="bg-body-secondary p-3 mb-3">
                            <row-component>
                                <col-component class-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                    <label>Código:</label>
                                    <input-component v-model="data.code"/>
                                </col-component>

                                <col-component class-col="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                                    <label>Descrição:</label>
                                    <input-component type="email" v-model="data.desc"/>
                                </col-component>
                            </row-component>

                            <div class="clearfix" v-if="activitiesAdd.length >= 2">
                                <button-delete class="float-end" @click="removeActivities(index)"/>
                            </div>
                        </form>
                    </template>
                </section>

                <row-component>
                    <col-component class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                        <button-submit :disabled="sendForm" @click="submit" class="w-100" icon="plus" name="Adicionar"/>
                    </col-component>

                    <col-component class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                        <button-route class="w-100" :items="itemsTwo"/>
                    </col-component>
                </row-component>
            </div>
        </div>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import ColComponent from "@/components/row/ColComponent";
import MultiSelect from "@/components/form/MultiSelect";
import InputComponent from "@/components/form/InputComponent";
import TextArea from "@/components/form/TextArea";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import ButtonDelete from "@/components/buttons/ButtonDelete";
import ErrorComponent from "@/components/alerts/ErrorComponent";
import { ESTADOS } from "@/configs/constants/index";

import {VMoney} from "v-money";
import {mask} from "vue-the-mask"
import { MONEY } from "@/configs/constants";

import {useToast} from "vue-toastification";

const toast = useToast()
export default {
    name: "ClientCreate",
    components: {
        ErrorComponent,
        ButtonDelete,
        ButtonRoute,
        ButtonSubmit,
        TextArea,
        InputComponent,
        MultiSelect,
        ColComponent,
        RowComponent,
        PainelComponent
    },

    directives: {
        mask,
        money: VMoney
    },

    created() {
        this.$store.dispatch("changeNamePage", "Cliente")
        this.nullArray()
        this.checkACL()
        this.formatEstados()
    },

    data() {
        return {
            itemsOne: {
                title: "Adicionar",
                icon: "pen square"
            },

            itemsTwo: {
                route: "/client/search",
                icon: "reply all",
                name: "Voltar"
            },

            formData: {
                cpf: "",
                cnpj: "",
                size: "",
                name: "",
                name_f: "",
                surname: "",
                legal_nature: "",
                share_capital: "",
                federative_entity: "",
                main_activity: "",
                date_open: "",
                birth_date: "",
                headquarters: "",
                date_situation: "",
                situation: "",
                reason_situation: "",
                special_date: "",
                special_situation: "",
                rg: "",
                status: 1,
                type: 2,
                members: [],
                emails: [],
                phones: [],
                secondary_activitys: [],
                cep: "",
                city: "",
                state: "",
                district: "",
                address: "",
                complement: "",
                country: "",
                number: "",
                ie: ""
            },

            picked: 2,

            MONEY,

            errors: [],
            sendForm: false,
            viewPage: false,
            showError: false,

            estados: [],
            selectedEstados: []
        }
    },

    methods: {
        formatEstados() {
            this.estados = Object.keys(ESTADOS).map(key => ({
                value: key,
                label: ESTADOS[key]
            }));
        },
        submit() {
            if (!this.sendForm) {
                this.sendForm = true
                this.$store.dispatch('storeClient', this.formData)
                    .then((r) => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success('Cadastro efetuado com sucesso', {timeout: 1500})
                        this.$router.push({name: 'client-edit', params: {id: r}})
                    }).catch((e) => {
                    this.showError = true
                    this.sendForm = false
                    window.scroll(0, 0)
                    this.errors = e.response.data.errors
                })
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLItems', 3)
                .then((r) => {
                    if (r.indexOf('client-create') !== -1) {
                        this.viewPage = true
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        dateFormatBR(inputDate) {
            let dateArray = inputDate.split("-");
            return `${dateArray[2]}/${dateArray[1]}/${dateArray[0]}`;
        },

        searchCPF() {
            const dateCPF = {'type': this.formData.type, 'cpf': this.formData.cpf}
            this.$store.dispatch('searchCPF', dateCPF)
                .then((r) => {
                    if (r.check) {
                        toast.info(`Cliente já está cadastrado, ${this.me.name}!`, {timeout: 2000});
                        this.resetFormData()
                    }
                }).catch(() => {
            })
        },

        searchCNPJ() {
            const dateCNPJ = {'type': this.formData.type, 'cnpj': this.formData.cnpj}
            this.$store.dispatch('searchCNPJ', dateCNPJ)
                .then((r) => {
                    if (r.check) {
                        toast.info(`Cliente já está cadastrado, ${this.me.name}!`, {timeout: 1500});
                        this.resetFormData()
                    } else {
                        this.searchAPiCNPJ()
                    }
                }).catch(() => {
            })
        },

        searchAPiCNPJ() {
            const formData = {'cnpj': this.formData.cnpj.replace(/\D/g, '')}
            this.$store.dispatch('searchApiCNPJ', formData)
                .then((r) => {
                    this.selectedEstados = [r.data.address.state]
                    this.completeInput(r.data)
                })
        },

        searchApiCEP() {
            this.resetFormAddress()
            let cep = this.formData.cep.replace(/\D/g, '');
            const formData = {'cep': cep}
            this.$store.dispatch('searchApiCEP', formData)
                .then((r) => {
                    this.formData.city = r.data.localidade
                    this.formData.state = r.data.uf
                    this.formData.stateView = r.data.uf
                    this.formData.district = r.data.bairro
                    this.formData.address = r.data.logradouro
                    this.formData.complement = r.data.complemento

                    this.selectedEstados = [r.data.uf];
                })
        },

        changeType() {
            this.resetFormData()
            this.nullArray()
            if (parseInt(this.formData.type) === 1) this.picked = 1
            else this.picked = 2
        },

        nullArray() {
            if (this.membersAdd.length === 0)
                this.addMembers()
            if (this.emailsAdd.length === 0)
                this.addEmails()
            if (this.phonesAdd.length === 0)
                this.addPhones()
            if (this.activitiesAdd.length === 0)
                this.addActivities()
        },

        addMembers() {
            this.formData.members.push({
                name: "",
                cpf_cnpj: "",
                age: "",
                qualification: ""
            })
        },

        removeMembers(index) {
            this.formData.members.splice(index, 1)
        },

        addEmails() {
            this.formData.emails.push({
                name: "",
                email: "",
                notification: ""
            })
        },

        removeEmails(index) {
            this.formData.emails.splice(index, 1)
        },

        addPhones() {
            this.formData.phones.push({
                name: "",
                phone: "",
                notification: ""
            })
        },

        removePhones(index) {
            this.formData.phones.splice(index, 1)
        },

        addActivities() {
            this.formData.secondary_activitys.push({
                code: "",
                desc: "",
            })
        },

        removeActivities(index) {
            this.formData.phones.splice(index, 1)
        },


        resetFormAddress() {
            this.formData.city = ""
            this.formData.state = ""
            this.formData.stateView = ""
            this.formData.district = ""
            this.formData.address = ""
            this.formData.complement = ""
            this.formData.country = "Brasil"
        },

        resetFormData() {
            this.formData = {
                cpf: "",
                cnpj: "",
                size: "",
                name: "",
                name_f: "",
                surname: "",
                legal_nature: "",
                share_capital: "",
                main_activity: "",
                date_open: "",
                birth_date: "",
                headquarters: "",
                rg: "",
                type: this.formData.type,
                status: 1,
                emails: [],
                phones: [],
                members: [],
                secondary_activitys: [],
                cep: "",
                city: "",
                state: "",
                district: "",
                address: "",
                complement: "",
                country: "",
                company_status: ""
            }
        },

        completeInput(data) {
            this.formData.name = data.company.name
            this.formData.name_f = data.alias
            this.formData.surname = this.formData.name_f
            this.formData.legal_nature = `${data.company.nature.id} - ${data.company.nature.text}`
            this.formData.share_capital = data.company.equity.toLocaleString('pt-br', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });
            this.formData.main_activity = `${data.mainActivity.id} - ${data.mainActivity.text}`
            this.formData.date_open = this.dateFormatBR(data.founded)
            this.formData.headquarters = data.head ? 'Sim' : 'Não'
            this.formData.company_status = `${data.status.id} - ${data.status.text}`
            this.formData.cep = data.address.zip
            this.formData.city = data.address.city
            this.formData.stateView = data.address.state
            this.formData.state = data.address.state
            this.formData.district = data.address.district
            this.formData.address = data.address.street
            this.formData.number =  data.address.number
            this.formData.country = data.address.country.name
            this.formData.complement = data.address.details
            this.formData.size = `${data.company.size.id} -  ${data.company.size.acronym} - ${data.company.size.text}`
            if (data.company.members.length > 0) {
                this.formData.members = []
                data.company.members.forEach(element => {
                    this.formData.members.push({
                        name: element.person.name,
                        cpf_cnpj: element.person.taxId,
                        age: element.person.age,
                        qualification: `${element.role.id} - ${element.role.text}`
                    })
                });
            }
            if (data.emails.length > 0) {
                this.formData.emails = []
                data.emails.forEach(element => {
                    this.formData.emails.push({
                        name: element.domain,
                        email: element.address,
                        notification: '1'
                    })
                });
            }
            if (data.phones.length > 0) {
                this.formData.phones = []
                data.phones.forEach(element => {
                    this.formData.phones.push({
                        name: '',
                        phone: `${element.area}${element.number}`,
                        notification: '1'
                    })
                });
            }
            if (data.sideActivities.length > 0) {
                this.formData.secondary_activitys = []
                data.sideActivities.forEach(element => {
                    this.formData.secondary_activitys.push({
                        code: `${element.id}`,
                        desc: `${element.text}`
                    })
                });
            }
        },
    },

    computed: {
        me() {
            return this.$store.state.auth.me
        },

        selectActiveInative() {
            return this.$store.state.var.selectActiveInative
        },

        tabMobile() {
            return parseInt(this.picked) !== 2;
        },

        membersAdd() {
            return this.formData.members
        },

        emailsAdd() {
            return this.formData.emails
        },

        phonesAdd() {
            return this.formData.phones
        },

        activitiesAdd() {
            return this.formData.secondary_activitys
        },
    }
}
</script>