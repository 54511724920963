import AdminComponent from "@/components/dashboard/AdminComponent";

import CommerceSalesSearch from "@/views/search/CommerceSalesSearch";
import CommerceSalesShow from "@/views/show/CommerceSalesShow";

export default {
    path: "/e-commerce-sales",
    meta: {auth: true},
    component: AdminComponent,
    children: [
        {path: "search", name: "commerce-sales-search", component: CommerceSalesSearch},
        {path: "show/:id", name: "commerce-sales-show", component: CommerceSalesShow, props: true},
    ]
}