import AdminComponent from '@/components/dashboard/AdminComponent'
import DashboardBoxSearch from "@/views/search/DashboardBoxSearch.vue";
import DashboardBoxShow from "@/views/show/DashboardBoxShow.vue";


export default {
    path: '/pdv-box-dashboard',
    component: AdminComponent,
    meta: {auth: true},
    children: [
        {path: 'search', name: 'pdv-box-dashboard-search', component: DashboardBoxSearch},
        {path: 'show/:id', name: 'pdv-box-dashboard-show', component: DashboardBoxShow, props:true},
    ]
}