<template>
    <painel-component :items="itemsOne">
        <div class="tabs effect-3">
            <input type="radio" id="tab-1" name="tab-effect-3" checked>
            <span>
                <i class="info circle icon"></i>
                <span>Informações</span>
            </span>

            <input type="radio" id="tab-2" name="tab-effect-3">
            <span>
                <i class="address book icon"></i>
                <span>Contato</span>
            </span>

            <div class="line ease"></div>
            <div class="tab-content">
                <section id="tab-item-1">
                    <row-component>
                        <col-component class-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <label>Nome:</label>
                            <input-component v-model="formData.nome" disabled/>
                        </col-component>
                    </row-component>

                    <row-component>
                        <col-component class-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                            <label>Data de Nascimento:</label>
                            <input-component v-model="formData.nascimento" disabled/>
                        </col-component>

                        <col-component class-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                            <label>CPF/CNPJ:</label>
                            <input-component v-mask="['###.###.###-##', '##.###.###/####-##']"
                                             v-model="formData.cpf_cnpj" disabled/>
                        </col-component>

                        <col-component class-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                            <label>Carteira de Identidade (RG):</label>
                            <input-component v-mask="['##.###.###-X', '#.###.###']" v-model="formData.rg" disabled/>
                        </col-component>
                    </row-component>

                    <row-component>
                        <col-component class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                            <label>Inscrição estadual (IE):</label>
                            <input-component v-model="formData.ie" disabled/>
                        </col-component>

                        <col-component class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                            <label>Idioma:</label>
                            <input-component class="text-uppercase" v-model="formData.idioma" disabled/>
                        </col-component>
                    </row-component>
                </section>

                <section id="tab-item-2">
                    <row-component>
                        <col-component class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                            <label>E-mail:</label>
                            <input-component v-model="formData.email" disabled/>
                        </col-component>

                        <col-component class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                            <label>Login:</label>
                            <input-component v-model="formData.login" disabled/>
                        </col-component>
                    </row-component>

                    <row-component>
                        <col-component class-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                            <label>Telefone Principal:</label>
                            <input-component v-mask="['(##) ####-####', '(##) #####-####']" v-model="formData.fone"
                                             disabled/>
                        </col-component>

                        <col-component class-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                            <label>Celular:</label>
                            <input-component v-mask="['(##) ####-####', '(##) #####-####']" v-model="formData.celular"
                                             disabled/>
                        </col-component>

                        <col-component class-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                            <label>WhatsApp:</label>
                            <input-component v-mask="['(##) ####-####', '(##) #####-####']" v-model="formData.whatsapp"
                                             disabled/>
                        </col-component>
                    </row-component>
                </section>

                <row-component>
                    <col-component class-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <button-route class="w-100" :items="itemsTwo"/>
                    </col-component>
                </row-component>
            </div>
        </div>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import ColComponent from "@/components/row/ColComponent";
import RowComponent from "@/components/row/RowComponent";
import InputComponent from "@/components/form/InputComponent";
import ButtonRoute from "@/components/buttons/ButtonRoute";

import {mask} from "vue-the-mask";
import {useToast} from "vue-toastification";

const toast = useToast()
export default {
    name: "CommerceClientShow",
    components: {
        ButtonRoute,
        InputComponent,
        RowComponent,
        ColComponent,
        PainelComponent
    },

    directives: {
        mask
    },

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch("changeNamePage", "Ecommerce Cliente")
        this.loadItems()
    },

    data() {
        return {
            itemsOne: {
                icon: "eye",
                title: "Ver"
            },

            itemsTwo: {
                route: "/e-commerce-client/search",
                icon: "reply all",
                name: "Voltar"
            },

            formData: {
                nome: "",
                email: "",
                login: "",
                cpf_cnpj: "",
                idioma: "",
                nascimento: "",
                fone: "",
                celular: "",
                whatsapp: "",
                rg: "",
                ie: "",
            }
        }
    },

    methods: {
        loadItems() {
            this.$store.dispatch('loadCommerceClient', this.id)
                .then((r) => {
                    this.formData = r.registros
                    this.formatDate(r.registros.nascimento)
                }).catch(() => {
                toast.error('Erro desconhecido!', {timeout: 1500})
            })
        },

        formatDate(value) {
            if (value) {
                let date = value.split(" ")[0]
                date = date.split("-")
                return this.formData.nascimento = `${date[2]}/${date[1]}/${date[0]}`
            }
            return this.formData.nascimento = "N/D"
        },
    }
}
</script>

<style scoped>
.tabs > input,
.tabs > span,
.effect-3 .line {
    width: 50%;
}

#tab-2,
#tab-2 + span,
#tab-2:checked ~ .line {
    left: 50%;
}
</style>