<template>
  <painel-component :items="itemsOne">
    <form>
      <row-component>
        <col-component class-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
          <label>Nº:</label>
          <input-component v-model="formData.ref" placeholder="Número"/>
        </col-component>

        <col-component class-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
          <label>Cliente:</label>
          <MultiSelect v-model="formData.client_id" :options="clients" placeholder="Cliente"/>
        </col-component>

        <col-component class-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
          <label>Status:</label>
          <MultiSelect v-model="formData.status" :options="selectStatusGenerateNF" placeholder="Status"/>
        </col-component>
      </row-component>

      <row-component>
        <col-component class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
          <button-submit class="me-1" @click="submit" icon="search" name="Pesquisar"/>
          <button-route :items="itemsTwo"/>
        </col-component>
      </row-component>
    </form>

    <table class="table table-striped" v-if="viewPage">
      <thead>
        <tr>
          <th scope="col" style="width: 20%">Nº</th>
          <th scope="col" style="width: 40%">Nome Cliente</th>
          <th scope="col" style="width: 40%">Status</th>
          <th scope="col" style="width: 20%">Ações</th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(data, index) in object.data" :key="index">
          <td data-label="Numero">{{ data.ref }}</td>
          <td data-label="Consultora" v-if="data.nameClient">
              {{ data.nameClient }}
              <br>
              <!-- <img :src="data.user_photo" alt="Foto consultora"> -->
              <img src="../../assets/avatar.png" class="user-photo" alt="Foto consultora">
            </td>
            <td data-label="Consultora" v-else>
              N/I <br>
              <!-- <img :src="data.user_photo" alt="Foto consultora"> -->
              <img src="../../assets/avatar.png" class="user-photo" alt="Foto consultora">
              {{data.nameClient}}
            </td>
          <td data-label="Status">{{ statusString(data.status) }}</td>
          <td data-label="Ações">
            <button-edit :id="data.id" route="nf-generate-edit"/>
          </td>
        </tr>
      </tbody>
    </table>

    <button-pagination :pagination="object" :offset="3" @paginate="loadItems"/>
  </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import ColComponent from "@/components/row/ColComponent";
import InputComponent from "@/components/form/InputComponent";
import MultiSelect from "@/components/form/MultiSelect";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import ButtonEdit from "@/components/buttons/ButtonEdit";
import ButtonPagination from "@/components/buttons/ButtonPagination";
import {useToast} from "vue-toastification";


const toast = useToast()
export default {
  name: "GenerateNFSearch",

  components: {
    ButtonPagination,
    ButtonEdit,
    ButtonRoute,
    ButtonSubmit,
    MultiSelect,
    InputComponent,
    ColComponent,
    RowComponent,
    PainelComponent
  },

  created() {
    this.$store.dispatch("changeNamePage", "Gerar NF")
    this.checkACL()
    this.loadItems(1)
    this.loadClients()

  },

  data() {
    return {
      itemsOne: {
        title: "Pesquisar",
        icon: "list alternate"
      },

      itemsTwo: {
        route: "/nf-generate/create",
        icon: "plus",
        name: "Adicionar"
      },

      buttonCheck: {
        create: false,
        edit: false
      },

      formData: {
        ref: "",
        client_id: "",
        status: ""
      },

      viewPage: false,
      searchSubmit: false,

      clients: [],
    }
  },

  methods: {
    submit() {
      this.loadItems(1)
      this.searchSubmit = true
    },

    loadItems(page) {
      if (!this.searchSubmit) {
        this.$store.dispatch('loadNfGenerates', {...this.params, page})
            .then((r) => {
              if (this.searchSubmit) {
                this.searchSubmit = false;
                if (r.data.length === 0) {
                  toast.info("Nada foi encontrado com os parâmetros informados!", {timeout: 2000});
                }
              }
            }).catch(() => {
          this.searchSubmit = false;
        });
      }
    },

    async checkACL() {
      this.$store.dispatch('ACLItems', 18)
          .then((r) => {
            
            if (r.indexOf('nf-generate') !== -1) {
              this.viewPage = true

              if (r.indexOf('nf-generate-search') !== -1) {
                this.viewPage = true
              }

              if (r.indexOf('nf-generate-create') !== -1) {
                this.buttonCheck.create = true
              }

              if (r.indexOf('nf-generate-edit') !== -1) {
                this.buttonCheck.edit = true
              }
            } else {
              this.$router.push({name: 'home'})
            }
          }).catch(() => {
        this.$router.push({name: 'home'})
      })
    },

    statusString(value) {
      if (parseInt(value) === 1) return "Em Cadastro"
      if (parseInt(value) === 2) return "Emitida"
      return "Cancelada"
    },

    loadClients() {
      this.$store.dispatch('loadClientList')
        .then((r) => {

          this.clients = r.map(client => ({
            label: client.name,
            value: client.value,
            icon: client.icon,
          }));
        }).catch((error) => {
          console.error('Erro ao carregar clientes:', error);
        });
    },
  },

  computed: {
    selectStatusGenerateNF() {
      return this.$store.state.var.selectStatusGenerateNF
    },

    object() {
      return this.$store.state.nf_generate.items
    },

    params() {
      return {
        ref: this.formData.ref,
        client_id: this.formData.client_id,
        status: this.formData.status,
      }
    }
  }
}
</script>