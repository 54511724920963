import axios from "axios";
import {NAME_TOKEN} from "@/configs/constants"
import {URL_BASE} from "@/configs/constants";

const RESOURCE = "auth";
export default {

    login(context, params) {
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE}${RESOURCE}`, params)
                .then(response => {
                    context.commit("AUTH_USER_OK", response.data.user)
                    const token = response.data.user.token
                    localStorage.setItem(NAME_TOKEN, token)
                    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
                    return resolve(response.data)
                }).catch(errors => {
                return reject(errors)
            })
        });
    },

    loginCheck(context) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem(NAME_TOKEN)
            const config = {headers: {Authorization: `Bearer ${token}`}};
            if (!token) {
                context.state.authenticate = false;
                return reject()
            }
            context.state.authenticate = true;
            axios.get(`${URL_BASE}${RESOURCE}/me`, config)
                .then(response => {
                    context.commit('AUTH_USER_OK', response.data.user)
                    resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            })
        })
    },

    logout(context) {
        const token = localStorage.getItem(NAME_TOKEN)
        context.commit('AUTH_USER_LOGOFF')
        if (token != null) {
            const configHeader = {headers: {Authorization: `Bearer ${token}`}};
            axios.delete(`${URL_BASE}${RESOURCE}/logout`, configHeader).then(() => localStorage.removeItem(NAME_TOKEN))
                .catch(() => localStorage.removeItem(NAME_TOKEN))
        } else localStorage.removeItem(NAME_TOKEN)
    },

    ACLItems(context, type) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}${RESOURCE}/permissions?type=${type}`,)
                .then(response => {
                    resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            })
        })
    },

    ACLItemsNav() {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}${RESOURCE}/permissionsnav`,)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            })
        })
    },

    // UpdateLinkPhoto(context, photo) {
    //     context.commit('UPDATE_LINK_PHOTO', photo)
    // },

}