<template>
  <painel-component :items="itemsOne">
    <form >
      <row-component>
        <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
          <label>Categoria:</label>
          <MultiSelect v-model="formData.category_id" :options="categories" placeholder="Categoria"/>
        </col-component>

        <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
            <label>Subcategoria:</label>
            <multi-select placeholder="Subcategoria"
              :options="subCategories"
              v-model="formData.subcategory_id"
              />
        </col-component>

        <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
            <label>Status:</label>
            <multi-select placeholder="Status" v-model="formData.status" :options="selectActiveInative" />
        </col-component>
        
        <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
            <label>Excel/PDF:</label>
            <multi-select placeholder="Excel ou PDF" v-model="formData.excel_pdf" :options="selectExcelOrPdf" />
        </col-component>

    </row-component>

    <row-component>
      <col-component class-col="col-xxl-3 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
        <button-submit class="me-1" @click="submit" name="Gerar"/>
      </col-component>
    </row-component>
    </form>
  </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from '@/components/row/RowComponent.vue';
import ColComponent from "@/components/row/ColComponent.vue";
import MultiSelect from "@/components/form/MultiSelect";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";

export default {
  name: "ProductReport",
  components: {
    PainelComponent,
    RowComponent,
    ColComponent,
    MultiSelect,
    ButtonSubmit,
  },
  created() {
    this.$store.dispatch("changeNamePage", "Relatório Produto");
    this.checkACL();
    this.loadcategoria();
    this.loadSubCategoria();
  },
  data() {
    // Data atual
    const currentDate = new Date();

    return {
      itemsOne: {
        title: "Relatório",
        icon: "list alternate"
      },
      buttonCheck: {
        create: false,
        edit: false
      },
      formData: {
        type: "",
        month: currentDate.getMonth() + 1, //Retorna o mês atual
        year: currentDate.getFullYear(), //Retorna o anual atual
        excel_pdf: 2,
      },

      categories: [],
      subCategories: [],
      viewPage: false,
      baseUrl: '',

    }
  },
  methods: {
    submit() {
      this.$store.dispatch('loadReportProducts', {...this.params})
          .then((r) => {
             window.open(r.link, '_blank');
          }).catch(() => {
      });

    },

    async checkACL() {
      this.$store.dispatch('ACLItems', 19)
        .then((r) => {
          if (r.indexOf('report-all') !== -1) {
            this.viewPage = true;
            if (r.indexOf('report-all-search') !== -1) {
              this.viewPage = true;
            }
          } else {
            this.$router.push({name: 'home'});
          }
        }).catch(() => {
          this.$router.push({name: 'home'});
        });
    },

    statusString(value) {
      return parseInt(value) === 1 ? "Ativo" : "Inativo";
    },


    loadcategoria(page) {
        this.$store.dispatch('loadCategories', {...this.params, page})
          .then((r) => {
            this.categories = r.data.map(category => ({
              label: category.name,
              value: category.id
            }));
          }).catch(() => {
            this.searchSubmit = false;
          });
    },

    loadSubCategoria(page) {
        this.$store.dispatch('loadSubcategories', {...this.params, page})
          .then((r) => {
            this.subCategories = r.data.map(subCategory => ({
              label: subCategory.name,
              value: subCategory.id
            }));
          }).catch(() => {
          });
    },


    getYearSelect() {
      let dataActual = new Date();
      let year = dataActual.getFullYear() + 2;
      let years = [];
      for (let i = 2024; i <= year; i++) {
        years.push(i);
      }
      return years
    },
  },

  computed: {
    selectActiveInative() {
      return this.$store.state.var.selectActiveInative;
    },

    selectExcelOrPdf() {
      return this.$store.state.var.selectExcelOrPdf;
    },

    selectTypeDoc(){
      return this.$store.state.var.selectTypeDoc;
    },

    selectMonthList(){
      return this.$store.state.var.selectMonthList;
    },


    object() {
      return this.$store.state.report_products.items;
    },
    params() {
      return {
        category_id: this.formData.category_id,
        status: this.formData.status,
        subcategory_id: this.formData.subcategory_id,
        excel_pdf: this.formData.excel_pdf,
      };
    }
  }
};
</script>
