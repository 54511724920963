import AdminComponent from "@/components/dashboard/AdminComponent";

import CommerceProductSearch from "@/views/search/CommerceProductSearch";
import CommerceProductShow from "@/views/show/CommerceProductShow";

export default {
    path: "/e-commerce-product",
    meta: {auth: true},
    component: AdminComponent,
    children: [
        {path: "search", name: "commerce-product-search", component: CommerceProductSearch},
        {path: "show/:id", name: "commerce-product-show", component: CommerceProductShow, props: true},
    ]
}