<template>

  <div class="modal-w d-grid position-fixed top-0 start-0 h-100 w-100" v-if="modal_account_sub">
    <div class="modal-add-sub-account" v-if="modal_account_sub">
      <div class="btn-close" @click="closeModal"></div>

      <error-component :errors="errorsModal" v-if="showErrorModal"/>

      <form>
        <row-component>
          <col-component
              :class-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
              :class="{'formError' : errorsModal.name}">
            <label>Nome:</label>
            <input-component :type-input="'text'" v-model="account_type_subs.name"/>
          </col-component>

          <col-component
              :class-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
              :class="{'formError' : errorsModal.status}">
            <label>Status</label>
            <MultiSelect v-model="account_type_subs.status" :options="selectActiveInative" :searchable="true"
                         :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
          </col-component>
        </row-component>

        <button-submit icon="plus" :name="'Adicionar'" @click="addSubType" v-if="editOrUpdate==1"></button-submit>
        <button-submit icon="edit" :name="'Salvar'" @click="submitAccountSubEdit" v-if="editOrUpdate==2"></button-submit>
      </form>
    </div>
  </div>


  <painel-component :items="itemsOne">
    <error-component :errors="errors" v-if="showError"/>
    <div class="tabs effect-3" v-if="viewPage">

      <input checked type="radio" id="tab-1" name="tab-effect-3">
      <span>
                <i class="ti ti-info-square-rounded"></i>
                <p>Informações</p>
            </span>

      <input type="radio" id="tab-2" name="tab-effect-3">
      <span>
                <i class="ti ti-cash-banknote"></i>
                <p>Sub Tipo de Conta</p>
            </span>

      <div class="line ease"></div>
      <div class="tab-content">

        <section id="tab-item-1">

          <div class="clearfix mb-3" v-if="create">
            <button-route class="float-end" :items="itemsThree"/>
          </div>
          <row-component>
            <col-component :class-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                           :class="{'formError' : errors.name}">
              <label>Nome:</label>
              <input-component :type-input="'text'" v-model="formData.name"/>
            </col-component>

            <col-component :class-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                           :class="{'formError' : errors.status}">
              <label>Status:</label>
              <MultiSelect v-model="formData.status" :options="selectActiveInative" :searchable="true"
                           :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
            </col-component>
          </row-component>

          <row-component>
            <col-component :class-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                           :class="{'formError' : errors.obs}">
              <label>Observações:</label>
              <text-area :rows="4" v-model="formData.obs"/>
            </col-component>
          </row-component>

          <row-component>
            <col-component class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <button-submit class="w-100" :disabled="sendForm" @click="submit" icon="save" name="Salvar"/>
            </col-component>

            <col-component class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <button-route class="w-100" :items="itemsTwo"/>
            </col-component>
          </row-component>
        </section>
        <section id="tab-item-2">
          <div class="alert alert-secondary alert-dismissible fade show"
               v-if="formData.account_type_subs.length === 0">
            Não há nada cadastrado
          </div>

          <div class="clearfix">
            <button-submit :icon="'plus'" @click="openModalCreate"></button-submit>
          </div>

          <form v-for="(data, index) in formData.account_type_subs" :key="index"
                class="card p-3 bg-light my-3">
            <row-component>
              <col-component
                  :class-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                <label>Nome:</label>
                <input-component disabled :type-input="'text'" v-model="data.name"/>
              </col-component>

              <col-component
                  :class-col="'col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12'">
                <label>Status</label>
                <input-component disabled :type-input="'text'" :model-value="'Ativo'" v-if="data.status ==1"/>
                <input-component disabled :type-input="'text'" :model-value="'Inativo'" v-else/>
              </col-component>

              <col-component
                  :class-col="'col-xxl-1 col-xl-1 col-lg-1 col-md-12 col-sm-12 col-12 mt-md-4'">
                <div class="btn-group" role="group" aria-label="Basic example">
                  <button-submit  icon="edit" @click="openModalUpdate(data)" />
                  <button-submit  icon="trash" @click="destroyAccountSub(data)"/>
                </div>
              </col-component>
            </row-component>
          </form>

        </section>


      </div>


    </div>


  </painel-component>

</template>

<style scoped>
.tabs > input,
.tabs > span,
.effect-3 .line {
  width: 50%;
}

#tab-2,
#tab-2 + span,
#tab-2:checked ~ .line {
  left: 50%;
}


.modal-w {
  z-index: 99;
  place-items: center;
  background-color: rgba(0, 0, 0, 0.7);
}

.modal-p {
  width: 80%;
  overflow-y: auto;
  min-height: 800px;
  background-color: #FFFFFF;
}

.modal-p .btn-close {
  top: 10px;
  right: 10px;
}

.btn-group {
  height: 46px;
}

.modal-add-sub-account .btn-close {
  top: 0;
  right: 0;
  margin: 1%;
  cursor: pointer;
  position: absolute;
}

.modal-add-sub-account {
  top: 50%;
  left: 50%;
  width: 40%;
  z-index: 9999;
  position: fixed;
  min-height: 20%;
  margin-right: -50%;
  padding: 40px 20px;
  overflow-y: auto;
  background: #FFFFFF;
  transform: translate(-50%, -50%);
}

@media (max-width: 991px) {
  .modal-add-sub-account {
    width: 90%;
  }
}
</style>

<script>


import PainelComponent from "@/components/dashboard/PainelComponent.vue";
import RowComponent from "@/components/row/RowComponent.vue";
import ErrorComponent from "@/components/alerts/ErrorComponent.vue";
import ColComponent from "@/components/row/ColComponent.vue";
import InputComponent from "@/components/form/InputComponent.vue";
import TextArea from "@/components/form/TextArea.vue";
import ButtonSubmit from "@/components/buttons/ButtonSubmit.vue";
import ButtonRoute from "@/components/buttons/ButtonRoute.vue";
import MultiSelect from "@/components/form/MultiSelect.vue";
import swal from "sweetalert";
import {useToast} from "vue-toastification";
const toast = useToast()
export default {
  name: "AccountTypeUpdate",
  components: {
    MultiSelect,
    ButtonRoute,
    ButtonSubmit,
    TextArea,
    InputComponent,
    ColComponent,
    ErrorComponent,
    RowComponent,
    PainelComponent
  },


  props: {
    id: {
      required: true
    }
  },


  created() {
    this.$store.dispatch('changeNamePage', 'Tipo de Conta')
    this.loadAccountType()
    this.checkACL()
  },


  data() {
    return {
      itemsOne: {
        title: "Editar",
        icon: "edit"
      },

      itemsTwo: {
        route: "/account-type/search",
        icon: "reply all",
        name: "Voltar"
      },

      itemsThree: {
        route: "/account-type/create",
        icon: "plus",
      },


      formData: {
        name: '',
        status: '',
        obs: '',
        account_type_subs: []
      },

      account_type_subs: {
        account_type_id: this.id,
        name: '',
        status: ''
      },

      edit: false,
      modal_account_sub: false,


      errors: [],
      errorsModal: [],
      viewPage: false,
      sendForm: false,
      showError: false,
      showErrorModal: false,
      create: false,
      editOrUpdate:0,
    }
  },

  methods: {

    loadAccountType() {
      this.$store.dispatch('loadAccountType', this.id)
          .then((r) => {
            this.formData = r
          }).catch(() => {
        toast.error('Erro desconhecido', {timeout: 2000})
      })
    },

    submit() {
      if (!this.sendForm) {
        this.sendForm = true;
        this.$store.dispatch('updateAccountType', this.formData)
            .then(() => {
              this.showError = false
              this.sendForm = false
              this.errors = []
              toast.success("Atualização efetuado com sucesso", {timeout: 2000});
              this.loadAccountType(1)
            }).catch((r) => {
          this.showError = true;
          this.sendForm = false
          this.errors = r.response.data.errors;
          this.scrollToTop()
          toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
        })
      }
    },

    addSubType() {
      if (!this.sendForm) {
        this.sendForm = true;
        this.$store.dispatch('storeAccountTypeSub', this.account_type_subs)
            .then(() => {
              this.modal_account_sub = false
              this.showErrorModal = false
              this.sendForm = false
              this.errorsModal = []
              this.loadAccountType()
              this.editOrUpdate=0
              this.resetFormModal()
              toast.success("Sub conta adicionada com sucesso", {timeout: 2000});
            }).catch((r) => {
          this.showErrorModal = true;
          this.errorsModal = r.response.data.errors;
          this.sendForm = false
          toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
        })
      }


    },


    openModalCreate(){
      this.editOrUpdate = 1
      this.modal_account_sub=true

    },

    openModalUpdate(data){
      this.editOrUpdate = 2
      this.modal_account_sub=true
      this.account_type_subs.name=data.name
      this.account_type_subs.status=data.status
      this.account_type_subs.id = data.id

    },


    closeModal() {
      this.modal_account_sub = false
      this.showErrorModal = false;
      this.errorsModal = [];
      this.resetFormModal()
    },

    resetFormModal(){
      this.account_type_subs.name=''
      this.account_type_subs.status=''
    },

    scrollToTop() {
      window.scrollTo(0, 0);
    },

    destroyAccountSub(item) {
      swal({
        title: 'Atenção!',
        text: `Deseja realmente deletar ${item.name}, ${this.me.name} ?`,
        closeOnClickOutside: false,
        buttons: {
          cancel: {
            text: 'Não',
            visible: true,
            closeModal: true,

          },
          confirm: "Sim"
        }
      }).then((result) => {
        if (result) {
          this.$store.dispatch('destroyAccountTypeSub', item.id).then(() => {
            this.loadAccountType(1)
            toast.success('Cadastro deletado com sucesso', {timeout: 2000});
          })
              .catch(() => {
                toast.error(`Não foi possivel deletar  ${item.name} `, {timeout: 2000});
              })
        }
      });
    },



    submitAccountSubEdit() {
      if (!this.sendForm) {
        this.sendForm = true;
         console.log( this.account_type_subs)
        this.$store.dispatch('updateAccountTypeSub', this.account_type_subs)
            .then(() => {
              this.modal_account_sub = false
              this.showErrorModal = false
              this.sendForm = false
              this.errorsModal = []
              this.loadAccountType()
              this.resetFormModal()
              toast.success("Sub conta atualizada com sucesso", {timeout: 2000});
            }).catch((r) => {
          this.showErrorModal = true;
          this.sendForm = false
          this.errorsModal = r.response.data.errors;
          toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
        })
      }
    },


    async checkACL() {
      this.$store.dispatch('ACLItems', 10)
          .then((r) => {
            if (r.indexOf('account-type-edit') !== -1) {
              this.viewPage = true
              if (r.indexOf('account-type-create') !== -1) {
                this.create = true
              }
            } else {
              this.$router.push({name: 'home'})
            }
          }).catch(() => {
        this.$router.push({name: 'home'})
      })
    },

  },

  computed: {

    me() {
      return this.$store.state.auth.me
    },

    selectActiveInative() {
      return this.$store.state.var.selectActiveInative
    },

    noResultsText() {
      return this.$store.state.var.noResultsText
    },

    noOptionsText() {
      return this.$store.state.var.noOptionsText
    }
  }

}


</script>