<template>
    <button class="btn btn-danger rounded-0" type="button">
        <i class="m-0 trash icon"></i>
    </button>
</template>

<script>
export default {
    name: 'ButtonDelete',
}
</script>