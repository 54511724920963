<template>
    <Multiselect 
      :noResultsText="noResultsText" 
      :noOptionsText="noOptionsText" 
      :searchable="true" 
      :options="options"
      v-model="internalValue" 
    />
  </template>
  
  <script>
  import Multiselect from "@vueform/multiselect";
  
  export default {
    name: "MultiSelect",
    components: {
      Multiselect
    },
    props: {
      options: {
        required: true
      },
      modelValue: {
        type: [String, Number, Array],
        default: null
      }
    },
    computed: {
      noResultsText() {
        return this.$store.state.var.noResultsText;
      },
      noOptionsText() {
        return this.$store.state.var.noOptionsText;
      },
      internalValue: {
        get() {
          return this.modelValue;
        },
        set(value) {
          this.$emit('update:modelValue', value);
        }
      }
    }
  }
  </script>
  