import AdminComponent from "@/components/dashboard/AdminComponent";

import BoxTotalEntrance from "@/views/report/BoxTotalEntrance.vue";

export default {
    path: "/box-total-entrance",
    meta:{auth: true},
    component: AdminComponent,
    children: [
        {path: "search", name: "box-total-entrance-search", component: BoxTotalEntrance},
    ]
}