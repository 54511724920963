import axios from "axios";
import {URL_BASE} from "@/configs/constants";

// Adicionar rota
const RESOURCE = 'home';

export default {
    loadHome(context, year) {
        context.commit('HOME_GENERATE', true)
        return new Promise((resolve, reject) => {
        axios.get(`${URL_BASE}${RESOURCE}?year=${year}`)
            .then(response => {
                context.commit('HOME_GENERATE', response.data)
                resolve(response.data)
            })
            .catch((e) => reject(e))
        })
    },
    

}