import AdminComponent from "@/components/dashboard/AdminComponent";

import ClientsPhones from "@/views/report/ClientsPhones.vue";

export default {
    path: "/clients-phones",
    meta:{auth: true},
    component: AdminComponent,
    children: [
        {path: "search", name: "clients-phones-search", component: ClientsPhones},
    ]
}