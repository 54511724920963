import AdminComponent from '@/components/dashboard/AdminComponent'
import FinanceEntranceEffective from "@/views/report/FinanceEntranceEffective";

export default {
    path: '/financial-entrance-effective',
    component: AdminComponent,
    meta: {auth: true},
    children: [
        {path: 'search', name: 'financial-entrance-effective-search', component: FinanceEntranceEffective},
    ]
}