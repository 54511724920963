<template>
    <body>
    <div class="page">
        <div style="text-align: center">
            <h1>Resumo do pedido {{ formData.id }}</h1>
            <h3>Pedido realizado em {{ formatDate(formData.data) }}</h3>
        </div>

        <div class="dados">
            <div>
                <h3>Dados de contato</h3>
                <ul>
                    <li><strong>Nome:</strong> {{ formData.nome_cliente }}</li>
                    <li><strong>CPF/CNPJ:</strong> {{ formatRecords(formData.cnpj_cliente) }}</li>
                    <li><strong>Telefone:</strong> {{ formatPhone(formData.fone) }}</li>
                    <li><strong>E-mail:</strong> {{ formData.email }}</li>
                </ul>
            </div>

            <div>
                <h3>Dados do endereço</h3>
                <ul>
                    <li><strong>Destinatário:</strong> {{ formData.end_nome_destinatario }}</li>
                    <li><strong>Logradouro:</strong> {{ formData.end_endereco }}, 151</li>
                    <li><strong>Bairro:</strong> {{ formData.end_bairro }}</li>
                    <li><strong>Cidade/UF:</strong> {{ formData.end_cidade }}/{{ formData.end_estado }}</li>
                    <li><strong>CEP:</strong> {{ formData.end_cep }}</li>
                </ul>
            </div>
        </div>

        <hr>

        <table>
            <thead>
            <tr>
                <th scope="col" style="width: 1%; text-align: center">#</th>
                <th scope="col" style="width: 10%; text-align: center">Ref.</th>
                <th scope="col" style="width: 20%; text-align: center">Descrição</th>
                <th scope="col" style="width: 5%; text-align: center">Qtde.</th>
                <th scope="col" style="width: 10%; text-align: center">Unitário</th>
                <th scope="col" style="width: 10%; text-align: center">Total</th>
            </tr>
            </thead>

            <tbody>
            <tr v-for="(data, index) in items" :key="index">
                <td style="text-align: center">{{ data.id }}</td>
                <td style="text-align: center">{{ data.referencia }}</td>
                <td style="text-align: center">{{ data.descricao_simples }}</td>
                <td style="text-align: center">{{ data.qtde }}</td>
                <td style="text-align: center">R$ {{ formatMoney(data.vlr_uni) }}</td>
                <td style="text-align: center">R$ {{ formatMoney(data.vlr_total) }}</td>
            </tr>
            </tbody>
        </table>
        <hr>
        <table>
            <thead>
            <tr>
                <th scope="col" style="width: 20%; text-align: left;">Forma de Envio</th>
                <th scope="col" style="width: 20%; text-align: left;">Frete</th>
                <th scope="col" style="width: 20%; text-align: left;">Impostos</th>
                <th scope="col" style="width: 20%; text-align: left;">Descontos.</th>
                <th scope="col" style="width: 20%; text-align: left;">Peso total (Kg)</th>
                <th scope="col" style="width: 30%; text-align: left;">Subtotal</th>
            </tr>
            </thead>

            <tbody>
            <tr>
                <td>{{ formData.frete_tipo }}</td>
                <td>R$ {{ formatMoney(formData.frete) }}</td>
                <td>R$ {{ formatMoney(formData.imposto) }}</td>
                <td>R$ {{ formatMoney(formData.vlr_total_descontos) }}</td>
                <td>{{ formData.peso_total_pedido }}</td>
                <td>R$ {{ formatMoney(formData.vlr_total_itens) }}</td>
            </tr>
            </tbody>
        </table>
        <hr>
        <table style="width: 100%">
            <thead>
            <tr>
                <th scope="col" style="width: 25%; text-align: left;">Cupom</th>
                <th scope="col" style="width: 25%; text-align: left;">Desconto</th>
                <th scope="col" style="width: 25%; text-align: left;">Qtd. Itens</th>
                <th scope="col" style="width: 25%; text-align: left;">Total</th>
            </tr>
            </thead>

            <tbody>
            <tr>
                <td>{{ formData.cupom !== null ? formData.cupom : "N/D" }}</td>
                <td>R$ {{ formatMoney(formData.vlr_desconto_cupom) }}</td>
                <td>{{ formData.qtd_itens }}</td>
                <td>R$ {{ formatMoney(formData.vlr_total_pedido) }}</td>
            </tr>
            </tbody>
        </table>
        <hr>
        <table>
            <thead>
            <tr>
                <th scope="col" style="width: 40%; text-align: left;">Forma de pagamento</th>
                <th scope="col" style="width: 80%; text-align: left;">Vendedor</th>
                <th scope="col" style="width: 10%; text-align: left;">Status</th>
            </tr>
            </thead>

            <tbody>
            <tr>
                <td>{{ formData.forma_pgto }}</td>
                <td>{{ formData.id_vendedor !== null ? formData.id_vendedor : "N/D" }}</td>
                <td>{{ formData.status.descricao }}</td>
            </tr>
            </tbody>
        </table>
        <hr>
    </div>
    </body>
</template>

<script>
export default {
    props: {
        id: {
            required: true
        }
    },

    data() {
        return {
            formData: {
                id: this.id,
                nome_cliente: "",
                cnpj_cliente: "",
                data: "",
                email: "",
                fone: "",
                end_bairro: "",
                end_cep: "",
                end_cidade: "",
                end_endereco: "",
                end_estado: "",
                end_numero: "",
                end_nome_destinatario: "",
                frete_tipo: "",
                frete: "",
                forma_pgto: "",
                peso_total_pedido: "",
                qtd_itens: "",
                vlr_total_itens: "",
                vlr_total_pedido: "",
                vlr_total_descontos: "",
                vlr_desconto_cupom: "",
                cupom: "",
                id_vale_compra: "",
                imposto: "",
                id_vendedor: "",
                previsao_entrega: "",
                status: []
            },

            items: [],
        }
    },

    created() {
        this.loadItems()
    },

    methods: {
        loadItems() {
            this.$store.dispatch('loadCommerceSale', this.id)
                .then((r) => {
                    this.formData = r.sales.original.registros
                    this.items = r.itens.original.registros
                    this.formData.email = r.client.original.registros.email
                    this.formData.fone = r.client.original.registros.fone
                    setTimeout(() => {
                        window.print()
                        window.close()
                    }, 1000)
                })
        },

        formatDate(value) {
            let date = value.split(" ")[0]
            date = date.split("-")
            return `${date[2]}/${date[1]}/${date[0]}`
        },

        formatMoney(value) {
            return value = value.toLocaleString('pt-br', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });
        },

        formatRecords(value) {
            value = value.replace(/\D/g, '');
            if (value.length === 11)
                /* eslint-disable-next-line */
                return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3-\$4");

            /* eslint-disable-next-line */
            return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3/\$4-\$5");
        },

        formatPhone(value) {
            return value
                .replace(/\D/g, '')
                .replace(/(?:(^\+\d{2})?)(?:([1-9]{2})|([0-9]{3})?)(\d{4,5})(\d{4})/,
                    (fullMatch, country, ddd, dddWithZero, prefixTel, suffixTel) => {
                        if (country) return `${ country } (${ ddd || dddWithZero }) ${ prefixTel }-${ suffixTel}`;
                        if (ddd || dddWithZero) return `(${ ddd || dddWithZero }) ${ prefixTel }-${ suffixTel}`;
                        if (prefixTel && suffixTel) return `${ prefixTel }-${ suffixTel }`;
                        return value;
                    });
        }
    }
}
</script>

<style scoped>
ul {
    padding: 0;
    list-style: none;
}

.dados {
    display: flex;
    justify-content: space-between;
}

h1, h3 {
    margin: 0.6rem 0;
}

body {
    margin: 0;
    padding: 0;
    width: 100vw;
    height: 100vh;
    font-size: 12pt;
    overflow-y: auto !important;
    background-color: transparent;
    -webkit-print-color-adjust: exact;
    font-family: "Roboto", -apple-system, "San Francisco", "Segoe UI", "Helvetica Neue", sans-serif;
}

.page {
    outline: 0;
    margin: 1cm auto;
    background: #fff;
    box-shadow: 0 4px 5px rgba(75, 75, 75, 0.2);
}

div.page-break {
    page-break-after: always;
}

@media screen {
    div.page-break::before {
        content: "";
        height: 0.8cm;
        display: block;
        margin-left: 0.5cm;
        margin-right: 0.5cm;
        background-color: #fff;
        box-shadow: 0 6px 5px rgba(75, 75, 75, 0.2);
    }

    div.page-break {
        height: 1.8cm;
        display: block;
        background: #eee;
        margin: 1.2cm -2.5cm 2cm -2.5cm;
    }
}

h1 {
    page-break-before: always;
}

h1, h2, h3, h4, h5, h6 {
    page-break-after: avoid;
}

p {
    margin: 0;
}

p + p {
    margin-top: 0.5cm;
}

a {
    color: black;
    text-decoration: none;
}

td {
    font-size: 15px;
}

table {
    page-break-inside: avoid;
}

@page {
    widows: 2;
    orphans: 4;
}

@media print {
    html,
    body {
        background-color: #fff;
    }

    .page {
        margin: 0 !important;
        padding: 0 !important;
        page-break-after: always;
        width: initial !important;
        border: initial !important;
        min-height: initial !important;
        background: initial !important;
        box-shadow: initial !important;
        border-radius: initial !important;
    }
}

.page {
    width: 21cm;
    padding: 0.5cm;
    min-height: 29.7cm;
}

@page {
    margin: 2cm;
    size: A4 portrait;
}
</style>