<template>
    <div class="btn-toolbar  d-grid gap-2 d-md-flex justify-content-md-end paddingPage">
        <div class="btn-group ">
            <button type="button" class="btn btn-outline-secondary" v-if="pagesList.current_page > 1"
                    @click.prevent="changePage(pagesList.current_page - 1)"><i class="angle double left icon"></i>
            </button>

            <button type="button" class="btn btn-outline-secondary"
                    v-for="(page, index) in pagesNumber"
                    :class="['page-item', {'active': page == pagesList.current_page}]"
                    :key="index"
                    @click.prevent="changePage(page)">
                {{ page }}
            </button>

            <button type="button" class="btn btn-outline-secondary" v-if="pagesList.current_page < pagesList.last_page"
                    @click.prevent="changePage(pagesList.current_page + 1)"><i class="angle double right icon"></i>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: "ButtonPagination",
    props: {
        pagination: {
            type: Object,
            required: true,
            "default": []
        },
        offset: {
            type: Number,
            default: 4
        }
    },
    computed: {
        pagesNumber() {
            // Verifica se tem itens para paginar, se não tiver retorna o Array vazio
            if (!this.pagination.to) {
                return [];
            }
            // Define a próxima página
            let from = this.pagination.current_page - this.offset;
            if (from < 1) {
                from = 1;
            }
            // Define a última opção páginação
            let to = from + this.offset;
            if (to >= this.pagination.last_page) {
                to = this.pagination.last_page;
            }
            // Cria as opções de paginação
            let pagesArray = [];
            for (let page = from; page <= to; page++) {
                pagesArray.push(page);
            }
            // Array montado com as opções de paginação (Número de opções de paginação === offset)
            return pagesArray;
        },

        pagesList() {
            return this.pagination
        }

    },

    methods: {
        changePage(page) {
            // Atualiza a pagina atual
            this.pagesList.current_page = page
            // Dispara o evento @paginate do Component Pai
            this.$emit('paginate', page);
        }
    }
}
</script>

<style scoped>
.btn-outline-secondary {
    border-radius: 0;
}

.paddingPage {
    margin: 30px 10px;
}

.btn:focus,
.btn:active {
    box-shadow: none;
}
</style>
