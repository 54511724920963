export default {
    icon: "list",
    name: "Cadastro",
    subLinks: [
        {
            name: "Cliente",
            route: "/client/search",
            pagePermissions: [
                "client-search",
                "client-create",
                "client-edit",
                "client-delete"
            ],
        },

        {
            name: "Fornecedor",
            route: "/supplier/search",
            pagePermissions: [
                "provider-search",
                "provider-create",
                "provider-edit"
            ],
        },

        {
            name: "Funcionário",
            route: "/employee/search",
            pagePermissions: [
                "employee-search",
                "employee-create",
                "employee-edit",
            ],
        },

        {
            name: "Grupo de Usuário",
            route: "/user-group/search",
            pagePermissions: [
                "group-user-search",
                "group-user-create",
                "group-user-edit",
                "group-user-delete"
            ],
        },

        {
            name: "Usuário",
            route: "/user/search",
            pagePermissions: [
                "user-search",
                "user-create",
                "user-edit",
                "user-delete"
            ],
        },

        {
            name: "Consignado",
            route: "/consigned/search",
            pagePermissions: [
                "consigned-search",
                "consigned-create",
                "consigned-edit",
                "consigned-delete"
            ],
        },
    ],

    pageNavCheck: [
        "client-search",
        "client-create",
        "client-edit",
        "client-delete",
        "provider-search",
        "provider-create",
        "provider-edit",
        "employee-search",
        "employee-create",
        "employee-edit",
        "group-user-search",
        "group-user-create",
        "group-user-edit",
        "group-user-delete",
        "user-search",
        "user-create",
        "user-edit",
        "user-delete",
        "consigned-search",
        "consigned-create",
        "consigned-edit",
        "consigned-delete"
    ]
}