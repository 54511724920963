<template>
  
  <painel-component :items="itemsOne">
    <div class="d-flex flex-column flex-md-row justify-content-between align-items-center">
      <div class="pt-3 text-uppercase">
        <h5 class="text-dark">N° {{ formData.code }} |
          <span class="badge rounded-pill text-bg-dark" v-if="formData.status==1">Aberto</span>
          <span class="badge rounded-pill text-bg-dark" v-if="formData.status==2">Em pagamento</span>
         <span class="badge rounded-pill text-bg-dark" v-if="formData.status==3">Cancelado</span>
          <span class="badge rounded-pill text-bg-dark" v-if="formData.status==4">Finalizado</span>
        </h5>
        <div class="d-flex flex-column">
          <small>Hora de abertura: {{ formData.openDateHour }}</small>
          <small>Hora de fechamento: {{ formData.closeDateHour }}</small>
        </div>
      </div>

      <div class="d-flex flex-column flex-md-row align-items-center m-3">
          <button-route class="mx-1" :items="itemsTwo"/>
          <button-submit :icon="'print'" @click="openPDF(formData.linkPrint)"/>
        </div>
    </div>

    <div class="mt-4 spacing-border"></div>

    <div class="card mt-4 rounded-0 text-uppercase">
    <div class="p-3 d-flex flex-column align-items-center align-items-md-start">
      <h5 class="text-uppercase">Consultora | Cliente </h5>
      <div class="d-flex align-items-center">
        <!-- <img class="profileImg" :src="formData.user_photo" alt="foto_de_perfil"> -->
        <img class="profileImg" src="../../assets/avatar.png" alt="foto_de_perfil">
        <div>
          <small v-if="formData.user_creater">{{ formData.user_creater }} </small>
          <small v-else>N/I</small>
          |
          <small v-if="formData.name_client">{{ formData.name_client }} </small>
          <small v-else>N/I</small>
        </div>
      </div>
    </div>
  </div>

  <div class="card mt-4 rounded-0">
      <h5 class="p-3 text-uppercase">Produtos</h5>
      <div class="table-responsive" style="max-height: 600px; overflow-y: auto;">
        <table class="table table-hover">
          <thead class="bg-dark text-white">
          <tr>
            <th scope="col">Nome do Produto</th>
            <th scope="col">Foto</th>
            <th scope="col">Qtde.</th>
            <th scope="col">Unitário</th>
            <th scope="col">Total</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in formData.itens" :key="item.id">
            <td>{{ item.name_product }}</td>
            <td>
              <img :src="item.photo_product" alt="" width="60" height="60">
            </td>
            <td>{{ item.amount }}</td>
            <td>R$ {{ item.price_un }}</td>
            <td>R$ {{ item.price_total }}</td>
          </tr>
          </tbody>
          <tfoot>
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th></th>

            <th scope="col" class="tableDark" v-for="total in formData.paymentsTotal" :key="total.id">R$
              {{ total.price_total }}
            </th>

          </tr>
          </tfoot>
        </table>
      </div>
  </div>

  <div class="card mt-4 rounded-0">
      <h5 class="p-3 text-uppercase">Pagamentos</h5>
      <div class="table-responsive" style="max-height: 600px; overflow-y: auto;">
        <table class="table table-hover">
          <thead class="bg-dark text-white">
          <tr>
            <th scope="col" colspan="3">Forma</th>
            <th scope="col" colspan="2">Preço</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="payment in formData.payments" :key="payment.id">
            <td v-if="payment.type == 1" colspan="3" >Dinheiro</td>
            <td v-if="payment.type == 2" colspan="3">PIX</td>
            <td v-if="payment.type == 3" colspan="3">Cartão de Crédito</td>
            <td v-if="payment.type == 4" colspan="3">Cartão de Débito</td>
            <td colspan="2">R$ {{ payment.price }}</td>
          </tr>

          </tbody>
          <tfoot>
          <tr>
            <th scope="col" class="tableDark">
              Valor total pedido
            </th>
            <th scope="col" class="tableDark"  v-for="total in formData.paymentsTotal" :key="total.id">
              Valor total com desconto de {{total.discount}}%
            </th>
            <th scope="col" class="tableDark">
              Valor pago
            </th>
            <th scope="col" class="tableDark">
              Troco
            </th>
          </tr>
           <tr>
             <th v-for="total in formData.paymentsTotal" :key="total.id"> R$ {{ total.price_total }}</th>
             <th v-for="total in formData.paymentsTotal" :key="total.id"> R$ {{ total.price_total_discount }}</th>
             <th v-for="total in formData.paymentsTotal" :key="total.id"> R$ {{ total.price_paid_out }}</th>
             <th v-for="total in formData.paymentsTotal" :key="total.id"> R$ {{ total.price_change	 }}</th>
           </tr>
          </tfoot>
        </table>
      </div>
    </div>

  </painel-component>


</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import ButtonSubmit from "@/components/buttons/ButtonSubmit.vue";
import ButtonRoute from "@/components/buttons/ButtonRoute";
export default {
  name: "SalesHistoricShow",

  components: {
    PainelComponent,
    ButtonSubmit,
    ButtonRoute,
  },

  props: {
    id: {
      required: true
    }
  },

  created() {
    this.$store.dispatch("changeNamePage", "Pedido Caixa")
    this.checkACL()
    this.loadItem()
  },

  data() {
    return {
      itemsOne: {
        title: "Caixa",
        icon: "list alternate"
      },

      itemsTwo: {
        route: "/pdv-sales-historic/search",
        icon: "reply all",
        name: "",
      },

      formData: {},

      viewPage: false,

      clients: [],
    }
  },

  methods: {
    loadItem() {
      this.$store.dispatch('loadSalesHistoric', this.id)
        .then((r) => {
          this.formData = r;
          this.formData.id = this.id;
        }).catch(() => {
        });
    },
    
    // Permissions
    async checkACL() {
      this.$store.dispatch('ACLItems', 17)
          .then((r) => {
            if (r.indexOf('pdv-box-historic') !== -1) {
              this.viewPage = true

              if (r.indexOf('pdv-box-historic-search') !== -1) {
                this.viewPage = true
              }
              if (r.indexOf('pdv-box-historic-show') !== -1) {
                this.buttonCheck.create = true
              }

            } else {
              this.$router.push({name: 'home'})
            }
          }).catch(() => {
        this.$router.push({name: 'home'})
      })
    },

    openPDF(link) {
        if (link) {
          window.open(link, '_blank');
        } else {
          console.error('Link do PDF não disponível');
        }
      }

  },

  computed: {
    selectActiveInative() {
      return this.$store.state.var.selectActiveInative
    },

    object() {
      return this.$store.state.box_historic.items
    },
    

    params() {
      return {
        code: this.formData.code,
        creator_user_id: this.formData.creator_user_id,
        status: this.formData.status
      }
    }
  }
}
</script>

<style scoped>

.main-card {
  margin: 5px 0 0 5px;
  max-height: 90vh; /* ou qualquer valor que faça sentido para o seu layout */
  overflow: auto;
}

.spacing-border {
  border: 1px solid #000;
}

.profileImg {
  width: 50px;
  margin: 15px;
  border-radius: 50%; 
}

table {
  font-size: 1.02rem;
}

table thead tr > th, .tableDark {
  background-color: #000;
  color: #fff;
  font-weight: 600;
  border: none;
}

tbody tr {
  border-color: #eeeeee;
}

tbody tr td,
tbody tr th {
  background-color: #fcfcfc;
  font-weight: 300;
}

.table {
  margin-bottom: 0;
}


</style>
