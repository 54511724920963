<template>
    <div class="mb-4" :class="classCol">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "ColComponent",

    props: {
        classCol: {
            required: true,
            type: String
        }
    }
}
</script>