import axios from "axios";
import {URL_BASE} from "@/configs/constants";

// Mudar rota
const RESOURCE = 'report/';

export default {
  loadReportProducts({ commit }, params) {
    commit('LOADER', true);
    return new Promise((resolve, reject) => {
      axios.get(`${URL_BASE}${RESOURCE}products`, { params })
        .then(response => {
          commit('LOAD_REPORT_PRODUCTS', response.data);
          resolve(response.data);
        })
        .catch(errors => {
          reject(errors);
        })
        .finally(() => {
          commit('LOADER', false);
        });
    });
  },

  loadReportProductsEntranceExit({ commit }, params) {
    commit('LOADER', true);
    return new Promise((resolve, reject) => {
      axios.get(`${URL_BASE}${RESOURCE}products-entrance-and-exit`, { params })
        .then(response => {
          commit('LOAD_REPORT_PRODUCTS', response.data);
          resolve(response.data);
        })
        .catch(errors => {
          reject(errors);
        })
        .finally(() => {
          commit('LOADER', false);
        });
    });
  },

  loadClientsEmail({ commit }, params) {
    commit('LOADER', true);
    return new Promise((resolve, reject) => {
      axios.get(`${URL_BASE}${RESOURCE}clients-emails`, { params })
        .then(response => {
          commit('LOAD_REPORT_PRODUCTS', response.data);
          resolve(response.data);
        })
        .catch(errors => {
          reject(errors);
        })
        .finally(() => {
          commit('LOADER', false);
        });
    });
  },

  loadClientsPhones({ commit }, params) {
    commit('LOADER', true);
    return new Promise((resolve, reject) => {
      axios.get(`${URL_BASE}${RESOURCE}clients-phones`, { params })
        .then(response => {
          commit('LOAD_REPORT_PRODUCTS', response.data);
          resolve(response.data);
        })
        .catch(errors => {
          reject(errors);
        })
        .finally(() => {
          commit('LOADER', false);
        });
    });
  },
  
  loadBoxTotalEntrance({ commit }, params) {
    commit('LOADER', true);
    return new Promise((resolve, reject) => {
      axios.get(`${URL_BASE}${RESOURCE}box-total-entrance`, { params })
        .then(response => {
          commit('LOAD_REPORT_PRODUCTS', response.data);
          resolve(response.data);
        })
        .catch(errors => {
          reject(errors);
        })
        .finally(() => {
          commit('LOADER', false);
        });
    });
  },

  loadBoxTotalExit({ commit }, params) {
    commit('LOADER', true);
    return new Promise((resolve, reject) => {
      axios.get(`${URL_BASE}${RESOURCE}box-total-exit`, { params })
        .then(response => {
          commit('LOAD_REPORT_PRODUCTS', response.data);
          resolve(response.data);
        })
        .catch(errors => {
          reject(errors);
        })
        .finally(() => {
          commit('LOADER', false);
        });
    });
  },

  loadBoxTotalMoviment({ commit }, params) {
    commit('LOADER', true);
    return new Promise((resolve, reject) => {
      axios.get(`${URL_BASE}${RESOURCE}box-total-movement`, { params })
        .then(response => {
          commit('LOAD_REPORT_PRODUCTS', response.data);
          resolve(response.data);
        })
        .catch(errors => {
          reject(errors);
        })
        .finally(() => {
          commit('LOADER', false);
        });
    });
  },

  loadFinanceEntrancePreview({ commit }, params) {
    commit('LOADER', true);
    return new Promise((resolve, reject) => {
      axios.get(`${URL_BASE}${RESOURCE}financial-entrance-preview`, { params })
        .then(response => {
          commit('LOAD_REPORT_PRODUCTS', response.data);
          resolve(response.data);
        })
        .catch(errors => {
          reject(errors);
        })
        .finally(() => {
          commit('LOADER', false);
        });
    });
  },

  loadFinanceExitPreview({ commit }, params) {
    commit('LOADER', true);
    return new Promise((resolve, reject) => {
      axios.get(`${URL_BASE}${RESOURCE}financial-exit-preview`, { params })
        .then(response => {
          commit('LOAD_REPORT_PRODUCTS', response.data);
          resolve(response.data);
        })
        .catch(errors => {
          reject(errors);
        })
        .finally(() => {
          commit('LOADER', false);
        });
    });
  },

  loadFinanceEntranceEffective({ commit }, params) {
    commit('LOADER', true);
    return new Promise((resolve, reject) => {
      axios.get(`${URL_BASE}${RESOURCE}financial-entrance-effective`, { params })
        .then(response => {
          commit('LOAD_REPORT_PRODUCTS', response.data);
          resolve(response.data);
        })
        .catch(errors => {
          reject(errors);
        })
        .finally(() => {
          commit('LOADER', false);
        });
    });
  },

  loadFinanceExitEffective({ commit }, params) {
    commit('LOADER', true);
    return new Promise((resolve, reject) => {
      axios.get(`${URL_BASE}${RESOURCE}financial-exit-effective`, { params })
        .then(response => {
          commit('LOAD_REPORT_PRODUCTS', response.data);
          resolve(response.data);
        })
        .catch(errors => {
          reject(errors);
        })
        .finally(() => {
          commit('LOADER', false);
        });
    });
  },

  loadAccountBankReport({ commit }, params) {
    commit('LOADER', true);
    return new Promise((resolve, reject) => {
      axios.get(`${URL_BASE}${RESOURCE}account-banck`, { params })
        .then(response => {
          commit('LOAD_REPORT_PRODUCTS', response.data);
          resolve(response.data);
        })
        .catch(errors => {
          reject(errors);
        })
        .finally(() => {
          commit('LOADER', false);
        });
    });
  },

  loadProductsPhoto({ commit }, params) {
    commit('LOADER', true);
    return new Promise((resolve, reject) => {
      axios.get(`${URL_BASE}${RESOURCE}products-photo`, { params })
        .then(response => {
          commit('LOAD_REPORT_PRODUCTS', response.data);
          resolve(response.data);
        })
        .catch(errors => {
          reject(errors);
        })
        .finally(() => {
          commit('LOADER', false);
        });
    });
  },

  loadReportSales({ commit }, params) {
    commit('LOADER', true);
    return new Promise((resolve, reject) => {
      axios.get(`${URL_BASE}${RESOURCE}sales`, { params })
        .then(response => {
          commit('LOAD_REPORT_PRODUCTS', response.data);
          resolve(response.data);
        })
        .catch(errors => {
          reject(errors);
        })
        .finally(() => {
          commit('LOADER', false);
        });
    });
  },
  
}