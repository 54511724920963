<template>
    <div class="main-box">
        <h2>Pedido #{{ formData.id }}</h2>
        <p>Realizado em {{ formatDate(formData.data) }}</p>

        <hr>

        <table>
            <thead>
            <tr>
                <th scope="col" style="width: 40%; text-align: left;">Produto</th>
                <th scope="col" style="width: 20%; text-align: center;">Qtde.</th>
                <th scope="col" style="width: 20%; text-align: right;">Total</th>
            </tr>
            </thead>

            <tbody>
            <tr v-for="(data, index) in items" :key="index">
                <td>{{ data.referencia }} - {{ data.descricao_simples }}</td>
                <td style="text-align: center;">{{ data.qtde }}</td>
                <td style="text-align: right;">R$ {{ formatMoney(data.vlr_uni) }}</td>
            </tr>
            </tbody>
        </table>

        <hr>

        <div class="values">
            <div>
                <span>Subtotal ({{ formData.qtd_itens }} itens):</span>
                <span>R$ {{ formatMoney(formData.vlr_total_itens) }}</span>
            </div>

            <div>
                <span>Frete ({{ formData.frete_tipo }}):</span>
                <span>R$ {{ formatMoney(formData.frete) }}</span>
            </div>

            <div>
                <span>Desconto:</span>
                <span>R$ {{ formatMoney(formData.vlr_total_descontos) }}</span>
            </div>

            <div style="font-weight: bold">
                <span>Total:</span>
                <span>R$ {{ formatMoney(formData.vlr_total_pedido) }}</span>
            </div>
        </div>

        <hr>

        <div class="recipient">
            <h2>Destinatário:</h2>
            <p>{{ formData.end_nome_destinatario }}</p>
            <p>CPF/CNPJ: {{ formatRecords(formData.cnpj_cliente) }}</p>
            <p>{{ formData.end_endereco }}, {{ formData.end_numero }} - {{ formData.end_bairro }}</p>
            <p>{{ formData.end_cidade }}/{{ formData.end_estado }}</p>
            <p>{{ formData.end_cep }}</p>
            <p>Fone: {{ formatPhone(formData.fone) }}</p>
        </div>

        <hr>

        <div class="sender">
            <h2>Remetente:</h2>
            <p>REGALLI SEMIJOIAS</p>
            <p>Fone: (19) 99661-9942</p>
            <p>Rua Dario Roland 664 - Jardim Res. Santina Paroli Peccinino</p>
            <p>LIMEIRA/SP</p>
            <p>13481-385</p>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        id: {
            required: true
        }
    },

    data() {
        return {
            formData: {
                id: this.id,
                nome_cliente: "",
                cnpj_cliente: "",
                data: "",
                fone: "",
                end_bairro: "",
                end_cep: "",
                end_cidade: "",
                end_endereco: "",
                end_estado: "",
                end_numero: "",
                end_nome_destinatario: "",
                frete_tipo: "",
                frete: "",
                forma_pgto: "",
                peso_total_pedido: "",
                qtd_itens: "",
                vlr_total_itens: "",
                vlr_total_pedido: "",
                vlr_total_descontos: "",
                vlr_desconto_cupom: "",
                cupom: "",
                id_vale_compra: "",
                imposto: "",
                id_vendedor: "",
                previsao_entrega: "",
                status: []
            },

            items: [],
        }
    },

    created() {
        this.loadItems()
    },

    methods: {
        loadItems() {
            this.$store.dispatch('loadCommerceSale', this.id)
                .then((r) => {
                    this.formData = r.sales.original.registros
                    this.items = r.itens.original.registros
                    this.formData.fone = r.client.original.registros.fone
                    setTimeout(() => {
                        window.print()
                        window.close()
                    }, 1000)
                })
        },

        formatDate(value) {
            let date = value.split(" ")[0]
            date = date.split("-")
            return `${date[2]}/${date[1]}/${date[0]}`
        },

        formatMoney(value) {
            return value = value.toLocaleString('pt-br', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });
        },

        formatRecords(value) {
            value = value.replace(/\D/g, '');
            if (value.length === 11)
                /* eslint-disable-next-line */
                return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3-\$4");

            /* eslint-disable-next-line */
            return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3/\$4-\$5");
        },

        formatPhone(value) {
            return value
                .replace(/\D/g, '')
                .replace(/(?:(^\+\d{2})?)(?:([1-9]{2})|([0-9]{3})?)(\d{4,5})(\d{4})/,
                    (fullMatch, country, ddd, dddWithZero, prefixTel, suffixTel) => {
                        if (country) return `${ country } (${ ddd || dddWithZero }) ${ prefixTel }-${ suffixTel}`;
                        if (ddd || dddWithZero) return `(${ ddd || dddWithZero }) ${ prefixTel }-${ suffixTel}`;
                        if (prefixTel && suffixTel) return `${ prefixTel }-${ suffixTel }`;
                        return value;
                    });
        }

    }
}
</script>

<style scoped>
.main-box {
    height: auto;
    width: 550px;
    padding: 1rem;
    border-radius: 3px;
    background: #FFFFFF;
    border: 1px dashed #000000;
    -webkit-print-color-adjust: exact;
}

h2, p {
    margin: 5px 0;
}

table {
    width: 100%;
}

.values div {
    display: flex;
    margin: 5px 0;
    justify-content: space-between;
}

.recipient h2,
.sender h2 {
    margin: 0;
    font-size: 1.2rem;
    text-transform: uppercase;
}

</style>