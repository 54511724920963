import axios from "axios";

const RESOURCE = "api/v1/bw/sales";

export default {
    actions: {
        loadCommerceSales(context, params) {
            context.commit("LOADER", true)
            return new Promise((resolve, reject) => {
                axios.get(`${RESOURCE}`, {params})
                    .then((response) => {
                        context.commit("COMMERCE_SALES", response.data)
                        return resolve(response.data)
                    }).catch((errors) => {
                    return reject(errors)
                }).finally(() => context.commit("LOADER", false))
            })
        },

        loadCommerceSale(context, id) {
            context.commit("LOADER", true)
            return new Promise((resolve, reject) => {
                axios.get(`${RESOURCE}/${id}`)
                    .then((response) => {
                        return resolve(response.data)
                    }).catch((errors) => {
                    return reject(errors)
                }).finally(() => context.commit("LOADER", false))
            })
        },
    },

    mutations: {
        COMMERCE_SALES(state, items) {
            state.items = items
        }
    },

    state: {
        items: {
            data: []
        }
    }
}