<template>
  <painel-component :items="itemsOne">
    <form >
      <row-component>
        <col-component class-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
            <label>Pessoa:</label>
            <multi-select placeholder="Física / Jurídica"
              :options="selectPessoa"
              v-model="formData.type"
              />
        </col-component>

        <col-component class-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
            <label>Status:</label>
            <multi-select placeholder="Status" v-model="formData.status" :options="selectActiveInative" />
        </col-component>
        
        <col-component class-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
            <label>Excel/PDF:</label>
            <multi-select placeholder="Excel ou PDF" v-model="formData.excel_pdf" :options="selectExcelOrPdf" />
        </col-component>

    </row-component>

    <row-component>
      <col-component class-col="col-xxl-3 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
        <button-submit class="me-1" @click="submit" name="Gerar"/>
      </col-component>
    </row-component>
    </form>
  </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from '@/components/row/RowComponent.vue';
import ColComponent from "@/components/row/ColComponent.vue";
import MultiSelect from "@/components/form/MultiSelect";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";

export default {
  name: "ProductReport",
  components: {
    PainelComponent,
    RowComponent,
    ColComponent,
    MultiSelect,
    ButtonSubmit,
  },
  created() {
    this.$store.dispatch("changeNamePage", "Email Clientes");
    this.checkACL();
  },
  data() {
    return {
      itemsOne: {
        title: "Relatório",
        icon: "list alternate"
      },
      formData: {
        type: "",
        status: "",
        excel_pdf: 2,
      },
      viewPage: false,
      baseUrl: '',
    }
  },
  
  methods: {
    submit() {
      this.$store.dispatch('loadClientsEmail', {...this.params})
        .then((r) => {
          window.open(r.link, '_blank');
        }).catch(() => {
        });
    },

    async checkACL() {
      this.$store.dispatch('ACLItems', 19)
        .then((r) => {
          if (r.indexOf('report-all') !== -1) {
            this.viewPage = true;
            if (r.indexOf('report-all-search') !== -1) {
              this.viewPage = true;
            }
          } else {
            this.$router.push({name: 'home'});
          }
        }).catch(() => {
          this.$router.push({name: 'home'});
        });
    },
  },

  computed: {
    selectActiveInative() {
      return this.$store.state.var.selectActiveInative;
    },

    selectExcelOrPdf() {
      return this.$store.state.var.selectExcelOrPdf;
    },

    selectPessoa() {
        return this.$store.state.var.selectPessoa;
      },

    object() {
      return this.$store.state.report_products.items;
    },
    params() {
      return {
        type: this.formData.type,
        status: this.formData.status,
        excel_pdf: this.formData.excel_pdf,
      };
    }
  }
};
</script>
