<template>
    <painel-component :items="itemsOne">

        <div class="tabs effect-3">
            <input type="radio" id="tab-1" name="tab-effect-3" checked>
            <span>
                <i class="info circle icon"></i>
                <span>Informações</span>
            </span>

            <input type="radio" id="tab-2" name="tab-effect-3">
            <span>
                <i class="picture icon"></i>
                <span>Foto</span>
            </span>

            <div class="line ease"></div>
            <div class="tab-content">
                <section id="tab-item-1">
                    <form>
                        <row-component>
                            <col-component class-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                <label>Referência:</label>
                                <input-component v-model="formData.referencia" disabled/>
                            </col-component>

                            <col-component class-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                <label>Nome do Produto:</label>
                                <input-component v-model="formData.descricao" disabled/>
                            </col-component>

                            <col-component class-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                <label>Status:</label>
                                <input-component v-model="formData.status" disabled/>
                            </col-component>
                        </row-component>

                        <row-component>
                            <col-component class-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                <label>Categoria:</label>
                                <input-component v-model="formData.categoria" disabled/>
                            </col-component>

                            <col-component class-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                <label>Marca:</label>
                                <input-component disabled/>
                            </col-component>

                            <col-component class-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                <label>Preço de Venda:</label>
                                <input-component v-model="formData.preco" disabled/>
                            </col-component>
                        </row-component>

                        <row-component>
                            <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                                <label>Preço Promocional:</label>
                                <input-component v-model="formData.valor_promocional" disabled/>
                            </col-component>

                            <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                                <label>Desconto:</label>
                                <input-component v-model="formData.desconto" disabled/>
                            </col-component>

                            <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                                <label>Preço de Custo:</label>
                                <input-component v-model="formData.custo" disabled/>
                            </col-component>

                            <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                                <label>Quantidade Estoque:</label>
                                <input-component v-model="formData.qtd_estoque" disabled/>
                            </col-component>
                        </row-component>

                        <row-component>
                            <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                                <label>Peso:</label>
                                <input-component v-model="formData.peso" disabled/>
                            </col-component>

                            <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                                <label>Altura:</label>
                                <input-component v-model="formData.altura" disabled/>
                            </col-component>

                            <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                                <label>Largura:</label>
                                <input-component v-model="formData.largura" disabled/>
                            </col-component>

                            <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                                <label>Comprimento:</label>
                                <input-component v-model="formData.comprimento" disabled/>
                            </col-component>
                        </row-component>

                        <row-component>
                            <col-component class-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <label>Descrição Completa:</label>
                                <div class="form-control textarea">
                                    <span v-html="formData.descricao_completa"></span>
                                </div>
                            </col-component>
                        </row-component>
                    </form>
                </section>

                <section id="tab-item-2">
                    <row-component>
                        <col-component v-for="(data, index) in images" :key="index"
                                       class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                            <div class="card position-relative">
                                <span v-if="data.principal === true"
                                      class="position-absolute rounded-0 top-0 start-0 badge text-bg-dark">
                                    Principal
                                </span>
                                <img class="img-fluid"
                                     :src="`https://www.bwimg.com.br/iwassasemijoias/produtos/${data.arquivo}`" alt="">
                            </div>
                        </col-component>
                    </row-component>
                </section>

                <row-component>
                    <col-component class-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <button-route class="w-100" :items="itemsTwo"/>
                    </col-component>
                </row-component>
            </div>
        </div>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import ColComponent from "@/components/row/ColComponent";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import InputComponent from "@/components/form/InputComponent";

import {useToast} from "vue-toastification";

const toast = useToast()
export default {
    name: "CommerceProductShow",
    components: {
        InputComponent,
        ButtonRoute,
        ColComponent,
        RowComponent,
        PainelComponent
    },

    props: {
        id: {
            require: true
        }
    },

    created() {
        this.$store.dispatch("changeNamePage", "Ecommerce Produto")
        this.loadItems()
    },

    data() {
        return {
            itemsOne: {
                icon: "eye",
                title: "Ver"
            },

            itemsTwo: {
                icon: "reply all",
                name: "Voltar",
                route: "/e-commerce-product/search"
            },

            formData: {
                referencia: "",
                categoria: "",
                preco: "",
                valor_promocional: "",
                desconto: "",
                custo: "",
                qtd_estoque: "",
                descricao: "",
                peso: "",
                altura: "",
                largura: "",
                comprimento: "",
                status: "",
                descricao_completa: ""
            },

            images: []
        }
    },

    methods: {
        loadItems() {
            this.$store.dispatch('loadCommerceProduct', this.id)
                .then((r) => {
                    let data = r.price.original.registros
                    let dataTwo = r.product.original.registros

                    this.formData.descricao_completa = dataTwo.descricao_completa
                    this.images = r.imagem.original.registros

                    this.completePrice(data)
                    this.completeFormData(dataTwo)
                }).catch((e) => {
                console.error(e)
                toast.error('Erro desconhecido!', {timeout: 1500})
            })
        },

        completeFormData(data) {
            this.formData.categoria = data.id_hierarquia_produto.nome
            this.formData.referencia = data.referencia
            this.formData.descricao = data.descricao
            this.formData.peso = data.peso
            this.formData.altura = data.altura
            this.formData.largura = data.largura
            this.formData.comprimento = data.comprimento
            this.formData.status = data.ativo ? "Ativo" : "Inativo"
        },

        completePrice(data) {
            this.formData.preco = data.preco ? data.preco.toLocaleString('pt-br', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }) : "Valor não informado"

            this.formData.valor_promocional = this.formatMoney(data?.valor_promocional)
            this.formData.desconto = this.formatMoney(data.id_produto?.desconto)
            this.formData.custo = this.formatMoney(data.id_produto?.custo)
            this.formData.qtd_estoque = data.id_produto?.qtd_estoque
        },

        formatMoney(value) {
            if (value !== undefined) {
                return value.toLocaleString('pt-br', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                })
            }
        }
    }
}
</script>

<style scoped>
.tabs > input,
.tabs > span,
.effect-3 .line {
    width: 50%;
}

#tab-2,
#tab-2 + span,
#tab-2:checked ~ .line {
    left: 50%;
}

.textarea {
    opacity: 1;
    resize: none;
    height: 200px;
    border-radius: 0;
    border-color: #000000;
    background-color: var(--bs-secondary-bg);
}
</style>