import axios from "axios";
import {URL_BASE} from "@/configs/constants";

const RESOURCE = "product";
export default {
    loadProducts(context, params) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}${RESOURCE}`, {params})
                .then((response) => {
                    context.commit("PRODUCT", response.data)
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => context.commit("LOADER", false))
        })
    },

    storeProduct(context, formData) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE}${RESOURCE}`, formData)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => context.commit("LOADER", false))
        })
    },

    loadProduct(context, id) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}${RESOURCE}/${id}`)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => context.commit("LOADER", false))
        })
    },

    updateProduct(context, formData) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE}${RESOURCE}/${formData.id}`, formData)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => context.commit("LOADER", false))
        })
    },

    storeProductPhoto(context, formData) {
        context.commit('LOADER', true)
        let config = {headers: {'Content-Type': 'multipart/form-data'}}
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE}product-add-photo`, formData, config)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => context.commit('LOADER', false))
        })
    },

    updateProductPhoto(context, formData) {
        context.commit('LOADER', true)
        let config = {headers: {'Content-Type': 'multipart/form-data'}}
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE}product-update-photo`, formData, config)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => context.commit('LOADER', false))
        })
    },

    syncProductPhoto(context, id) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE}product-shop-img-synchronize/${id}`)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => context.commit("LOADER", false))
        })
    },

    destroyProductPhoto(context, formData) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.delete(`${URL_BASE}product-delete-photo/${formData.id}/${formData.photo_id}`)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => context.commit("LOADER", false))
        })
    },

    updateProductPhotoOrder(context, formData) {
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE}product-order-photo/${formData.id}`, formData)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            })
        })
    },

    loadProductPhotos(context, id) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}product-get-photo/${id}`)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => context.commit("LOADER", false))
        })
    },

    updateProductPhotoCover(context, formData) {
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE}product-cover-photo`, formData)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            })
        })
    },

    storeProductShop(context, formData) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE}product-shop`, formData)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => context.commit("LOADER", false))
        })
    },

    destroyProductShop(context, id) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.delete(`${URL_BASE}product-delete-bw/${id}`)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => context.commit("LOADER", false))
        })
    },
}