export default {
    icon: "shopping cart",
    name: "BW",
    subLinks: [
        {
            name: "Cliente",
            route: "/e-commerce-client/search",
            pagePermissions: [
                "bw-client"
            ],
        },

        {
            name: "Produtos",
            route: "/e-commerce-product/search",
            pagePermissions: [
                "bw-product"
            ],
        },

        {
            name: "Carrinho Abandonado",
            route: "/e-commerce-cart/search",
            pagePermissions: [
                "bw-abandoned-cart"
            ],
        },

        {
            name: "Vendas",
            route: "/e-commerce-sales/search",
            pagePermissions: [
                "bw-sales"
            ],
        },
    ],

    pageNavCheck: [
        "bw-client",
        "bw-product",
        "bw-abandoned-cart",
        "bw-sales"
    ]
}