import AdminComponent from '@/components/dashboard/AdminComponent'
import FinanceExitEffective from "@/views/report/FinanceExitEffective";

export default {
    path: '/financial-exit-effective',
    component: AdminComponent,
    meta: {auth: true},
    children: [
        {path: 'search', name: 'financial-exit-effective-search', component: FinanceExitEffective},
    ]
}