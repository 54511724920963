import AdminComponent from "@/components/dashboard/AdminComponent";
import ProductReportEntranceExit from "@/views/report/ProductReportEntranceExit.vue";

export default {
    path: "/report-products-entrance-exit",
    meta:{auth: true},
    component: AdminComponent,
    children: [
        {path: "search", name: "report-products-entrance-exit-search", component: ProductReportEntranceExit},
    ]
}