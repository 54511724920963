import AdminComponent from '@/components/dashboard/AdminComponent'
import FinanceEntrancePreview from "@/views/report/FinanceEntrancePreview";

export default {
    path: '/financial-entrance-preview',
    component: AdminComponent,
    meta: {auth: true},
    children: [
        {path: 'search', name: 'financial-entrance-preview-search', component: FinanceEntrancePreview},
    ]
}