<template>
    <button  class="btn btn-success rounded-0 me-1">
        <i class="m-0 edit outline icon"></i>
    </button>
</template>

<script>
export default {
    name: "ButtonEditSubmit",

    props: {

    },
}
</script>
