<template>
  <painel-component :items="itemsOne">
    <error-component :errors="errors" v-if="showError"/>

    <form v-if="showView">
      <row-component>
        <col-component :class-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                           :class="{'formError' : errors.name}">
          <label>Nome:</label>
          <input-component :type-input="'text'" v-model="formData.name"/>
        </col-component>

        <col-component :class-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                           :class="{'formError' : errors.status}">
          <label>Status:</label>
          <MultiSelect v-model="formData.status" :options="selectActiveInative" :searchable="true"
                       :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
        </col-component>
      </row-component>

      <row-component>
        <col-component :class-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                           :class="{'formError' : errors.obs}">
          <label>Observações:</label>
          <text-area :rows="4" v-model="formData.obs"/>
        </col-component>
      </row-component>

      <row-component>
        <col-component class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
          <button-submit class="w-100" :disabled="sendForm" @click="submit" icon="plus" name="Adicionar"/>
        </col-component>

        <col-component class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
          <button-route class="w-100" :items="itemsTwo"/>
        </col-component>
      </row-component>
    </form>

  </painel-component>

</template>

<style scoped>
</style>
<script>



import PainelComponent from "@/components/dashboard/PainelComponent.vue";
import {useToast} from "vue-toastification";
import RowComponent from "@/components/row/RowComponent.vue";
import ErrorComponent from "@/components/alerts/ErrorComponent.vue";
import ColComponent from "@/components/row/ColComponent.vue";
import InputComponent from "@/components/form/InputComponent.vue";
import TextArea from "@/components/form/TextArea.vue";
import ButtonSubmit from "@/components/buttons/ButtonSubmit.vue";
import ButtonRoute from "@/components/buttons/ButtonRoute.vue";
import MultiSelect from "@/components/form/MultiSelect.vue";
const toast = useToast()
export default {
  name: "AccountTypeCreate",
  components: {
    MultiSelect,
    ButtonRoute,
    ButtonSubmit,
    TextArea,
    InputComponent,
    ColComponent,
    ErrorComponent,
    RowComponent,
    PainelComponent
  },


  created() {
    this.$store.dispatch('changeNamePage', 'Tipo de Conta')
    this.checkACL()
  },




  data() {
    return {
      itemsOne: {
        title: "Adicionar",
        icon: "pen square"
      },

      itemsTwo: {
        route: "/account-type/search",
        icon: "reply all",
        name: "Voltar"
      },



      formData: {
        name: '',
        status: '',
        obs: ''
      },

      errors: [],
      showView: false,
      sendForm: false,
      showError: false,
    }
  },

  methods: {

    submit() {
      if (!this.sendForm) {
        this.sendForm = true;
        this.$store.dispatch('storeAccountType', this.formData)
            .then((r) => {
              this.showError = false
              this.sendForm = false
              this.errors = []
              toast.success("Cadastro efetuado com sucesso", {timeout: 2000});
              this.$router.push({name: 'account-type-edit', params: {id: r.data}})
            }).catch((r) => {
          this.showError = true;
          this.sendForm = false
          this.errors = r.response.data.errors;
          this.scrollToTop()
          toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
        })
      }
    },




    scrollToTop() {
      window.scrollTo(0, 0);
    },

    async checkACL() {
      this.$store.dispatch('ACLItems', 10)
          .then((r) => {
            if (r.indexOf('account-type-create') !== -1) {
              this.showView = true
            } else {
              this.$router.push({name: 'home'})
            }
          }).catch(() => {
        this.$router.push({name: 'home'})
      })
    },

  },

  computed: {
    selectActiveInative() {
      return this.$store.state.var.selectActiveInative
    },

    noResultsText() {
      return this.$store.state.var.noResultsText
    },

    noOptionsText() {
      return this.$store.state.var.noOptionsText
    }
  }

}



</script>