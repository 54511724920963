import AdminComponent from "@/components/dashboard/AdminComponent";

import SupplierSearch from "@/views/search/SupplierSearch";
import SupplierCreate from "@/views/create/SupplierCreate";
import SupplierUpdate from "@/views/update/SupplierUpdate";

export default {
    path: "/supplier",
    meta: {auth: true},
    component: AdminComponent,
    children: [
        {path: "search", name: "supplier-search", component: SupplierSearch},
        {path: "create", name: "supplier-create", component: SupplierCreate},
        {path: "edit/:id", name: "supplier-edit", component: SupplierUpdate, props: true},

    ]
}