import AdminComponent from '@/components/dashboard/AdminComponent'
import AccountBankReport from "@/views/report/AccountBankReport";

export default {
    path: '/account-banck',
    component: AdminComponent,
    meta: {auth: true},
    children: [
        {path: 'search', name: 'account-banck-search', component: AccountBankReport},
    ]
}