<template>

  <div class="modal-w d-grid position-fixed top-0 start-0 h-100 w-100" v-if="modalShow">
    <entrance-and-exit-modal
        v-if="modalShow" :id="this.id_modal"
        class="modal-entrance-exit-edit"
        @close="closeModal"
    />



  </div>


  <painel-component :items="itemsOne">
    <row-component>
      <col-component :class-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
        <label>Código:</label>
        <input-component :type-input="'text'" v-model="formData.code"/>
      </col-component>

      <col-component :class-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
        <label>Mês:</label>
        <MultiSelect v-model="formData.month" :options="selectMonthList" :searchable="true"
                     :noOptionsText="noOptionsText"
                     :noResultsText="noResultsText"/>
      </col-component>

      <col-component :class-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
        <label>Ano:</label>
        <MultiSelect v-model="formData.year" :options="getYearSelect()" :searchable="true"
                     :noOptionsText="noOptionsText"
                     :noResultsText="noResultsText"/>
      </col-component>


    </row-component>

    <row-component>
      <col-component :class-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
        <label>Conta Bancária:</label>
        <MultiSelect label="name" v-model="formData.bank_accounts_id" :options="selectAccountBankList"
                     :searchable="true"
                     :noOptionsText="noOptionsText"
                     :noResultsText="noResultsText"/>
      </col-component>
      <col-component :class-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
        <label>Tipo Entrada/Saída:</label>
        <MultiSelect v-model="formData.type" :options="selectEntranceExitList" :searchable="true"
                     :noOptionsText="noOptionsText"
                     :noResultsText="noResultsText"/>
      </col-component>

      <col-component :class-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
        <label>Tipo de Conta:</label>
        <MultiSelect v-model="formData.account_types_id" :options="selectAccountList" :searchable="true"
                     :noOptionsText="noOptionsText"
                     :noResultsText="noResultsText"/>
      </col-component>
    </row-component>

    <row-component>
      <col-component :class-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mt-4'">
        <button-submit class="me-1" @click="submit" icon="search" name="Pesquisar"/>
        <button-route v-if="buttonCheck.create" :items="itemsTwo"/>
      </col-component>
    </row-component>


    <row-component>


      <col-component :class-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
        <div class="bg-card-alert alert alert-secondary alert-dismissible fade show" role="alert">
          <p>Total de entrada: {{ entrance_exit.totalQuantEntrance }}</p>
          <p>Valor: <span>R${{ entrance_exit.totalValueEntrance }}</span></p>
          <i class="icon-card-entrance  arrow alternate circle up outline icon"></i>
        </div>
      </col-component>

      <col-component :class-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
        <div class="bg-card-alert alert alert-secondary alert-dismissible fade show" role="alert">
          <p>Total de saída: {{ entrance_exit.totalQuantExit }}</p>
          <p>Valor: <span>R${{ entrance_exit.totalValueExit }}</span></p>
          <i class="icon-card-exit arrow alternate circle down outline icon"></i>
        </div>
      </col-component>

      <col-component :class-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
        <div class="bg-card-alert alert alert-secondary alert-dismissible fade show" role="alert">
          <p>Subtotal</p>
          <p>Valor: <span>R${{ entrance_exit.subtotal }}</span></p>
          <i class="icon-card-entrance money outline icon"></i>
        </div>
      </col-component>
    </row-component>

    <table class="table table-striped mt-4">
      <thead>
      <tr>
        <th scope="col" style="width: 5%">Código</th>
        <th scope="col" style="width: 10%">Conta Bancária</th>
        <th scope="col" style="width: 10%">Tipo de Conta <br> Tipo de Sub Conta</th>
        <th scope="col" style="width: 5%">Preço</th>
        <th scope="col" style="width: 5%">Entrada/Saída</th>
        <th scope="col" style="width: 10%">Data de Vencimento <br> Data de Efetivação</th>
        <th scope="col" style="width: 10%">
          Ações
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(data, index) in this.object" :key="index">
        <td data-label="Código">{{ data.code }}</td>
        <td data-label="Conta Bancária">
          <img v-if="data.bank_accounts_photo" class="avatar__image mx-1"
               :src="data.bank_accounts_photo" alt="">
          <img v-else class="avatar__image mx-1" src="@/assets/avatar.png" alt="">
          {{ data.bank_accounts_name }}
        </td>
        <td data-label="Tipo de Conta/Tipo de Sub Conta">
          {{ data.account_types_name }} <br>
          {{ data.account_types_sub_name }}
        </td>
        <td data-label="Preço">{{ data.price_paid }}</td>
        <td data-label="Entrada/Saída">{{ statusString(data.type) }}</td>
        <td data-label="Data de Vencimento">{{ data.due_date }} <br> {{ data.effective_date }}</td>
        <td data-label="Ações" >
          <button  class="btn btn-submit rounded-0 m-1"
                  @click="modalShowOpen(data.id)">
            <i class="eye icon"></i>
          </button>
        </td>
      </tr>
      </tbody>
    </table>

    <button-pagination :pagination="entrance_exit.itens" :offset="3" @paginate="loadItens"/>
  </painel-component>


</template>

<style scoped>
.avatar__image {
  width: 30px;
}

.bg-card-alert p {
  margin: 0;
}

.bg-card-alert span {
  font-weight: bold;
}

.card-container h3 {
  color: #424242;
  font-size: 14px;
  font-family: inherit;
  text-transform: uppercase;
}

.icon-card-entrance,
.icon-card-entrance-table {
  font-size: 24px;
  color: #16ab39;
}

.icon-card-exit,
.icon-card-exit-table {
  font-size: 24px;
  color: #db2828;
}

.icon-card-entrance,
.icon-card-exit {
  top: 0;
  right: 0;
  font-size: 2rem;
  margin: 15px 15px;
  position: absolute;
}

.btn-submit {
  color: #ffffff;
  border-color: #000000;
  background-color: #000000;
}

.btn-submit.disabled,
.btn-submit:disabled {
  opacity: 0.8;
  color: #ffffff;
  border-color: #000000 !important;
  background-color: #000000 !important;
}

.btn-submit:active,
.btn-submit:focus,
.btn-submit:hover {
  color: #ffffff;
  border-color: #000000 !important;
  background-color: #000000 !important;
}


.modal-w {
  place-items: center;
  background: rgba(0, 0, 0, 0.9);
  z-index: 99;
}

</style>

<script>

import PainelComponent from "@/components/dashboard/PainelComponent.vue";
import RowComponent from "@/components/row/RowComponent.vue";
import ColComponent from "@/components/row/ColComponent.vue";
import InputComponent from "@/components/form/InputComponent.vue";
import MultiSelect from "@/components/form/MultiSelect.vue";
import {useToast} from "vue-toastification";

const toast = useToast()
import ButtonSubmit from "@/components/buttons/ButtonSubmit.vue";
import ButtonRoute from "@/components/buttons/ButtonRoute.vue";
import ButtonPagination from "@/components/buttons/ButtonPagination.vue";
import EntranceAndExitModal from "@/components/modal/EntranceAndExitModal.vue";

export default {
  name: "EntranceExitPreviewSearch",
  components: {
    EntranceAndExitModal,
    ButtonPagination,
    ButtonRoute,
    ButtonSubmit,
    InputComponent,
    ColComponent,
    RowComponent,
    PainelComponent,
    MultiSelect
  },

  created() {
    this.$store.dispatch('changeNamePage', 'Entrada/Saída')
    this.$store.dispatch('loadAccountBankMultiSelect')
    this.$store.dispatch('loadAccountTypeMultiSelect')
    this.checkACL()
    this.loadItens(1)
  },

  data() {
    return {
      itemsOne: {
        title: "Pesquisar",
        icon: "list alternate"
      },

      itemsTwo: {
        route: "/entrance-exit/create",
        icon: "plus",
        name: "Adicionar"
      },

      buttonSubmit: {
        icon: 'ti-search',
      },

      buttonRoute: {
        icon: 'ti-plus',
        route: '/entrance-exit-preview/create'
      },

      buttonCheck: {
        create: false,
      },

      object: {},

      modalShow:false,
      id_modal: '',

      formData: {
        month: this.getMonth(),
        year: this.getYear(),
        code: '',
        bank_accounts_id: '',
        account_types_id: '',
        type: '',
      },

      searchSubmit: false,
    }
  },

  methods: {

    submit() {
      this.loadItens(1)
      this.searchSubmit = true
    },

    modalShowOpen(id) {
      this.modalShow = true
      this.id_modal = id;

    },


    statusString(value) {
      if (value === '1')
        return 'Entrada'
      return 'Saída'
    },

    getYearSelect() {
      let dataActual = new Date();
      let year = dataActual.getFullYear() + 2;
      let years = [];
      for (let i = 2021; i <= year; i++) {
        years.push(i);
      }
      return years
    },

    getYear() {
      let date = new Date()
      return date.getFullYear()
    },

    getMonth() {
      let date = new Date()
      return date.getMonth() + 1
    },

    loadItens(page) {
      if (!this.searchSubmit) {
        this.$store.dispatch('loadEntriesDepartures', {...this.params, page})
            .then((r) => {
              this.object = r.itens.data
              if (this.searchSubmit) {
                this.searchSubmit = false;
                if (r.itens.data == '') {
                  toast.info("Nada foi encontrado com os parâmetros informados!", {timeout: 2000});
                }
              }
            }).catch(() => {
          this.searchSubmit = false;
        });
      }
    },

    async checkACL() {
      this.$store.dispatch('ACLItems', 12)
          .then((r) => {

            if (r.indexOf('entrance-exit-search') !== -1) {

              if (r.indexOf('entrance-exit-create') !== -1) {
                this.buttonCheck.create = true
              }







            } else {
              this.$router.push({name: 'home'})
              console.log("01")
            }
          }).catch(() => {

        this.$router.push({name: 'home'})

      })
    },


    closeModal() {
      this.modalShow = false
      this.loadItens(1)
    },



  },


  computed: {
    selectActiveInative() {
      return this.$store.state.var.selectActiveInative
    },

    noResultsText() {
      return this.$store.state.var.noResultsText
    },

    noOptionsText() {
      return this.$store.state.var.noOptionsText
    },

    selectMonthList() {
      return this.$store.state.var.selectMonthList
    },

    selectEntranceExitList() {
      return this.$store.state.var.selectEntranceExitList
    },

    selectAccountBankList() {
      return this.$store.state.var.selectAccountBankList
    },

    selectAccountList() {
      return this.$store.state.var.selectAccountList
    },

    selectYesNoList() {
      return this.$store.state.var.selectYesNoList
    },

    entrance_exit() {
      return this.$store.state.entrance_exit.items
    },

    params() {
      return {
        page: this.entrance_exit.current_page,
        month: this.formData.month,
        year: this.formData.year,
        code: this.formData.code,
        bank_accounts_id: this.formData.bank_accounts_id,
        account_types_id: this.formData.account_types_id,
        type: this.formData.type,
      }
    },

    me() {
      return this.$store.state.auth.me
    },
  }


}

</script>
