<template>
    <button :type="type" class="btn btn-submit rounded-0" data-bs-toggle='tooltip'>
        <i v-if="icon" :class="'m-0 icon ' + icon"></i>
        {{ name }}
    </button>
</template>

<script>
import {Tooltip} from "bootstrap";

export default {
    name: "ButtonSubmit",

    props: {
        icon: {
            "required": false,
            "type": String,
            "default": ''
        },

        name: {
            "type": String,
            "default": ''
        },

        type: {
            "type": String,
            "default": "button"
        }
    },

    mounted() {
        new Tooltip(document.body, {
            selector: "[data-bs-toggle='tooltip']",
        })
    },
}
</script>

<style scoped>
.btn-submit{
    color: #ffffff;
    border-color: #000000;
    background-color: #000000;
}

.btn-submit.disabled,
.btn-submit:disabled{
    opacity: 0.8;
    color: #ffffff;
    border-color: #000000 !important;
    background-color: #000000 !important;
}

.btn-submit:active,
.btn-submit:focus,
.btn-submit:hover{
    color: #ffffff;
    border-color: #000000 !important;
    background-color: #000000 !important;
}
</style>