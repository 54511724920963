import AdminComponent from '@/components/dashboard/AdminComponent'
import ProductsPhoto from "@/views/report/ProductsPhoto";

export default {
    path: '/products-photo',
    component: AdminComponent,
    meta: {auth: true},
    children: [
        {path: 'search', name: 'products-photo-search', component: ProductsPhoto},
    ]
}