<template>


  <painel-component :items="itemsOne">
    <error-component :errors="errors" v-if="showError"/>

    <form class="mb-4 mt-2" v-else>





      <row-component>
        <col-component :class-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                           :class="{ 'formError': errors.type }">
          <label>Entrada / Saída:</label>
          <MultiSelect v-model="formData.type" :searchable="true" :options="selectEntranceExit"
                       :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
        </col-component>



        <col-component :class-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'"
                           :class="{ 'formError': errors.bank_accounts_id }">
          <label>Conta Bancária:</label>
          <MultiSelect v-model="formData.bank_accounts_id" label="name" :options="selectAccountBankList"
                       :searchable="true" :noResultsText="noResultsText" :noOptionsText="noResultsText">
            <template v-slot:singlelabel="{ value }">
              <div class="multiselect-single-label">
                <img class="character-label-icon" :src="value.icon" alt=""> {{ value.name }}
              </div>
            </template>

            <template v-slot:option="{ option }">
              <img class="character-option-icon" :src="option.icon" alt=""> {{ option.name }}
            </template>
          </MultiSelect>
        </col-component>


      </row-component>

      <row-component>
        <col-component :class-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                           :class="{ 'formError': errors.account_types_id }">
          <label>Tipo de Conta:</label>
          <MultiSelect v-model="formData.account_types_id" :searchable="true"
                       @select="selectSubTypeAccount(formData.account_types_id)"
                       :options="selectAccountType"
                       :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
        </col-component>

        <col-component :class-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                           :class="{ 'formError': errors.account_types_id }">
          <label>Tipo de Sub Conta:</label>
          <MultiSelect v-model="formData.account_type_sub_id" :searchable="true"
                       :options="selectAccountTypeSubList"
                       :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
        </col-component>
      </row-component>

      <row-component>
        <col-component :class-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                           :class="{ 'formError': errors.amount }">
          <label>Quantidade:</label>
          <input-component :type="'number'" v-model="formData.amount" />
        </col-component>

        <col-component :class-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                           :class="{ 'formError': errors.frequency }">
          <label>Periodicidade:</label>
          <MultiSelect v-model="formData.frequency" :searchable="true" :options="selectFrequency"
                       :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
        </col-component>

        <col-component :class-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                           :class="{ 'formError': errors.price }">
          <label>Valor:</label>
          <input-component input="'text'" v-model="formData.price" v-money="money"/>
        </col-component>

        <col-component :class-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                           :class="{ 'formError': errors.due_date }">
          <label>Data de Vencimento:</label>
          <input-component input="'text'" v-model="formData.due_date" v-mask="'##/##/####'"/>
        </col-component>
      </row-component>

      <row-component>
        <col-component :class-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
          <label>Observações:</label>
          <text-area v-model="formData.obs" :rows="4"/>
        </col-component>
      </row-component>

      <row-component>
        <col-component class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
          <button-submit class="w-100" :disabled="sendForm" @click="submit" icon="plus" name="Adicionar"/>
        </col-component>

        <col-component class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
          <button-route class="w-100" :items="itemsTwo"/>
        </col-component>
      </row-component>


    </form>
  </painel-component>
</template>

<style scoped>
.multiselect-option img {
  width: 30px;
  padding-right: 10px;
}
.multiselect-single-label img{
  width: 30px;
  padding-right: 10px;
}


</style>
<script>
import PainelComponent from "@/components/dashboard/PainelComponent.vue";
import ErrorComponent from "@/components/alerts/ErrorComponent.vue";
import RowComponent from "@/components/row/RowComponent.vue";
import ColComponent from "@/components/row/ColComponent.vue";
import {VMoney} from 'v-money'
import {mask} from 'vue-the-mask'
import {MONEY} from "@/configs/constants"
import InputComponent from "@/components/form/InputComponent.vue";
import TextArea from "@/components/form/TextArea.vue";
import {useToast} from "vue-toastification";
const toast = useToast();
import MultiSelect from "@vueform/multiselect";
import ButtonRoute from "@/components/buttons/ButtonRoute.vue";
import ButtonSubmit from "@/components/buttons/ButtonSubmit.vue";
export default {
  
  name: "EntranceExitPreviewCreate",
  components: {
    ButtonSubmit, ButtonRoute,
    TextArea,
    InputComponent,
    ColComponent,
    RowComponent,
    ErrorComponent,
    PainelComponent,
    MultiSelect
  },


  directives: {
    money: VMoney,
    mask
  },


  created() {
    this.$store.dispatch('changeNamePage', 'Entrada/Saída (Previsão)')
    this.$store.dispatch('loadAccountBankMultiSelect')
    this.$store.dispatch('loadAccountTypeMultiSelect')
    this.checkACL()
  },


  data() {
    return {
      itemsOne: {
        title: "Adicionar",
        icon: "pen square"
      },

      itemsTwo: {
        route: "/entrance-exit-preview/search",
        icon: "reply all",
        name: "Voltar"
      },

      formData: {
        type: '',
        bank_accounts_id: '',
        account_types_id: '',
        account_type_sub_id: '',
        due_date: this.dateCurrent(),
        price: '',
        attended: 1,
        business: '',
        amount: '',
        frequency: '',
        obs: ''
      },

      money: MONEY,

      errors: [],
      sendForm: false,
      showError: false,
      showLoader: true,
    }
  },

  methods: {
    submit() {
      if (!this.sendForm) {
        this.sendForm = true;
        this.$store.dispatch('storeEntranceExitPreview', this.formData)
            .then(() => {
              this.showError = false
              this.sendForm = false
              this.errors = []
              toast.success("Cadastro relizado com sucesso", {timeout: 2000});
              this.$router.push({name: 'entrance-exit-preview-search'})
            }).catch((r) => {
          this.showError = true;
          this.sendForm = false
          this.errors = r.response.data.errors;
          this.scrollToTop();
          toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
        })
      }
    },

    async checkACL() {
      this.$store.dispatch('ACLItems', 13)
          .then((r) => {
            if (r.indexOf('entrance-exit-preview-create') !== -1) {
              this.showLoader = false
            } else {
              this.$router.push({name: 'home'})
            }
          }).catch(() => {
        this.$router.push({name: 'home'})
      })
    },

    selectSubTypeAccount(id) {
      this.formData.account_type_sub_id =''
      this.$store.dispatch('loadAccountTypeSubMultiSelect', id)
    },

    dateCurrent() {
      let currentTime = new Date();
      let day = currentTime.getDate();
      let month = currentTime.getMonth() + 1;
      let year = currentTime.getFullYear();

      day = (day < 10 ? '0' : '') + day;
      month = (month < 10 ? '0' : '') + month;

      return `${day}/${month}/${year}`
    },

    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },


  computed: {

    noResultsText() {
      return this.$store.state.var.noResultsText
    },

    noOptionsText() {
      return this.$store.state.var.noOptionsText
    },

    selectEntranceExit() {
      return this.$store.state.var.selectEntranceExit;
    },

    selectAccountBankList() {
      return this.$store.state.var.selectAccountBankList
    },

    selectAccountType() {
      return this.$store.state.var.selectAccountList;
    },

    selectFrequency() {
      return this.$store.state.var.selectFrequencyList
    },

    selectAccountTypeSubList() {
      return this.$store.state.var.selectAccountSubList
    }



  },


}



</script>
