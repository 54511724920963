<template>
    <painel-component :items="itemsOne">
        <error-component :errors="errors" v-if="showError"/>

        <div class="clearfix mb-3" v-if="create">
            <button-route class="float-end" :items="itemsThree"/>
        </div>

        <form v-if="viewPage">
            <row-component>
                <col-component class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                               :class="{'formError' : errors.name}">
                    <label>Nome:</label>
                    <input-component v-model="formData.name"/>
                </col-component>

                <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                               :class="{'formError' : errors.sexo}">
                    <label>Sexo:</label>
                    <MultiSelect v-model="formData.sexo" :options="selectSexo"/>
                </col-component>

                <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                               :class="{'formError' : errors.status}">
                    <label>Status:</label>
                    <MultiSelect v-model="formData.status" :options="selectActiveInative"/>
                </col-component>
            </row-component>

            <row-component>
                <col-component class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                               :class="{'formError' : errors.email}">
                    <label>Login:</label>
                    <input-component v-model="formData.email" type="email"/>
                </col-component>

                <col-component class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                               :class="{'formError' : errors.group_id}">
                    <label>Grupo:</label>
                    <MultiSelect v-model="formData.group_id" :options="selectUserGroup"/>
                </col-component>
            </row-component>

            <row-component>
                <col-component class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <label>Alterar Senha:</label>
                    <div class="form-check form-switch">
                        <input @click="passwordToAlter" class="form-check-input" type="checkbox" role="switch"
                               id="switch">
                    </div>
                </col-component>
            </row-component>

            <row-component v-if="updatePassword">
                <col-component class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                               :class="{'formError' : errors.password}">
                    <label>Senha:</label>
                    <input-component v-model="formData.password" type="password"/>
                </col-component>

                <col-component class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                               :class="{'formError' : errors.passwordconfirm}">
                    <label>Confirmar Senha:</label>
                    <input-component v-model="formData.passwordconfirm" type="password"/>
                </col-component>
            </row-component>

            <row-component>
                <col-component class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-submit :disabled="sendForm" @click="submit" class="w-100" icon="save" name="Salvar"/>
                </col-component>

                <col-component class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-route class="w-100" :items="itemsTwo"/>
                </col-component>
            </row-component>
        </form>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import ColComponent from "@/components/row/ColComponent";
import InputComponent from "@/components/form/InputComponent";
import MultiSelect from "@/components/form/MultiSelect";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import ErrorComponent from "@/components/alerts/ErrorComponent";

import {useToast} from "vue-toastification";

const toast = useToast()
export default {
    name: "UserUpdate",

    components: {
        ErrorComponent,
        ButtonRoute,
        ButtonSubmit,
        MultiSelect,
        InputComponent,
        ColComponent,
        RowComponent,
        PainelComponent
    },

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch("changeNamePage", "Usuário")
        this.$store.dispatch("loadSelectUserGroup")
        this.loadItems()
        this.checkACL()
    },

    data() {
        return {
            itemsOne: {
                title: "Editar",
                icon: "edit"
            },

            itemsTwo: {
                route: "/user/search",
                icon: "reply all",
                name: "Voltar"
            },

            itemsThree: {
                route: "/user/create",
                icon: "plus",
            },

            formData: {
                name: "",
                sexo: "",
                status: "",
                email: "",
                group_id: "",
                password: "",
                passwordconfirm: "",
                updatepassword: "",
            },

            updatePassword: false,

            create: false,

            errors: [],
            sendForm: false,
            viewPage: false,
            showError: false,
        }
    },

    methods: {
        loadItems() {
            this.$store.dispatch('loadUser', this.id)
                .then((r) => {
                    this.formData = r
                    this.formData.updatepassword = false
                }).catch(() => {
                toast.error('Erro desconhecido!', {timeout: 1500})
            })
        },

        submit() {
            if (!this.sendForm) {
                this.sendForm = true
                this.$store.dispatch('updateUser', this.formData)
                    .then(() => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        window.scroll(0, 0)
                        toast.success('Cadastro atualizado com sucesso', {timeout: 1500})
                    }).catch((e) => {
                    this.showError = true
                    this.sendForm = false
                    window.scroll(0, 0)
                    this.errors = e.response.data.errors
                })
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLItems', 2)
                .then((r) => {
                    if (r.indexOf('user-edit') !== -1) {
                        this.viewPage = true

                        if (r.indexOf('user-create') !== -1) {
                            this.create = true
                        }
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        passwordToAlter() {
            this.updatePassword = !this.updatePassword
            this.formData.updatepassword = this.updatePassword
        },
    },

    computed: {
        selectActiveInative() {
            return this.$store.state.var.selectActiveInative
        },

        selectSexo() {
            return this.$store.state.var.selectSexo
        },

        selectUserGroup() {
            return this.$store.state.var.selectUserGroup
        },
    }
}
</script>

<style scoped>
.form-check-input:checked {
    background-color: #000000;
    border-color: #000000;
}

.form-check-input:focus {
    border-color: #000000;
}

.form-switch .form-check-input {
    width: 3em;
    height: 2em;
}
</style>