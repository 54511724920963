import AdminComponent from "@/components/dashboard/AdminComponent";

import SubcategorySearch from "@/views/search/SubcategorySearch";
import SubcategoryCreate from "@/views/create/SubcategoryCreate";
import SubcategoryUpdate from "@/views/update/SubcategoryUpdate";

export default {
    path: "/subcategory",
    meta: {auth: true},
    component: AdminComponent,
    children: [
        {path: "search", name: "subcategory-search", component: SubcategorySearch},
        {path: "create", name: "subcategory-create", component: SubcategoryCreate},
        {path: "edit/:id", name: "subcategory-edit", component: SubcategoryUpdate, props: true},
    ]
}