import {createApp} from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import axios from "axios";
import { VMoney } from "v-money";  // Adicione esta linha


import Toast from "vue-toastification";
import "vue-toastification/dist/index.css"

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

import "@/assets/css/main.css"
import "@/assets/icons/icon.min.css"

import "@vueform/multiselect/themes/default.css"

import {NAME_TOKEN} from "@/configs/constants";

const tokenAccess = localStorage.getItem(NAME_TOKEN)
if (tokenAccess) axios.defaults.headers.common['Authorization'] = `Bearer ${tokenAccess}`;

if (process.env.NODE_ENV === "production") axios.defaults.baseURL = "https://system.regallisemijoias.com.br/"
else axios.defaults.baseURL = "https://regallisemijoias.d7mmarketing.com.br/"

axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["Accept"] = "application/json";

createApp(App).use(store).use(router).use(Toast).directive('money', VMoney).mount('#app')

store.dispatch('loginCheck')
    .catch(() => store.dispatch('logout'))