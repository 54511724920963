import axios from "axios";
import {URL_BASE} from "@/configs/constants";

// Mudar rota
const RESOURCE = 'historic-sales';

export default {
    loadSalesHistorics(context, params) {
        context.commit('LOADER', true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}${RESOURCE}`, {params})
                .then(response => {
                    context.commit('LOAD_SALES_HISTORIC', response.data)
                    return resolve(response.data)
                }).catch(errors => {
                return reject(errors)
            }).finally(() => context.commit('LOADER', false))
        })
    },

    loadSalesHistoric(context, id) { // Ensure parameter name is 'id'
        context.commit('LOADER', true);
        return new Promise((resolve, reject) => {
          axios.get(`${URL_BASE}historic-sale-show/${id}`) // Use `${id}` here
            .then((response) => {
              context.commit('LOAD_SALES_HISTORIC', response.data) 
              return resolve(response.data)
            })
            .catch((e) => reject(e))
            .finally(() => context.commit('LOADER', false));
        });
      }
}