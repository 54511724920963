import AdminComponent from '@/components/dashboard/AdminComponent'
import GenerateNFSearch from "@/views/search/GenerateNFSearch.vue";
import GenerateNFCreate from "@/views/create/GenerateNFCreate.vue";
import GenerateNFUpdate from "@/views/update/GenerateNFUpdate.vue";

export default {
    path: '/nf-generate',
    component: AdminComponent,
    meta: {auth: true},
    children: [
        {path: 'search', name: 'nf-generate-search', component: GenerateNFSearch},
        {path: 'create', name: 'nf-generate-create', component: GenerateNFCreate},
        {path: 'edit/:id', name: 'nf-generate-edit', component: GenerateNFUpdate, props: true},
    ]
}