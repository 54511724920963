import axios from "axios";
import {URL_BASE} from "@/configs/constants";

const RESOURCE = "group";
export default {
    loadUserGroups(context, params) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}${RESOURCE}`, {params})
                .then((response) => {
                    context.commit("USER_GROUP", response.data)
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => context.commit("LOADER", false))
        })
    },

    storeUserGroup(context, formData) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE}${RESOURCE}`, formData)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => context.commit("LOADER", false))
        })
    },

    loadUserGroup(context, id) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}${RESOURCE}/${id}`)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => context.commit("LOADER", false))
        })
    },

    updateUserGroup(context, formData) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE}${RESOURCE}/${formData.id}`, formData)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => context.commit("LOADER", false))
        })
    },

    destroyUserGroup(context, id) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.delete(`${URL_BASE}${RESOURCE}/${id}`)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => context.commit("LOADER", false))
        })
    }
}