export default {
    UPDATE_NAME_PAGE(state, name) {
        state.namePage = name
    },

    PERMISSIONS(state, items) {
        state.permissionsPage = items
    },

    SELECT_USER_GROUP(state, items) {
        state.selectUserGroup = items
    },

    SELECT_CATEGORY(state, items) {
        state.selectCategory = items
    },

    SELECT_SUBCATEGORY(state, items) {
        state.selectSubcategory = items
    },

    SELECT_PRODUCT(state, items) {
        state.selectProduct = items
    },

    SELECT_PRODUCT_CATEGORY(state, items) {
        state.selectProductCategory = items
    },

    UPDATE_ACCOUNT_BANK_LIST(state, name) {
        state.selectAccountBankList = name
    },

    UPDATE_ACCOUNT_TYPE_LIST(state, name) {
        state.selectAccountList = name
    },

    UPDATE_ACCOUNT_TYPE_SUB_LIST(state, name) {
        state.selectAccountSubList = name
    },


}