import AdminComponent from '@/components/dashboard/AdminComponent'
import BoxHistoricSearch from "@/views/search/BoxHistoricSearch.vue";
import BoxHistoricShow from "@/views/show/BoxHistoricShow.vue";

export default {
    path: '/pdv-box-historic',
    component: AdminComponent,
    meta: {auth: true},
    children: [
        {path: 'search', name: 'pdv-box-historic-search', component: BoxHistoricSearch},
        {path: 'show/:id', name: 'pdv-box-historic-show', component: BoxHistoricShow, props:true},
    ]
}