<template>

  <div class="painel p-4 pt-5">
  <div class="flag"
       :class="[{'entrance-color' : formData.type === '1', 'exit-color' : formData.type === '2'}]">
    <i :class="[{'arrow alternate circle up outline icon' :formData.type === '1', 'arrow alternate circle down outline icon' :formData.type === '2'}]"></i>
    {{ statusEntranceExit(formData.type) }}
    <div role="button" @click="this.$emit('close')" class="btn-close"></div>
  </div>

  <div class="mt-4">
    <row-component>
      <col-component :class-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
        <div class="d-flex align-items-start flex-column">
          <div class="d-flex">
            <p class="my-0">Criado por:</p>
            <img v-if="formData.photo_user" class="avatar__image mx-2" :src="formData.photo_user" alt="">
            <img v-else class="avatar__image mx-2" src="@/assets/avatar.png" alt="">
            {{ formData.name_user }}
          </div>
          <p>Código: {{ formData.code }}</p>
        </div>
      </col-component>

      <col-component :class-col="'col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12'">
        <div class="d-flex align-items-center">
          <p class="d-inline-block mx-3">Data de Criação: <br> <i class="calendar alternate icon"></i>
            {{ formData.date_create }}</p>
          <p class="d-inline-block mx-3">Horário de criação: <br> <i class="clock icon"></i>
            {{ formData.hour_create }}</p>
        </div>
      </col-component>

      <col-component :class-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
        <div class="d-flex align-items-center">
          <p class="d-inline-block mx-3">Valor: <br> <i class="money bill alternate icon"></i>
            {{ formData.price }}</p>
        </div>
      </col-component>
      <hr>
    </row-component>

    <row-component>
      <col-component :class-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
        <label>Conta Bancária:</label>
        <MultiSelect label="name" v-model="formData.bank_accounts_id" :options="selectAccountBankList"
                     :searchable="true"
                     :noOptionsText="noOptionsText"
                     :noResultsText="noResultsText"/>
      </col-component>

      <col-component :class-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
        <label>Vencimento:</label>
        <input-component v-mask="'##/##/####'" :type-input="'text'" v-model="formData.due_date"/>
      </col-component>

      <col-component :class-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
        <label>Data de Efetivação:</label>
        <input-component v-mask="'##/##/####'" :type-input="'text'" v-model="formData.effective_date"/>
      </col-component>
      <hr>
    </row-component>

    <row-component>
      <col-component :class-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
        <label>Tipo de Conta:</label>
        <MultiSelect v-model="formData.account_types_id" :options="selectAccountList"
                     :searchable="true"
                     :noOptionsText="noOptionsText"
                     :noResultsText="noResultsText"
                     @select="selectSubTypeAccount(formData.account_types_id)"
        />
      </col-component>

      <col-component :class-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
        <label>Sub Conta:</label>
        <MultiSelect v-model="formData.account_type_sub_id" :options="selectAccountSubList"
                     :searchable="true"
                     :noOptionsText="noOptionsText"
                     :noResultsText="noResultsText"/>
      </col-component>

      <col-component :class-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
        <label>Valor Pago:</label>
        <input-component v-money="money" :type-input="'text'" v-model="formData.price_paid"/>
      </col-component>


    </row-component>

    <row-component>
      <col-component :class-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
        <label>Observação:</label>
        <text-area :rows="4" v-model="formData.obs"/>
      </col-component>
    </row-component>

    <row-component>
      <col-component :class-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
        <button-submit @click="submit" :icon="'arrow alternate circle up outline'" name="Baixar" v-if="formData.type==1"/>
        <button-submit @click="submit" :icon="'arrow alternate circle down outline'" name="Baixar" v-else/>
      </col-component>
    </row-component>
  </div>
  </div>

</template>

<style scoped>
.painel {
  position: relative;
  background: #FFFFFF;
  width: 50%;
}

.btn-close {
  top: 10px;
  right: 10px;
  position: absolute;
}

h1 {
  font-size: 20px;

  padding: 10px 50px;
  text-transform: uppercase;
}

.avatar__image {
  width: 25px;
}

.flag {
  top: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  color: #FFFFFF;
  font-weight: bold;
  position: absolute;
}

.flag i {
  font-size: 18px;
}

.entrance-color {
  background-color: #16ab39;
}

.exit-color {
  background-color: #db2828;
}

.btn-close {
  float: right;
  cursor: pointer;
}


</style>
<script >

import RowComponent from "@/components/row/RowComponent.vue";
import {MONEY} from "@/configs/constants";
import {VMoney} from "v-money";
import {mask} from "vue-the-mask";
import ColComponent from "@/components/row/ColComponent.vue";
import InputComponent from "@/components/form/InputComponent.vue";
import ButtonSubmit from "@/components/buttons/ButtonSubmit.vue";
import {useToast} from "vue-toastification";
const toast = useToast()
import MultiSelect from "@vueform/multiselect";
import TextArea from "@/components/form/TextArea.vue";
export default {
  name:"EntranceAndExitPreviewLowModal ",
  components: {
    TextArea,
    ButtonSubmit,
    InputComponent,
    ColComponent,
    RowComponent,
    MultiSelect
  },

  props: {
    id: {
      required: true
    }
  },


  directives:{
    mask,
    money: VMoney
  },

  created() {
    this.loadEntranceExitPreview()
  },


  data() {
    return {


      money: MONEY,

      formData: {
        code: '',
        type: '',
        type_preview: '',
        bank_accounts_id: '',
        account_types_id: '',
        due_date: '',
        price: '',
        price_paid: '',
        obs: '',
        business: '',
        user_id: '',
        user_name: '',
        account_type_sub: '',
        account_type_sub_id: '',
        effective_date: '',
        attended: '',
        photo_user: '',
        name_user: '',
        date_create: '',
        hour_create: '',
        account_types_name: '',
        bank_accounts_name: '',
        bank_accounts_photo: ''
      },
    }
  },


  methods: {
    loadEntranceExitPreview() {
      this.$store.dispatch('loadEntranceExitPreview', this.id)
          .then((r) => {
            this.formData = r
            this.loadAccountTypeSubMultiSelect()
          }).catch(() => {
        toast.error('Erro desconhecido', {timeout: 2000})
      })
    },

    submit() {
      this.$store.dispatch('lowEntranceExitPreview', this.formData)
          .then(() => {
            this.$emit('close')
            toast.success("Baixa atualizado com sucesso", {timeout: 2000});
          }).catch((err) => {
            let errorMessage = "Ocorreu um erro inesperado.";
            if (err.response && err.response.data && err.response.data.errors) {
            // Acessar a mensagem de erro dentro do array
            const errorsArray = err.response.data.errors;
            if (Array.isArray(errorsArray) && errorsArray.length > 0) {
              errorMessage = errorsArray[0][0] || "Erro desconhecido";
            }
          }
          toast.error(errorMessage, { timeout: 10000 });
      })
    },

    loadAccountTypeSubMultiSelect() {
      this.$store.dispatch('loadAccountTypeSubMultiSelect', this.formData.account_types_id)
    },

    statusEntranceExit(value) {
      if (parseInt(value) === 1)
        return 'Entrada'
      return 'Saída'
    },

    selectSubTypeAccount(id) {
      this.formData.account_type_sub_id =''
      this.$store.dispatch('loadAccountTypeSubMultiSelect', id)
    },

  },


  computed: {


    noResultsText() {
      return this.$store.state.var.noResultsText
    },

    noOptionsText() {
      return this.$store.state.var.noOptionsText
    },



    selectAccountBankList() {
      return this.$store.state.var.selectAccountBankList
    },

    selectAccountList() {
      return this.$store.state.var.selectAccountList
    },

    selectYesNoList() {
      return this.$store.state.var.selectYesNoList
    },

    selectAccountSubList() {
      return this.$store.state.var.selectAccountSubList
    },



    me() {
      return this.$store.state.auth.me
    },
  }




}
</script>