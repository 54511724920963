import AdminComponent from "@/components/dashboard/AdminComponent";

import BoxTotalMoviment from "@/views/report/BoxTotalMoviment.vue";

export default {
    path: "/box-total-moviment",
    meta:{auth: true},
    component: AdminComponent,
    children: [
        {path: "search", name: "box-total-moviment-search", component: BoxTotalMoviment},
    ]
}