import state from "@/store/modules/generateNF/state";
import actions from "@/store/modules/generateNF/actions";
import mutations from "@/store/modules/generateNF/mutations";
import getters from "@/store/modules/generateNF/getters";

export default {
    state,
    actions,
    mutations,
    getters
}