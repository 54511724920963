import AdminComponent from "@/components/dashboard/AdminComponent";

import EmployeeSearch from "@/views/search/EmployeeSearch";
import EmployeeCreate from "@/views/create/EmployeeCreate.vue";
import EmployeeUpdate from "@/views/update/EmployeeUpdate";
import EmployeeUpdatePhoto from "@/views/update/EmployeeUpdatePhoto";

export default {
    path: "/employee",
    meta: {auth: true},
    component: AdminComponent,
    children: [
        {path: "search", name: "employee-search", component: EmployeeSearch},
        {path: "create", name: "employee-create", component: EmployeeCreate},
        {path: "edit/:id", name: "employee-edit", component: EmployeeUpdate, props: true},
        {path: "photo/:id", name: "employee-edit-photo", component: EmployeeUpdatePhoto, props: true},
    ]
}