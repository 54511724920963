<template>


  <painel-component :items="itemsOne">
    <form>
      <row-component>
        
        <col-component class-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
          <label>Código:</label>
          <input-component v-model="formData.code" placeholder="Código"/>
        </col-component>
        <col-component class-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
          <label>Descrição:</label>
          <input-component v-model="formData.desc" placeholder="Descrição"/>
        </col-component>

        <col-component class-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
          <label>Status:</label>
          <MultiSelect v-model="formData.status" :options="selectActiveInative" placeholder="Status"/>
        </col-component>
      </row-component>

      <row-component>
        <col-component class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
          <button-submit class="me-1" @click="submit" icon="search" name="Pesquisar"/>
          <button-route :items="itemsTwo"/>
        </col-component>
      </row-component>
    </form>

    <table class="table table-striped" v-if="viewPage">
      <thead>
      <tr>
        <th scope="col" style="width: 20%">Código</th>
        <th scope="col" style="width: 20%">Descrição</th>
        <th scope="col" style="width: 25%">Status</th>
        <th scope="col" style="width: 27%">Preço</th>
        <th scope="col" style="width: 25%">Ações</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(data, index) in object.data" :key="index">
        <td data-label="Nome">{{ data.code }}</td>
        <td data-label="Descrição">{{ (data.desc) }}</td>
        <td data-label="Status" v-if="data.status==1">Ativo</td>
        <td data-label="Status" v-else>Inativo</td>

        <td data-label="Descrição">R$ {{ (data.price) }}</td>

        <td data-label="Ações">
          <button-edit :id="data.id" route="nf-itens-edit"/>
          <button-delete @click="destroyNFItem(data)"/>
        </td>
      </tr>
      </tbody>
    </table>


    <button-pagination :pagination="object" :offset="3" @paginate="loadItems"/>
  </painel-component>

</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import ColComponent from "@/components/row/ColComponent";
import InputComponent from "@/components/form/InputComponent";
import MultiSelect from "@/components/form/MultiSelect";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import ButtonEdit from "@/components/buttons/ButtonEdit";
import ButtonDelete from "@/components/buttons/ButtonDelete.vue";
import ButtonPagination from "@/components/buttons/ButtonPagination";
import {useToast} from "vue-toastification";
import swal from "sweetalert";

const toast = useToast()
export default {
  name: "ItensNFSearch",

  components: {
    ButtonPagination,
    ButtonEdit,
    ButtonDelete,
    ButtonRoute,
    ButtonSubmit,
    MultiSelect,
    InputComponent,
    ColComponent,
    RowComponent,
    PainelComponent,

  },

  created() {
    this.$store.dispatch("changeNamePage", "Items NF")
    this.checkACL()
    this.loadItems(1)
  },

  data() {
    return {
      itemsOne: {
        title: "Pesquisar",
        icon: "list alternate"
      },

      itemsTwo: {
        route: "/nf-itens/create",
        icon: "plus",
        name: "Adicionar"
      },

      buttonCheck: {
        create: false,
        edit: false
      },

      formData: {
        status: "",
        code: "", 
        desc: "",
        price: ""
      },
      viewPage: false,
      searchSubmit: false
    }
  },

  methods: {
    submit() {
      this.loadItems(1)
      this.searchSubmit = true
    },

    loadItems() {
    if (!this.searchSubmit) {
      this.$store.dispatch('loadItensNFs', {...this.params})
        .then((r) => {
          if (this.searchSubmit) {
            this.searchSubmit = false;
            if (r.data.length === 0) {
              toast.info("Nada foi encontrado com os parâmetros informados!", {timeout: 2000});
            }
          }
        }).catch(() => {
          this.searchSubmit = false;
        });
      }
    },

    async checkACL() {
      this.$store.dispatch('ACLItems', 18)
          .then((r) => {
            if (r.indexOf('nf-itens') !== -1) {
              this.viewPage = true

              if (r.indexOf('nf-itens-search') !== -1) {
                this.viewPage = true
              }
              if (r.indexOf('nf-itens-create') !== -1) {
                this.buttonCheck.create = true
              }

              if (r.indexOf('nf-itens-edit') !== -1) {
                this.buttonCheck.edit = true
              }
            } else {
              this.$router.push({name: 'home'})
            }
          }).catch(() => {
        this.$router.push({name: 'home'})
      })
    },

    destroyNFItem(data) {
      swal({
        title: 'Atenção!',
        text: `Deseja realmente deletar?`,
        closeOnClickOutside: false,
        buttons: {
          cancel: {
            text: 'Não',
            visible: true,
            closeModal: true,
          },
          confirm: "Sim"
        }
      }).then((result) => {
        if (result) {
          this.isLoading = true;
          this.$store.dispatch('destroyItensNF', data.id)
          .then(() => {
            toast.success('Cadastro deletado com sucesso', {timeout: 2000});
            this.loadItems()
          })
          .catch(() => {
            toast.error(`Não foi possivel deletar  ${data.code} `, {timeout: 2000});
            })
          }
        })
    },


    statusString(value) {
      if (parseInt(value) === 1)
        return "Ativo"
      return "Inativo"
    }
  },

  computed: {
    selectActiveInative() {
      return this.$store.state.var.selectActiveInative
    },

    object() {
      return this.$store.state.nf_itens.items
    },
    

    params() {
      return {
        code: this.formData.code,
        desc: this.formData.desc,
        status: this.formData.status
      }
    }
  }
}
</script>