<template>
    <div id="layoutPrint">
    <div id="main-box" class="main-box">
        <vue3-barcode :value="formData.code" :height="17" :width="0.7" :fontSize="10"/>

        <div class="one">
            <p>{{ formData.code }}</p>
            <p>{{ formData.kilo }}g</p>
        </div>

        <p>{{ formData.name }}</p>

        <p>R$ {{ formData.price }}</p>
    </div>
    </div>
</template>

<script>
import Vue3Barcode from 'vue3-barcode'

export default {
    components: {
        Vue3Barcode,
    },

    props: {
        id: {
            required: true
        },

        amount: {
            default: 1,
            required: true
        },
    },

    created() {
        this.loadItems()
    },

    mounted() {
        document.body.style.width = "8.5in"
        document.body.style.background = "#e8e8e8"
        document.body.style.margin = "0.5in .1875in;"
    },

    data() {
        return {
            formData: {
                name: "",
                price: "",
                code: "",
                kilo: "",
            },
        }
    },

    methods: {
        loadItems() {
            this.$store.dispatch('loadProduct', this.id)
                .then((response) => {
                    this.formData = response
                    this.clonarDiv()
                })
        },

        clonarDiv() {
            setTimeout(() => {
              let main_box = document.querySelector('#main-box');

                for (let i = 1; i < this.amount; i++) {
                   let new_main_box = main_box.cloneNode(true);
                     let app = document.querySelector("#layoutPrint")
                     app.appendChild(new_main_box);
                }
            }, 1000)
        }
    },
}
</script>

<style scoped>

#layoutPrint {
  max-width: 80mm;
}
.main-box {
    border: 1px dotted #000;
    width: 36mm;
    height: 25mm;
    margin: 0.3rem;
    background: #FFF;
    padding: 0 0.4rem;
    display: inline-block;
}

p {
    margin: 0 0 0.1rem;
    font-size: 9px;
}

svg {
    margin: 0;
}

.one {
    display: flex;
    font-weight: bold;
    justify-content: space-between;
}

p:last-child {
    font-weight: bold;
    text-align: center;
}
</style>

