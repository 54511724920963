import AdminComponent from "@/components/dashboard/AdminComponent";

import CommerceClientSearch from "@/views/search/CommerceClientSearch";
import CommerceClientShow from "@/views/show/CommerceClientShow";

export default {
    path: "/e-commerce-client",
    meta: {auth: true},
    component: AdminComponent,
    children: [
        {path: "search", name: "commerce-client-search", component: CommerceClientSearch},
        {path: "show/:id", name: "commerce-client-show", component: CommerceClientShow, props: true},
    ]
}