import AdminComponent from "@/components/dashboard/AdminComponent";

import UserSearch from "@/views/search/UserSearch";
import UserCreate from "@/views/create/UserCreate";
import UserUpdate from "@/views/update/UserUpdate";
import UserUpdatePhoto from "@/views/update/UserUpdatePhoto";
import UserUpdatePassword from "@/views/update/UserUpdatePassword";

export default {
    path: "/user",
    meta: {auth: true},
    component: AdminComponent,
    children: [
        {path: "search", name: "user-search", component: UserSearch},
        {path: "create", name: "user-create", component: UserCreate},
        {path: "edit/:id", name: "user-edit", component: UserUpdate, props: true},
        {path: "photo", name: "user-edit-photo", component: UserUpdatePhoto},
        {path: "password", name: "user-edit-password", component: UserUpdatePassword},
    ]
}