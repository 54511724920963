import AdminComponent from "@/components/dashboard/AdminComponent";

import ConsignedSearch from "@/views/search/ConsignedSearch";
import ConsignedCreate from "@/views/create/ConsignedCreate";
import ConsignedUpdate from "@/views/update/ConsignedUpdate";

export default {
    path: "/consigned",
    meta:{auth:true},
    component: AdminComponent,
    children: [
        {path: "search", name: "consigned-search", component: ConsignedSearch},
        {path: "create", name: "consigned-create", component: ConsignedCreate},
        {path: "edit/:id", name: "consigned-edit", component: ConsignedUpdate, props: true},
        {path: "delete", name: "consigned-delete", component: ConsignedCreate},
    ]
}