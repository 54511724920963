<template>
    <painel-component :items="itemsOne">
        <form>
            <row-component>
                <col-component class-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>Email:</label>
                    <input-component v-model="formData.email" disabled/>
                </col-component>

                <col-component class-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>CEP:</label>
                    <input-component v-mask="'#####-###'" v-model="formData.cep" disabled/>
                </col-component>

                <col-component class-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>Último Acesso:</label>
                    <input-component v-model="formData.ultimo_acesso" disabled/>
                </col-component>
            </row-component>

            <row-component>
                <col-component class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <label>IP:</label>
                    <input-component v-model="formData.endereco_ip" disabled/>
                </col-component>

                <col-component class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <label>Total:</label>
                    <input-component v-model="formData.total" disabled/>
                </col-component>
            </row-component>

            <row-component>
                <col-component class-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <button-route class="w-100" :items="itemsTwo"/>
                </col-component>
            </row-component>
        </form>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import ColComponent from "@/components/row/ColComponent";
import InputComponent from "@/components/form/InputComponent";
import ButtonRoute from "@/components/buttons/ButtonRoute";

import {mask} from "vue-the-mask";
import {useToast} from "vue-toastification";

const toast = useToast()
export default {
    name: "CommerceCartShow",
    components: {
        ButtonRoute,
        InputComponent,
        ColComponent,
        RowComponent,
        PainelComponent
    },

    directives: {
        mask
    },

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch("changeNamePage", "Carrinho Abandonado")
        this.loadItems()
    },

    data() {
        return {
            itemsOne: {
                icon: "eye",
                title: "Ver"
            },

            itemsTwo: {
                icon: "reply all",
                name: "Voltar",
                route: "/e-commerce-cart/search"
            },

            formData: {
                cep: "",
                email: "",
                endereco_ip: "",
                ultimo_acesso: "",
                total: "",
            }
        }
    },

    methods: {
        loadItems() {
            this.$store.dispatch('loadCommerceCart', this.id)
                .then((r) => {
                    this.completeForm(r.registros)
                }).catch(() => {
                toast.error('Erro desconhecido!', {timeout: 1500})
            })
        },

        completeForm(data) {
            this.formData.email = data.email
            this.formData.cep = data.cep
            this.formData.endereco_ip = data.endereco_ip
            this.formData.total = data.total.toLocaleString('pt-br', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });
            this.formData.ultimo_acesso = this.formatDate(data.ultimo_acesso)
        },

        formatDate(value) {
            let date = value.split(" ")[0]
            let hour = value.split(" ")[1]
            date = date.split("-")
            return `${date[2]}/${date[1]}/${date[0]} - ${hour}`
        },
    }
}
</script>