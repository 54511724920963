<template>
    <painel-component :items="itemsOne">

        <form>
            <row-component>
                <col-component class-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>Página:</label>
                    <MultiSelect v-model="formData.pagina" :options="selectPage" placeholder="Página"/>
                </col-component>


            </row-component>
            <row-component>
                <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mt-4">
                    <button-submit @click="submit" icon="search" name="Pesquisar"/>
                </col-component>
            </row-component>
        </form>

        <table class="table table-striped" v-if="viewPage">
            <thead>
            <tr>
                <th scope="col" style="width: 18%">Email</th>
                <th scope="col" style="width: 18%">CEP</th>
                <th scope="col" style="width: 18%">IP</th>
                <th scope="col" style="width: 18%">Último Acesso</th>
                <th scope="col" style="width: 18%">Total</th>
                <th scope="col" style="width: 10%">Ações</th>
            </tr>
            </thead>

            <tbody>
            <tr v-for="(data, index) in object.registros" :key="index">
                <td data-label="Email">{{ data.email }}</td>
                <td data-label="CEP">{{ formatCep(data.cep) }}</td>
                <td data-label="IP">{{ data.endereco_ip }}</td>
                <td data-label="Último Acesso">{{ formatDate(data.ultimo_acesso) }}</td>
                <td data-label="Total">R$ {{ formatMoney(data.total) }}</td>
                <td data-label="Ações">
                    <button-show :id="data.id" route="commerce-cart-show"/>
                </td>
            </tr>
            </tbody>
        </table>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import ColComponent from "@/components/row/ColComponent";
import MultiSelect from "@/components/form/MultiSelect";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ButtonShow from "@/components/buttons/ButtonShow";

import {useToast} from "vue-toastification";

const toast = useToast()
export default {
    name: "CommerceCartSearch",
    components: {
        ButtonShow,
        ButtonSubmit,
        MultiSelect,
        ColComponent,
        RowComponent,
        PainelComponent
    },

    created() {
        this.$store.dispatch("changeNamePage", "Carrinho Abandonado")
        this.loadItems(1)
        this.checkACL()
    },

    data() {
        return {
            itemsOne: {
                icon: "list alternate",
                title: "Pesquisar"
            },

            formData: {
                pagina: ""
            },

            viewPage: false,
            totalPage: "",
        }
    },

    methods: {
        submit() {
            this.loadItems(1)
            this.searchSubmit = true
        },

        loadItems(page) {
            if (!this.searchSubmit) {
                this.$store.dispatch('loadCommerceCarts', {...this.params, page})
                    .then((r) => {
                        if (this.searchSubmit) {
                            this.searchSubmit = false;
                            if (r.registros.length === 0) {
                                toast.info("Nada foi encontrado com os parâmetros informados!", {timeout: 2000});
                            }
                        }
                        this.totalPage = r.totalPaginas
                        this.pagesSelect()
                    }).catch(() => {
                    this.searchSubmit = false;
                });
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLItems', 16)
                .then((r) => {
                    if (r.indexOf('bw-abandoned-cart') !== -1) {
                        this.viewPage = true

                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        pagesSelect() {
            const pageArr = [];
            for (let i = 0; i < this.totalPage; i++) {
                pageArr.push({
                    label: `Página ${i + 1}`,
                    value: i + 1
                });
            }

            return pageArr;
        },

        formatDate(value) {
            let date = value.split(" ")[0]
            let hour = value.split(" ")[1]
            date = date.split("-")
            return `${date[2]}/${date[1]}/${date[0]} - ${hour}`
        },

        formatMoney(value) {
            return value.toLocaleString('pt-br', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });
        },

        formatCep(value) {
            if (value) {
                return value.replace(/\D/g, '')
                    .replace(/(\d{5})(\d)/, '$1-$2')
                    .replace(/(-\d{3})\d+?$/, '$1')
            }
        }
    },

    computed: {
        selectPage(){
            return this.pagesSelect()
        },

        object() {
            return this.$store.state.e_commerce_cart.items
        },

        params() {
            return {
                pagina: this.formData.pagina
            }
        }
    }
}
</script>