import axios from "axios";
import {URL_BASE} from "@/configs/constants";

// Mudar rota
const RESOURCE = 'historic-boxes';

export default {
    loadBoxHistorics(context, params) {
        context.commit('LOADER', true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}${RESOURCE}`, {params})
                .then(response => {
                    context.commit('LOAD_BOX_HISTORIC', response.data)
                    return resolve(response.data)
                }).catch(errors => {
                return reject(errors)
            }).finally(() => context.commit('LOADER', false))
        })
    },


    loadBoxHistoric(context, id) {
        context.commit('LOADER', true);
        return new Promise((resolve, reject) => {
          axios.get(`${URL_BASE}historic-box-show/${id}`)
            .then(response => resolve(response.data))
            .catch((e) => reject(e))
            .finally(() => context.commit('LOADER', false));
        });
      }
}