<template>
  <painel-component :items="itemsOne">
    <error-component :errors="errors" v-if="showError"/>

    <form>
      <row-component>
        <col-component :class-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'"
                           :class="{'formError' : errors.name}" >
          <label>Nome:</label>
          <input-component :type-input="'text'" v-model="formData.name"/>
        </col-component>

        <col-component :class-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'"
                           :class="{'formError' : errors.balance}">
          <label>Saldo:</label>
          <input-component v-money="MONEY" :type-input="'text'" v-model="formData.balance"/>
        </col-component>

        <col-component :class-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'"
                           :class="{'formError' : errors.status}">
          <label>Status:</label>
          <MultiSelect v-model="formData.status" :options="selectActiveInative" :searchable="true"
                       :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
        </col-component>
      </row-component>

      <row-component>
        <col-component :class-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                           :class="{'formError' : errors.bank}">
          <label>Banco:</label>
          <MultiSelect v-model="formData.bank" :options="selectBankList" :searchable="true"
                       :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
        </col-component>

        <col-component :class-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                           :class="{'formError' : errors.account_type}">
          <label>Tipo de Conta:</label>
          <MultiSelect v-model="formData.account_type" :options="selectAccountTypeList" :searchable="true"
                       :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
        </col-component>

        <col-component :class-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                           :class="{'formError' : errors.agency}">
          <label>Agência:</label>
          <input-component :type-input="'text'" v-model="formData.agency"/>
        </col-component>

        <col-component :class-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                           :class="{'formError' : errors.digit_account}">
          <label>Conta/Dígito:</label>
          <input-component :type-input="'text'" v-model="formData.digit_account"/>
        </col-component>
      </row-component>

      <row-component>
        <col-component
            :class-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
            :class="{'formError' : errors.pix_type}">
          <label>Tipo de PIX:</label>
          <MultiSelect v-model="formData.pix_type" :options="selectPIXList"
                       :searchable="true"
                       :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
        </col-component>

        <col-component v-if="formData.pix_type === 1"
                           :class-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'"
                           :class="{'formError' : errors.key_pix}">
          <label>Chave:</label>
          <input-component v-mask="['###.###.###-##','##.###.###/####-##']" :type-input="'text'"
                           v-model="formData.key_pix"/>
        </col-component>

        <col-component v-else-if="formData.pix_type === 2"
                           :class-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'"
                           :class="{'formError' : errors.key_pix}">
          <label>Chave:</label>
          <input-component v-mask="['(##) ####-####','(##) #####-####']" :type-input="'text'"
                           v-model="formData.key_pix"/>
        </col-component>

        <col-component v-else-if="formData.pix_type === 3"
                           :class-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'"
                           :class="{'formError' : errors.key_pix}">
          <label>Chave:</label>
          <input-component :type-input="'email'" v-model="formData.key_pix"/>
        </col-component>

        <col-component v-else-if="formData.pix_type === 4"
                           :class-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'"
                           :class="{'formError' : errors.key_pix}">
          <label>Chave:</label>
          <input-component :type-input="'text'" v-model="formData.key_pix"/>
        </col-component>

        <col-component v-else
                           :class-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'"
                           :class="{'formError' : errors.key_pix}">
          <label>Chave:</label>
          <input-component :type-input="'text'" v-model="formData.key_pix"/>
        </col-component>
      </row-component>

      <row-component>
        <col-component :class-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                           :class="{'formError' : errors.obs}">
          <label>Observações:</label>
          <text-area :rows="4" v-model="formData.obs"></text-area>
        </col-component>
      </row-component>

      <row-component>
        <col-component
            :class-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mt-4'">
          <label>Conta principal?</label>

          <input checked v-model="formData.main_account" type="radio" name="docs" id="main-account-no"
                 value="1">
          <label for="main-account-no">Não</label>

          <input v-model="formData.main_account" type="radio" name="docs" id="main-account-yes" value="2">
          <label for="main-account-yes">Sim</label>
        </col-component>

        <col-component
            :class-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mt-4'">
          <label>Permitir saldo negativo?</label>

          <input checked v-model="formData.allow_negative_balance" type="radio" name="docs-two"
                 id="allow-negative-no"
                 value="1">
          <label for="allow-negative-no">Não</label>

          <input v-model="formData.allow_negative_balance" type="radio" name="docs-two"
                 id="allow-negative-yes"
                 value="2">
          <label for="allow-negative-yes">Sim</label>
        </col-component>
      </row-component>



      <row-component>
        <col-component class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
          <button-submit class="w-100" :disabled="sendForm" @click="submit" icon="plus" name="Adicionar"/>
        </col-component>

        <col-component class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
          <button-route class="w-100" :items="itemsTwo"/>
        </col-component>
      </row-component>
    </form>
  </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import ColComponent from "@/components/row/ColComponent";
import InputComponent from "@/components/form/InputComponent";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import ErrorComponent from "@/components/alerts/ErrorComponent";
import MultiSelect from "@/components/form/MultiSelect";
import TextArea from "@/components/form/TextArea.vue";
import {useToast} from "vue-toastification";
import {VMoney} from "v-money"
import {MONEY} from "@/configs/constants";
import {mask} from "vue-the-mask"


const toast = useToast()
export default {
  name: "AccountBankCreate",

  components: {
    TextArea,
    ErrorComponent,
    ButtonRoute,
    ButtonSubmit,
    InputComponent,
    ColComponent,
    RowComponent,
    PainelComponent,
    MultiSelect
  },

  directives: {
    money: VMoney,
    mask
  },


  created() {
    this.$store.dispatch("changeNamePage", "Conta Bancária")
    this.checkACL()
  },

  data() {
    return {
      itemsOne: {
        title: "Adicionar",
        icon: "pen square"
      },

      itemsTwo: {
        route: "/account-bank/search",
        icon: "reply all",
        name: "Voltar"
      },

      formData: {
        name: '',
        bank: '',
        balance: '',
        account_type: '',
        agency: '',
        digit_account: '',
        pix_type: '',
        key_pix: '',
        allow_negative_balance: 1,
        main_account: 1,
        status: '',
        obs: ''
      },

      errors: [],
      viewPage: false,
      sendForm: false,
      showError: false,


      MONEY,


    }
  },

  methods: {
    submit() {
      if (!this.sendForm) {
        this.sendForm = true
        this.$store.dispatch('storeAccountBank', this.formData)
            .then((r) => {
              this.showError = false
              this.sendForm = false
              this.errors = []
              toast.success('Cadastro efetuado com sucesso', {timeout: 1500})
              console.log(r);
              this.$router.push({name: 'account-bank-edit', params: {id: r}})
            }).catch((e) => {
          this.showError = true
          this.sendForm = false
          window.scroll(0, 0)
          this.errors = e.response.data.errors
        })
      }
    },

    async checkACL() {
      this.$store.dispatch('ACLItems', 5)
          .then((r) => {
            if (r.indexOf('account-bank-create') !== -1) {
              this.viewPage = true
            } else {
              this.$router.push({name: 'home'})
            }
          }).catch(() => {
        this.$router.push({name: 'home'})
      })
    },
  },

  computed: {
    selectActiveInative() {
      return this.$store.state.var.selectActiveInative
    },

    noResultsText() {
      return this.$store.state.var.noResultsText
    },

    noOptionsText() {
      return this.$store.state.var.noOptionsText
    },

    selectAccountTypeList() {
      return this.$store.state.var.selectAccountType
    },

    selectBankList() {
      return this.$store.state.var.selectBankList
    },

    selectPIXList() {
      return this.$store.state.var.selectPIX
    }


  }
}
</script>