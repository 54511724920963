export default {
    icon: "box",
    name: "Estoque",
    subLinks: [
        {
            name: "Categoria",
            route: "/category/search",
            pagePermissions: [
                "category-search",
                "category-create",
                "category-edit",
            ],
        },
        {
            name: "Subcategoria",
            route: "/subcategory/search",
            pagePermissions: [
                "subcategory-search",
                "subcategory-create",
                "subcategory-edit",
            ],
        },
        {
            name: "Produto",
            route: "/product/search",
            pagePermissions: [
                "product-search",
                "product-create",
                "product-edit",
            ],
        },

        {
            name: "Entrada / Saída",
            route: "/inventory/search",
            pagePermissions: [
                "inventorie-search",
                "inventorie-create"
            ],
        },
    ],

    pageNavCheck: [
        "category-search",
        "category-create",
        "category-edit",
        "subcategory-search",
        "subcategory-create",
        "subcategory-edit",
        "inventorie-search",
        "inventorie-create",
        "product-search",
        "product-create",
        "product-edit",
    ]
}