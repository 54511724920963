export default {
  icon: "chart line",
  name: "Relatório",
  subLinks: [
    {
        name: "Produtos",
        route: "/report-products/search",
        pagePermissions: [
            "report-all"
        ],
    },
    {
        name: "Produtos (Entrada/Saída)",
        route: "/report-products-entrance-exit/search",
        pagePermissions: [
          "report-all"
        ],
    },
    
    { 
      name: "Produtos com foto",
      route: "/products-photo/search",
      pagePermissions: [
        "report-all"
      ],
    },
    {
      name: "Email Clientes",
      route: "/clients-emails/search",
      pagePermissions: [
        "report-all"
      ],
    },
    {
      name: "Telefone Clientes",
      route: "/clients-phones/search",
      pagePermissions: [
        "report-all"
      ],
    },
    {
      name: "PDV Caixa (Entrada)",
      route: "/box-total-entrance/search",
      pagePermissions: [
        "report-all"
      ],
    },
    {
      name: "PDV Caixa (Saída)",
      route: "/box-total-exit/search",
      pagePermissions: [
        "report-all"
      ],
    },
    {
      name: "PDV Caixa (Movimentação)",
      route: "/box-total-moviment/search",
      pagePermissions: [
        "report-all"
      ],
    },
    { 
      name: "PDV Vendas",
      route: "/sales/search",
      pagePermissions: [
        "report-all"
      ],
    },
    { 
      name: "Financeiro Entrada (Previsão)",
      route: "/financial-entrance-preview/search",
      pagePermissions: [
        "report-all"
      ],
    },
    { 
      name: "Financeiro Saída (Previsão)",
      route: "/financial-exit-preview/search",
      pagePermissions: [
        "report-all"
      ],
    },
    { 
      name: "Financeiro entrada (Efetivado)",
      route: "/financial-entrance-effective/search",
      pagePermissions: [
        "report-all"
      ],
    },
    { 
      name: "Financeiro saída (Efetivado)",
      route: "/financial-exit-effective/search",
      pagePermissions: [
        "report-all"
      ],
    },
    { 
      name: "Contas bancárias",
      route: "/account-banck/search",
      pagePermissions: [
        "report-all"
      ],
    },

  ],
  pageNavCheck: [
      "report-all",
    ]
}

      
