<template>
  <Bar  
    :chart-options="chartOptions" 
    :chart-id="chartId" 
    :dataset-id-key="datasetIdKey" 
    :plugins="plugins"
    :css-classes="cssClasses" 
    :styles="styles" 
    :width="width" 
    :height="height"
    :data="chartData"
  />
  <!-- <pre>{{ chartData }}</pre>
  <pre>{{ chartOptions }}</pre> -->
</template>

<script>
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: 'BarChart',
  components: { Bar },
  props: {
    chartId: {
      type: String,
      default: 'bar-chart'
    },

    datasetIdKey: {
      type: String,
      default: 'label'
    },

    width: {
      type: Number,
      default: 400
    },

    height: {
      type: Number,
      default: 400
    },

    cssClasses: {
      default: '',
      type: String
    },
    
    styles: {
      type: Object,
      default: () => ({})
    },

    plugins: {
      type: Array,
      default: () => []
    },

    chartData: {
      type: Object,
      default: () => ({
        labels: [],
        datasets: []
      })
    }
  },

  data() {
    return {
      chartOptions: {
        responsive: true
      }
    }
  },
}
</script>

<style scoped>
.icon-card-entrance {
  color: rgba(22, 171, 57, 0.404);
}

.icon-card-exit {
  color: rgb(219, 40, 40);
}
</style>
