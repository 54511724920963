<template>
  <painel-component :items="itemsOne">
      <form>
          <row-component>
              <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                  <label>Nome:</label>
                  <input-component v-model="formData.name" placeholder="Nome"/>
              </col-component>

              <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                  <label>CNPJ:</label>
                  <input-component v-model="formData.cnpj" placeholder="00.000.000/0000-00"/>
              </col-component>

              <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                  <label>CPF:</label>
                  <input-component v-model="formData.cpf" placeholder="000.000.000-00"/>
              </col-component>

              <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                  <label>Status:</label>
                  <MultiSelect v-model="formData.status" :options="selectActiveInative" placeholder="Status"/>
              </col-component>
          </row-component>

          <row-component>
              <col-component class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                  <button-submit @click="submit" class="me-1" icon="search" name="Pesquisar"/>
                  <button-route v-if="buttonCheck.create" :items="itemsTwo"/>
              </col-component>
          </row-component>
      </form>

      <table class="table table-striped" v-if="viewPage">
          <thead>
          <tr>
              <th scope="col" style="width: 40%">Apelido</th>
              <th scope="col" style="width: 20%">CNPJ / CPF</th>
              <th scope="col" style="width: 15%">Status</th>
              <th scope="col" style="width: 15%" v-if="buttonCheck.edit || buttonCheck.delete">Ações</th>
          </tr>
          </thead>

          <tbody>
          <tr v-for="(data, index) in object.data" :key="index">
              <td data-label="Nome">{{ data.name }}</td>
              <td data-label="CNPJ / CPF">{{ data.cnpj }} {{ data.cpf }}</td>
              <td data-label="Status">{{ statusString(data.status) }}</td>
              <td data-label="Ações" v-if="buttonCheck.edit || buttonCheck.delete">
                  <button-edit v-if="buttonCheck.edit" :id="data.id" route="consigned-edit"/>
                  <button-delete v-if="buttonCheck.delete" @click="destroy(data)"/>
              </td>
          </tr>
          </tbody>
      </table>
      <button-pagination :pagination="object" :offset="3" @paginate="loadItems"/>
  </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import ColComponent from "@/components/row/ColComponent";
import InputComponent from "@/components/form/InputComponent";
import MultiSelect from "@/components/form/MultiSelect";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import ButtonEdit from "@/components/buttons/ButtonEdit";
import ButtonDelete from "@/components/buttons/ButtonDelete";
import ButtonPagination from "@/components/buttons/ButtonPagination";

import {useToast} from "vue-toastification";
import swal from "sweetalert";

const toast = useToast()
export default {
  name: "ConsignedSearch",

  components: {
      ButtonPagination,
      ButtonDelete,
      ButtonEdit,
      ButtonRoute,
      ButtonSubmit,
      MultiSelect,
      InputComponent,
      ColComponent,
      RowComponent,
      PainelComponent
  },

  created() {
      this.$store.dispatch("changeNamePage", "Consignado")
      this.loadItems(1)
      this.checkACL()
  },

  data() {
      return {
          itemsOne: {
              title: "Pesquisa",
              icon: "list alternate"
          },

          itemsTwo: {
              route: "/consigned/create",
              icon: "plus",
              name: "Adicionar"
          },

          buttonCheck: {
              create: false,
              edit: false,
              delete: false
          },

          formData: {
              name: "",
              cnpj: "",
              cpf: "",
              status: ""
          },

          viewPage: false,
          searchSubmit: false,
      }
  },

  methods: {
      submit() {
          this.loadItems(1)
          this.searchSubmit = true
      },

      loadItems(page) {
          if (!this.searchSubmit) {
              this.$store.dispatch('loadConsigneds', {...this.params, page})
                  .then((r) => {
                    console.log(r)
                      if (this.searchSubmit) {
                          this.searchSubmit = false;
                          if (r.data.length === 0) {
                              toast.info("Nada foi encontrado com os parâmetros informados!", {timeout: 2000});
                          }
                      }
                  }).catch(() => {
                  this.searchSubmit = false;
              });
          }
      },

      async checkACL() {
          this.$store.dispatch('ACLItems', 21)
              .then((r) => {
                  if (r.indexOf('consigned-search') !== -1) {
                      this.viewPage = true

                      if (r.indexOf('consigned-create') !== -1) {
                          this.buttonCheck.create = true
                      }

                      if (r.indexOf('consigned-edit') !== -1) {
                          this.buttonCheck.edit = true
                      }

                      if (r.indexOf('consigned-delete') !== -1) {
                          this.buttonCheck.delete = true
                      }
                  } else {
                      this.$router.push({name: 'home'})
                  }
              }).catch(() => {
              this.$router.push({name: 'home'})
          })
      },

      destroy(item) {
          swal({
              title: 'Atenção!',
              text: `Deseja realmente deletar ${item.name} ?`,
              closeOnClickOutside: false,
              buttons: {
                  cancel: {
                      text: 'Não',
                      visible: true,
                      closeModal: true,
                  },
                  confirm: "Sim"
              }
          }).then((result) => {
              if (result) {
                  this.$store.dispatch('destroyConsigned', item.id).then(() => {
                      this.loadItems(1)
                      toast.success('Cadastro deletado com sucesso', {timeout: 1500});
                  }).catch(() => {
                      toast.error(`Não foi possível deletar  ${item.name} `, {timeout: 1500});
                  })
              }
          });
      },

      statusString(value) {
          if (parseInt(value) === 1)
              return "Ativo"
          return "Inativo"
      }
  },

  computed: {
      selectActiveInative() {
          return this.$store.state.var.selectActiveInative
      },

      object() {
          return this.$store.state.consigned.items
      },

      params() {
          return {
              page: this.object.current_page,
              name: this.formData.name,
              cnpj: this.formData.cnpj,
              cpf: this.formData.cpf,
              status: this.formData.status
          }
      }
  }
}
</script>