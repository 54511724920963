import axios from "axios";

const RESOURCE = "api/v1/bw/client";

export default {
    actions: {
        loadCommerceCustomers(context, params) {
            context.commit("LOADER", true)
            return new Promise((resolve, reject) => {
                axios.get(`${RESOURCE}`, {params})
                    .then((response) => {
                        context.commit("COMMERCE_CLIENT", response.data)
                        return resolve(response.data)
                    }).catch((errors) => {
                    return reject(errors)
                }).finally(() => context.commit("LOADER", false))
            })
        },

        loadCommerceClient(context, id) {
            context.commit("LOADER", true)
            return new Promise((resolve, reject) => {
                axios.get(`${RESOURCE}/${id}`)
                    .then((response) => {
                        return resolve(response.data)
                    }).catch((errors) => {
                    return reject(errors)
                }).finally(() => context.commit("LOADER", false))
            })
        }

    },

    mutations: {
        COMMERCE_CLIENT(state, items) {
            state.items = items
        }
    },

    state: {
        items: {
            data: []
        },
    }
}