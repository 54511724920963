import AdminComponent from "@/components/dashboard/AdminComponent";

import ClientsEmails from "@/views/report/ClientsEmails.vue";

export default {
    path: "/clients-emails",
    meta:{auth: true},
    component: AdminComponent,
    children: [
        {path: "search", name: "clients-emails-search", component: ClientsEmails},
    ]
}