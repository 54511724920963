import AdminComponent from '@/components/dashboard/AdminComponent'
import ReportSales from "@/views/report/ReportSales";

export default {
    path: '/sales',
    component: AdminComponent,
    meta: {auth: true},
    children: [
        {path: 'search', name: 'sales-search', component: ReportSales},
    ]
}