import AdminComponent from "@/components/dashboard/AdminComponent";

import CategorySearch from "@/views/search/CategorySearch";
import CategoryCreate from "@/views/create/CategoryCreate";
import CategoryUpdate from "@/views/update/CategoryUpdate.vue";

export default {
    path: "/category",
    meta: {auth: true},
    component: AdminComponent,
    children: [
        {path: "search", name: "category-search", component: CategorySearch},
        {path: "create", name: "category-create", component: CategoryCreate},
        {path: "edit/:id", name: "category-edit", component: CategoryUpdate, props: true},
    ]
}