import AdminComponent from "@/components/dashboard/AdminComponent";
import HomeView from "@/views/HomeView";
import LoginView from "@/views/LoginView";

import Category from "@/router/modules/category";
import Client from "@/router/modules/client";
import ECommerceCart from "@/router/modules/e-commerce-cart";
import ECommerceClient from "@/router/modules/e-commerce-client";
import ECommerceProduct from "@/router/modules/e-commerce-product";
import ECommerceSales from "@/router/modules/e-commerce-sales";
import Employee from "@/router/modules/employee";
import HangTags from "@/router/modules/hang-tags";
import Inventory from "@/router/modules/inventory";
import Product from "@/router/modules/product";
import Subcategory from "@/router/modules/subcategory";
import Supplier from "@/router/modules/supplier";
import User from "@/router/modules/user";
import UserGroup from "@/router/modules/user-group";
import AccountBank from "@/router/modules/account-bank";
import AccountType from "@/router/modules/account-type";
import EntranceExitPreview from "@/router/modules/entrance-exit-preview";
import EntranceExit from "@/router/modules/entrance-exit";
import ItensNF from "@/router/modules/itensNF";
import generateNF from "@/router/modules/generateNF";
import ReportProduct from "@/router/modules/report-product";
import ProductReportEntranceExit from "@/router/modules/report-product-entrance-exit";
import SalesHistoric from "@/router/modules/sales-historic";
import BoxHistoric from "@/router/modules/box-historic";
import DashboardBox from "@/router/modules/dashboard-box";
import Consigned from "@/router/modules/consigned";
import ClientsEmail from "@/router/modules/clients-emails";
import ClientsPhones from "@/router/modules/phone-clients";
import BoxTotalEntrance from "@/router/modules/box-total-entrance";
import BoxTotalExit from "@/router/modules/box-total-exit";
import BoxTotalMoviment from "@/router/modules/box-total-moviment";
import FinanceEntrancePreview from "@/router/modules/financial-entrance-preview"
import FinanceExitPreview from "@/router/modules/financial-exit-preview"
import FinanceEntranceEffective from "@/router/modules/financial-entrance-effective"
import FinanceExitEffective from "@/router/modules/financial-exit-effective"
import AccountBankReport from "@/router/modules/account-banck"
import ProductsPhoto from "@/router/modules/products-photo"
import ReportSales from "@/router/modules/report-sales"




const routes = [
    {
        path: "/",
        component: AdminComponent,
        children: [
            {path: 'login', name: 'login', component: LoginView},
            { path: '/', meta: { auth: true }, component: HomeView, name: 'home' },
        ]
    },

    Category,
    Client,
    ECommerceCart,
    ECommerceClient,
    ECommerceProduct,
    ECommerceSales,
    Employee,
    generateNF,
    HangTags,
    Inventory,
    ItensNF,
    Product,
    ReportProduct,
    ProductReportEntranceExit,
    Subcategory,
    Supplier,
    User,
    UserGroup,
    AccountBank,
    AccountType,
    EntranceExitPreview,
    EntranceExit,
    SalesHistoric,
    BoxHistoric,
    DashboardBox,
    Consigned,
    ClientsEmail,
    ClientsPhones,
    BoxTotalEntrance,
    BoxTotalExit,
    BoxTotalMoviment,
    FinanceEntrancePreview,
    FinanceExitPreview,
    FinanceEntranceEffective,
    FinanceExitEffective,
    AccountBankReport,
    ProductsPhoto,
    ReportSales
];

export default routes;
