<template>
    <painel-component :items="itemsOne">
        <error-component :errors="errors" v-if="showError"/>

        <div class="tabs effect-3" v-if="viewPage">
            <input type="radio" id="tab-1" name="tab-effect-3" checked>
            <span>
                <i class="info circle icon"></i>
                <span>Informações</span>
            </span>

            <input type="radio" id="tab-2" name="tab-effect-3">
            <span>
                <i class="user circle icon"></i>
                <span>Email</span>
            </span>

            <input type="radio" id="tab-3" name="tab-effect-3">
            <span>
                <i class="envelope icon"></i>
               <span>Telefone</span>
            </span>

            <input type="radio" id="tab-4" name="tab-effect-3">
            <span>
                <i class="money bill alternate icon"></i>
                <span>Conta Bancária</span>
            </span>

            <div class="line ease"></div>
            <div class="tab-content">

                <section id="tab-item-1">
                    <form>
                        <div class="aux my-4">
                            <div class="avatar-form">
                                <img v-if="formData.photo" :src="formData.photo" alt="" class="avatar__image">
                                <img v-else src="@/assets/avatar.png" alt="" class="avatar__image">
                            </div>
                            <p>{{ formData.name }}</p>
                            <router-link :to="{ name: 'employee-edit-photo', id: this.id }" class="figure">
                                <i class="camera icon m-0"></i>
                            </router-link>
                        </div>

                        <row-component>
                            <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                           :class="{'formError' : errors.type}">
                                <div class="form-check form-check-inline">
                                    <input v-model="formData.type" class="form-check-input" type="radio" value="1"
                                           name="types" id="type-one">
                                    <label class="form-check-label" for="type-one">Funcionário</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input v-model="formData.type" class="form-check-input" type="radio" value="2"
                                           name="types" id="type-two">
                                    <label class="form-check-label" for="type-two">Prestador de Serviço</label>
                                </div>
                            </col-component>
                        </row-component>

                        <row-component>
                            <col-component class-col="col-xxl-9 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12"
                                           :class="{'formError' : errors.type}">
                                <label>Nome:</label>
                                <input-component v-model="formData.name"/>
                            </col-component>

                            <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                           :class="{'formError' : errors.sexo}">
                                <label>Sexo:</label>
                                <MultiSelect v-model="formData.sexo" :options="selectSexo"/>
                            </col-component>
                        </row-component>

                        <row-component>
                            <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                           :class="{'formError' : errors.cpf}">
                                <label>CPF:</label>
                                <input-component v-model="formData.cpf" v-mask="'###.###.###-##'"/>
                            </col-component>

                            <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                           :class="{'formError' : errors.rg}">
                                <label>RG:</label>
                                <input-component v-model="formData.rg" v-mask="['##.###.###-X', '#.###.###']"/>
                            </col-component>

                            <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                           :class="{'formError' : errors.birth_date}">
                                <label>Data Nasc:</label>
                                <input-component v-model="formData.birth_date" v-mask="'##/##/####'"/>
                            </col-component>

                            <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                           :class="{'formError' : errors.status}">
                                <label>Status:</label>
                                <MultiSelect v-model="formData.status" :options="selectActiveInative"/>
                            </col-component>
                        </row-component>

                        <row-component>
                            <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                           :class="{'formError' : errors.cep}">
                                <label>CEP:</label>
                                <input-component v-model="formData.cep" v-mask="'#####-###'"/>
                            </col-component>

                            <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                           :class="{'formError' : errors.city}">
                                <label>Cidade:</label>
                                <input-component v-model="formData.city"/>
                            </col-component>

                            <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                           :class="{'formError' : errors.state}">
                                <label>Estado:</label>
                                <input-component v-model="formData.state"/>
                            </col-component>

                            <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                           :class="{'formError' : errors.district}">
                                <label>Bairro:</label>
                                <input-component v-model="formData.district"/>
                            </col-component>
                        </row-component>

                        <row-component>
                            <col-component class-col="col-xxl-9 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12"
                                           :class="{'formError' : errors.address}">
                                <label>Endereço:</label>
                                <input-component v-model="formData.address"/>
                            </col-component>

                            <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                           :class="{'formError' : errors.country}">
                                <label>País:</label>
                                <input-component v-model="formData.country"/>
                            </col-component>
                        </row-component>

                        <row-component>
                            <col-component class-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                           :class="{'formError' : errors.complement}">
                                <label>Referência:</label>
                                <text-area v-model="formData.complement" rows="6"/>
                            </col-component>
                        </row-component>
                    </form>
                </section>

                <section id="tab-item-2">
                    <div class="clearfix mb-3">
                        <button-submit class="float-end" @click="addEmails" icon="plus"/>
                    </div>

                    <template v-for="(data, index) in emailsAdd" :key="index">
                        <form class="bg-body-secondary p-3 mb-3">
                            <row-component>
                                <col-component class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                    <label>Receber notificação:</label>

                                    <div class="form-check form-check-inline ms-2">
                                        <input v-model="data.notification"  type="radio"
                                               value="1" :id="'emailS' + index">
                                        <label class="form-check-label" :for="'emailS' + index">Sim</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input v-model="data.notification"  type="radio"
                                               value="2" :id="'emailN' + index" checked>
                                        <label class="form-check-label" :for="'emailN' + index">Não</label>
                                    </div>
                                </col-component>
                            </row-component>

                            <row-component>
                                <col-component class-col="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                                    <label>Nome:</label>
                                    <input-component v-model="data.name"/>
                                </col-component>

                                <col-component class-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                    <label>Email:</label>
                                    <input-component v-model="data.email" type="email"/>
                                </col-component>
                            </row-component>

                            <div class="clearfix" v-if="emailsAdd.length >= 2">
                                <button-delete class="float-end" @click="removeEmails(index)"/>
                            </div>
                        </form>
                    </template>
                </section>

                <section id="tab-item-3">
                    <div class="clearfix mb-3">
                        <button-submit class="float-end" @click="addPhones" icon="plus"/>
                    </div>

                    <template v-for="(data, index) in phonesAdd" :key="index">
                        <form class="bg-body-secondary p-3 mb-3">
                            <row-component>
                                <col-component class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                    <label>Receber notificação:</label>

                                    <div class="form-check form-check-inline ms-2">
                                        <input v-model="data.notification" type="radio"
                                               value="1" :id="'phoneS' + index">
                                        <label class="form-check-label" :for="'phoneS' + index">Sim</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input v-model="data.notification" type="radio"
                                               value="2" :id="'phoneN' + index" checked>
                                        <label class="form-check-label" :for="'phoneN' + index">Não</label>
                                    </div>
                                </col-component>
                            </row-component>

                            <row-component>
                                <col-component class-col="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                                    <label>Nome:</label>
                                    <input-component v-model="data.name"/>
                                </col-component>

                                <col-component class-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                    <label>Telefone:</label>
                                    <input-component v-mask="['(##) ####-####', '(##) #####-####']"
                                                     v-model="data.phone"/>
                                </col-component>
                            </row-component>

                            <div class="clearfix" v-if="phonesAdd.length >= 2">
                                <button-delete class="float-end" @click="removePhones(index)"/>
                            </div>
                        </form>
                    </template>
                </section>

                <section id="tab-item-4">
                    <div class="clearfix mb-3">
                        <button-submit class="float-end" @click="addBanks" icon="plus"/>
                    </div>

                    <template v-for="(data, index) in banksAdd" :key="index">
                        <form class="bg-body-secondary p-3 mb-3">
                            <row-component>
                                <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                                    <label>Banco:</label>
                                    <MultiSelect v-model="data.bank" :options="selectBankList"/>
                                </col-component>

                                <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                                    <label>Tipo:</label>
                                    <MultiSelect v-model="data.account_type" :options="selectAccountType"/>
                                </col-component>

                                <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                                    <label>Agência:</label>
                                    <input-component v-model="data.agency"/>
                                </col-component>

                                <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                                    <label>Conta/Dígito:</label>
                                    <input-component v-model="data.digit_account"/>
                                </col-component>
                            </row-component>

                            <row-component>
                                <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                                    <label>Tipo Pix:</label>
                                    <MultiSelect v-model="data.pix_type" :options="selectPIX"/>
                                </col-component>

                                <col-component v-if="data.pix_type === 1"
                                               class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                    <label>Chave:</label>
                                    <input-component v-model="data.key_pix"
                                                     v-mask="['##.###.###/####-##','###.###.###-##']"/>
                                </col-component>

                                <col-component v-else-if="data.pix_type === 2"
                                               class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                    <label>Chave:</label>
                                    <input-component v-model="data.key_pix"
                                                     v-mask="['(##) ####-####','(##) #####-####']"/>
                                </col-component>

                                <col-component v-else-if="data.pix_type === 3"
                                               class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                    <label>Chave:</label>
                                    <input-component type="email"/>
                                </col-component>

                                <col-component v-else-if="data.pix_type === 4"
                                               class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                    <label>Chave:</label>
                                    <input-component v-model="data.key_pix"/>
                                </col-component>

                                <col-component v-else
                                               class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                    <label>Chave:</label>
                                    <input-component v-model="data.key_pix"/>
                                </col-component>

                                <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mt-4">
                                    <label>Conta Principal:</label>

                                    <div class="form-check form-check-inline ms-0">
                                        <input v-model="data.main_account" type="radio"
                                               value="1" :id="'accountS' + index" checked>
                                        <label class="form-check-label" :for="'accountS' + index">Sim</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input v-model="data.main_account" type="radio"
                                               value="2" :id="'accountN' + index">
                                        <label class="form-check-label" :for="'accountN' + index">Não</label>
                                    </div>
                                </col-component>
                            </row-component>

                            <div class="clearfix" v-if="banksAdd.length >= 2">
                                <button-delete class="float-end" @click="removeBanks(index)"/>
                            </div>
                        </form>
                    </template>
                </section>

                <row-component>
                    <col-component class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                        <button-submit :disabled="sendForm" @click="submit" class="w-100" icon="save" name="Salvar"/>
                    </col-component>

                    <col-component class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                        <button-route class="w-100" :items="itemsTwo"/>
                    </col-component>
                </row-component>
            </div>
        </div>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import ColComponent from "@/components/row/ColComponent";
import InputComponent from "@/components/form/InputComponent";
import MultiSelect from "@/components/form/MultiSelect";
import TextArea from "@/components/form/TextArea";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ButtonDelete from "@/components/buttons/ButtonDelete";
import ButtonRoute from "@/components/buttons/ButtonRoute";

import {mask} from "vue-the-mask";
import {useToast} from "vue-toastification";
import ErrorComponent from "@/components/alerts/ErrorComponent.vue";

const toast = useToast()
export default {
    name: "EmployeeCreate",

    components: {
        ErrorComponent,
        ButtonRoute,
        ButtonDelete,
        ButtonSubmit,
        TextArea,
        MultiSelect,
        InputComponent,
        ColComponent,
        RowComponent,
        PainelComponent
    },

    directives: {
        mask
    },

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch("changeNamePage", "Funcionário")
        this.loadItems()
        this.nullArray()
        this.checkACL()
    },

    data() {
        return {
            itemsOne: {
                title: "Editar",
                icon: "edit"
            },

            itemsTwo: {
                route: "/employee/search",
                icon: "reply all",
                name: "Voltar"
            },

            itemsThree: {
                route: "/employee/create",
                icon: "plus"
            },

            create: false,

            formData: {
                name: "",
                cpf: "",
                rg: "",
                birth_date: "",
                type: 1,
                emails: [],
                phones: [],
                bank_accounts: [],
                status: "",
                sexo: "",
                cep: "",
                city: "",
                state: "",
                district: "",
                address: "",
                complement: "",
                country: "Brasil"
            },

            errors: [],
            viewPage: false,
            sendForm: false,
            showError: false,
        }
    },

    methods: {
        loadItems() {
            this.$store.dispatch('loadEmployee', this.id)
                .then((r) => {
                    this.formData = r
                }).catch(() => {
                toast.error('Erro desconhecido!', {timeout: 1500})
            })
        },

        submit() {
            if (!this.sendForm) {
                this.sendForm = true
                this.$store.dispatch('updateEmployee', this.formData)
                    .then(() => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success('Cadastro atualizado com sucesso', {timeout: 1500})
                        window.scroll(0, 0)
                    }).catch((e) => {
                    this.showError = true
                    this.sendForm = false
                    window.scroll(0, 0)
                    this.errors = e.response.data.errors
                })
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLItems', 6)
                .then((r) => {
                    if (r.indexOf('employee-edit') !== -1) {
                        this.viewPage = true

                        if (r.indexOf('employee-create') !== -1) {
                            this.create = true
                        }
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        searchApiCEP() {
            this.resetFormAddress()
            let cep = this.formData.cep.replace(/\D/g, '');
            const formData = {'cep': cep}
            this.$store.dispatch('searchApiCEP', formData)
                .then((r) => {
                    this.formData.city = r.data.localidade
                    this.formData.state = r.data.uf
                    this.formData.stateView = r.data.uf
                    this.formData.district = r.data.bairro
                    this.formData.address = r.data.logradouro
                    this.formData.complement = r.data.complemento
                })
        },

        nullArray() {
            if (this.emailsAdd.length === 0)
                this.addEmails()
            if (this.phonesAdd.length === 0)
                this.addPhones()
            if (this.banksAdd.length === 0)
                this.addBanks()
        },

        addEmails() {
            this.formData.emails.push({
                notification: 2,
                name: "",
                email: ""
            })
        },

        removeEmails(index) {
            this.formData.emails.splice(index, 1)
        },

        addPhones() {
            this.formData.phones.push({
                notification: 2,
                name: "",
                phone: ""
            })
        },

        removePhones(index) {
            this.formData.phones.splice(index, 1)
        },

        addBanks() {
            this.formData.bank_accounts.push({
                bank: "",
                account_type: "",
                agency: "",
                digit_account: "",
                pix_type: "",
                key_pix: "",
                main_account: 1
            })
        },

        removeBanks(index) {
            this.formData.bank_accounts.splice(index, 1)
        },

        resetFormAddress() {
            this.formData.city = ""
            this.formData.state = ""
            this.formData.stateView = ""
            this.formData.district = ""
            this.formData.address = ""
            this.formData.complement = ""
            this.formData.country = "Brasil"
        }
    },

    computed: {
        selectSexo() {
            return this.$store.state.var.selectSexo
        },

        selectActiveInative() {
            return this.$store.state.var.selectActiveInative
        },

        selectBankList() {
            return this.$store.state.var.selectBankList
        },

        selectAccountType() {
            return this.$store.state.var.selectAccountType
        },

        selectPIX() {
            return this.$store.state.var.selectPIX
        },

        emailsAdd() {
            return this.formData.emails
        },

        phonesAdd() {
            return this.formData.phones
        },

        banksAdd() {
            return this.formData.bank_accounts
        },
    }
}
</script>

<style scoped>
.tabs > input,
.tabs > span,
.effect-3 .line {
    width: calc(100% / 4);
}

#tab-2,
#tab-2 + span,
#tab-2:checked ~ .line {
    left: 25%;
}

#tab-3,
#tab-3 + span,
#tab-3:checked ~ .line {
    left: 50%;
}

#tab-4,
#tab-4 + span,
#tab-4:checked ~ .line {
    left: 75%;
}
</style>