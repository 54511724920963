<template>
    <painel-component :items="itemsOne">
        <error-component v-if="showError" :errors="errors"/>

        <form v-if="viewPage">
            <row-component>
                <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                               :class="{'formError' : errors.product_id}">
                    <label>Produto:</label>
                    <MultiSelect label="name" v-model="formData.product_id" :options="selectProduct"/>
                </col-component>

                <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                               :class="{'formError' : errors.type}">
                    <label>Entrada / Saída:</label>
                    <MultiSelect v-model="formData.type" :options="selectEntranceExit"/>
                </col-component>

                <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                               :class="{'formError' : errors.amount}">
                    <label>Quantidade:</label>
                    <input-component v-model="formData.amount" type="number"/>
                </col-component>

                <col-component class-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                               :class="{'formError' : errors.price}">
                    <label>Preço Unitário:</label>
                    <input-component v-model="formData.price" v-money="MONEY"/>
                </col-component>
            </row-component>

            <row-component>
                <col-component class-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                               :class="{'formError' : errors.obs}">
                    <label>Observações:</label>
                    <text-area v-model="formData.obs" rows="6"/>
                </col-component>
            </row-component>

            <row-component>
                <col-component class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-submit :disabled="sendForm" @click="submit" class="w-100" icon="plus" name="Adicionar"/>
                </col-component>

                <col-component class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-route class="w-100" :items="itemsTwo"/>
                </col-component>
            </row-component>
        </form>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import ColComponent from "@/components/row/ColComponent";
import MultiSelect from "@/components/form/MultiSelect";
import InputComponent from "@/components/form/InputComponent";
import TextArea from "@/components/form/TextArea";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import ErrorComponent from "@/components/alerts/ErrorComponent";

import {VMoney} from "v-money";
import {MONEY} from "@/configs/constants";
import {useToast} from "vue-toastification";

const toast = useToast()
export default {
    name: "InventoryCreate",

    components: {
        ErrorComponent,
        ButtonRoute,
        ButtonSubmit,
        TextArea,
        InputComponent,
        MultiSelect,
        ColComponent,
        RowComponent,
        PainelComponent
    },

    directives: {
        money: VMoney
    },

    created() {
        this.$store.dispatch("changeNamePage", "Entrada / Saída")
        this.$store.dispatch("loadSelectProduct")
        this.checkACL()
    },

    data() {
        return {
            itemsOne: {
                title: "Pesquisar",
                icon: "pen square"
            },

            itemsTwo: {
                route: "/inventory/search",
                icon: "reply all",
                name: "Voltar"
            },

            formData: {
                product_id: "",
                type: "",
                price: "",
                amount: "",
                obs: ""
            },

            MONEY,

            errors: [],
            viewPage: false,
            sendForm: false,
            showError: false,
        }
    },

    methods: {
        submit() {
            if (!this.sendForm) {
                this.sendForm = true
                this.$store.dispatch('storeInventory', this.formData)
                    .then(() => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success('Cadastro efetuado com sucesso', {timeout: 1500})
                        this.$router.push({name: 'inventory-search'})
                    }).catch((e) => {
                    this.showError = true
                    this.sendForm = false
                    window.scroll(0, 0)
                    this.errors = e.response.data.errors
                })
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLItems', 8)
                .then((r) => {
                    if (r.indexOf('inventorie-create') !== -1) {
                        this.viewPage = true

                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },
    },

    computed: {
        selectProduct() {
            return this.$store.state.var.selectProduct
        },

        selectEntranceExit() {
            return this.$store.state.var.selectEntranceExit
        },
    }
}
</script>