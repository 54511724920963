import axios from "axios";
import {URL_BASE} from "@/configs/constants";

const URL_BW = "/api/v1/bw/"

export default {
    changeNamePage(context, name) {
        context.commit("UPDATE_NAME_PAGE", name)
    },

    loadPermissions(context) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}page`)
                .then((response) => {
                    context.commit("PERMISSIONS", response.data)
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            })
        })
    },

    loadSelectUserGroup(context) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}group-list`)
                .then((response) => {
                    context.commit("SELECT_USER_GROUP", response.data)
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            })
        })
    },

    loadSelectCategory(context) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}category-list`)
                .then((response) => {
                    context.commit("SELECT_CATEGORY", response.data)
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            })
        })
    },

    loadSelectSubcategory(context) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}subcategory-list`)
                .then((response) => {
                    context.commit("SELECT_SUBCATEGORY", response.data)
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            })
        })
    },

    loadSelectProduct(context) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}product-list`)
                .then((response) => {
                    context.commit("SELECT_PRODUCT", response.data)
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            })
        })
    },

    loadSelectProductCategory(context) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BW}produto-categories`)
                .then((response) => {
                    context.commit("SELECT_PRODUCT_CATEGORY", response.data)
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            })
        })
    },

    loadAccountBankMultiSelect(context) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}account-bank-list`)
                .then(response => {
                    context.commit('UPDATE_ACCOUNT_BANK_LIST', response.data)
                    return resolve(response.data)
                }).catch(errors => {
                return reject(errors)
            })
        })
    },

    loadAccountTypeMultiSelect(context) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}account-type-list`)
                .then(response => {
                    context.commit('UPDATE_ACCOUNT_TYPE_LIST', response.data)
                    return resolve(response.data)
                }).catch(errors => {
                return reject(errors)
            })
        })
    },

    loadAccountTypeSubMultiSelect(context, id) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}account-type-sub-list/${id}`)
                .then(response => {
                    context.commit('UPDATE_ACCOUNT_TYPE_SUB_LIST', response.data)
                    return resolve(response.data)
                }).catch(errors => {
                return reject(errors)
            })
        })
    },






}