import AdminComponent from '@/components/dashboard/AdminComponent'
import SalesHistoricSearch from "@/views/search/SalesHistoricSearch.vue";
import SalesHistoricShow from "@/views/show/SalesHistoricShow.vue";

export default {
    path: '/pdv-sales-historic',
    component: AdminComponent,
    meta: {auth: true},
    children: [
        {path: 'search', name: 'pdv-sales-historic-search', component: SalesHistoricSearch},
        {path: 'show/:id', name: 'pdv-sales-historic-show', component: SalesHistoricShow,  props: true},
    ]
}