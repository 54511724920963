import AdminComponent from "@/components/dashboard/AdminComponent";

import BoxTotalExit from "@/views/report/BoxTotalExit.vue";

export default {
    path: "/box-total-exit",
    meta:{auth: true},
    component: AdminComponent,
    children: [
        {path: "search", name: "box-total-exit-search", component: BoxTotalExit},
    ]
}